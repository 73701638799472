<template>
  <header class="page__header">
    <img class="page__logo" src="@/assets/svg/logo-99.svg" alt="Logo 99 Empresas">
    <PageHeaderActions v-if="actions" />

    <PageTitles class="page__header__title" v-if="title">
      <template v-slot:title>
        <div v-html="title" />
      </template>
    </PageTitles>
  </header>
</template>

<script>
import PageTitles from 'components/PageTitles'
import PageHeaderActions from './PageHeaderActions'

export default {
  name: 'PageHeader',
  props: {
    actions: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    }
  },
  components: {
    PageTitles,
    PageHeaderActions
  }
}
</script>

<style lang="scss" scoped>
.page__header {
  align-items: center;
  display: flex;
  margin-bottom: 30px;

  .page__logo {
    display: flex;
    justify-content: flex-start;

    @media (min-width: 1200px) {
      height: 77px;
    }
  }
}

.page__header__title {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: 35px;
}
</style>
