<template>
  <div>
    <h1 class="page__title" :class="{ 'page__title--mb': $slots.desc }">
      <slot name="title"></slot>
    </h1>

    <p v-if="$slots.desc" class="page__desc">
      <slot name="desc"></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'PageTitles'
}
</script>

<style lang="scss">
.page__title {
  color: $gray-8;
  font-family: $title-font;
  font-size: 22px;
  font-weight: 800;
  line-height: 1.1;

  &.page__title--mb {
    margin-bottom: 16px;
  }

  * {
    font-size: 22px;
  }

  span {
    color: $accent;
  }
}

p.page__desc {
  line-height: 1.3;
  margin-bottom: 32px;
}
</style>
