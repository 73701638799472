<template>
  <div class="page__header__actions">
    <span class="page__header__actions__text">Sua empresa já é cliente?</span>

    <v-btn
      href="https://empresas.99app.com/"
      title="Acessar a plataforma"
      color="default"
      depressed
      rounded
      small
    >
      Acessar
    </v-btn>

    <v-btn
      href="https://99app.com/ajuda/empresas"
      title="Perguntas Frequentes - FAQ"
      color="default"
      depressed
      fab
      small
    >
      <v-icon small :style="{ fontSize: '12px' }">mdi-help</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PageHeaderActions'
}
</script>

<style lang="scss" scoped>
.page__header__actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  .page__header__actions__text {
    display: none;

    @media (min-width: 768px) {
      display: flex;
    }
  }

  .v-btn {
    margin-left: 16px;
  }
}
</style>
