'use strict'

import {
  nameAndSurnameValidator,
  publicDomainValidator,
  cellPhoneValidator,
  CNPJValidator,
  CEPValidator,
  cardExpiryDateValidator,
  emailValidator
} from 'utils/validator'

const formValidatorMixin = {
  created () {
    this.$validator.extend('nameAndSurname', {
      getMessage: field => 'Insira o nome e sobrenome',
      validate: value => nameAndSurnameValidator(value)
    })

    this.$validator.extend('coporateEmail', {
      getMessage: field => 'Emails gratuitos como gmail, hotmail, yahoo e etc não são permitidos',
      validate: value => publicDomainValidator(value)
    })

    this.$validator.extend('cellPhone', {
      getMessage: field => 'Número de celular inválido',
      validate: value => cellPhoneValidator(value)
    })

    this.$validator.extend('cnpj', {
      getMessage: field => 'CNPJ inválido',
      validate: value => CNPJValidator(value)
    })

    this.$validator.extend('cep', {
      getMessage: field => 'CEP inválido. Pesquise seu cep ao lado.',
      validate: value => CEPValidator(value)
    })

    this.$validator.extend('creditCardExpirationDate', {
      getMessage: field => 'Data inválida',
      validate: value => cardExpiryDateValidator(value)
    })

    this.$validator.extend('isValidEmail', {
      getMessage: field => 'O e-mail não possui um formato válido',
      validate: value => emailValidator(value)
    })
  }
}

export default formValidatorMixin
