
'use strict'

const mediaQueries = {
  data () {
    return {
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      isLargeDesktop: false,
      isExtraLargeDesktop: false
    }
  },

  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 600
      this.isTablet = window.innerWidth >= 600 && window.innerWidth < 960
      this.isDesktop = window.innerWidth >= 960 && window.innerWidth < 1264
      this.isLargeDesktop = window.innerWidth >= 1264 && window.innerWidth < 1904
      this.isExtraLargeDesktop = window.innerWidth >= 1904
    }
  },

  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true })
    }
  }
}

export default mediaQueries
