'use strict'

const publicDomains = [
  'google.com',
  'facebook.com',
  'doubleclick.net',
  'google-analytics.com',
  'akamaihd.net',
  'googlesyndication.com',
  'googleapis.com',
  'googleadservices.com',
  'facebook.net',
  'youtube.com',
  'twitter.com',
  'scorecardresearch.com',
  'microsoft.com',
  'ytimg.com',
  'googleusercontent.com',
  'apple.com',
  'msftncsi.com',
  '2mdn.net',
  'googletagservices.com',
  'adnxs.com',
  'yahoo.com',
  'serving-sys.com',
  'akadns.net',
  'bluekai.com',
  'ggpht.com',
  'rubiconproject.com',
  'verisign.com',
  'addthis.com',
  'crashlytics.com',
  'amazonaws.com',
  'quantserve.com',
  'akamaiedge.net',
  'live.com',
  'googletagmanager.com',
  'revsci.net',
  'adadvisor.net',
  'openx.net',
  'digicert.com',
  'pubmatic.com',
  'agkn.com',
  'instagram.com',
  'mathtag.com',
  'gmail.com',
  'gmail.com.br',
  'rlcdn.com',
  'linkedin.com',
  'yahooapis.com',
  'chartbeat.net',
  'twimg.com',
  'turn.com',
  'crwdcntrl.net',
  'demdex.net',
  'betrad.com',
  'flurry.com',
  'newrelic.com',
  'yimg.com',
  'youtube-nocookie.com',
  'exelator.com',
  'acxiom-online.com',
  'imrworldwide.com',
  'amazon.com',
  'fbcdn.net',
  'windowsupdate.com',
  'mookie1.com',
  'rfihub.com',
  'omniroot.com',
  'adsrvr.org',
  'nexac.com',
  'bing.com',
  'skype.com',
  'godaddy.com',
  'sitescout.com',
  'tubemogul.com',
  'contextweb.com',
  'w55c.net',
  'chartbeat.com',
  'akamai.net',
  'jquery.com',
  'adap.tv',
  'criteo.com',
  'krxd.net',
  'optimizely.com',
  'macromedia.com',
  'comodoca.com',
  'casalemedia.com',
  'pinterest.com',
  'adsymptotic.com',
  'symcd.com',
  'atwola.com',
  'adobe.com',
  'msn.com',
  'adsafeprotected.com',
  'tapad.com',
  'truste.com',
  'symantecliveupdate.com',
  'atdmt.com',
  't.co',
  'avast.com',
  'google.co.in',
  'spotxchange.com',
  'tidaltv.com',
  'adtechus.com',
  'everesttech.net',
  'addthisedge.com',
  'hola.org',
  'btrll.com',
  'gwallet.com',
  'liverail.com',
  'windows.com',
  'burstnet.com',
  'disqus.com',
  'nr-data.net',
  'p-td.com',
  'geotrust.com',
  'admob.com',
  'crittercism.com',
  'bizographics.com',
  'ru4.com',
  'wtp101.com',
  'ksmobile.com',
  'msads.net',
  'thawte.com',
  'lijit.com',
  'cloudflare.com',
  '360yield.com',
  'dropbox.com',
  'simpli.fi',
  'smartadserver.com',
  'globalsign.com',
  'mlnadvertising.com',
  'chango.com',
  'connexity.net',
  'moatads.com',
  's-msn.com',
  'entrust.net',
  'tribalfusion.com',
  'domdex.com',
  'google.com.tr',
  'whatsapp.net',
  'ntp.org',
  'amazon-adsystem.com',
  'viber.com',
  'disquscdn.com',
  'yandex.ru',
  'doubleverify.com',
  'bkrtx.com',
  'criteo.net',
  'outbrain.com',
  'questionmarket.com',
  'adform.net',
  'yieldmanager.com',
  'typekit.net',
  'goo.gl',
  'voicefive.com',
  'owneriq.net',
  'media6degrees.com',
  'tynt.com',
  'symcb.com',
  'advertising.com',
  'audienceiq.com',
  'wp.com',
  'rtbidder.net',
  'wikipedia.org',
  'adroll.com',
  'icloud.com',
  'gravatar.com',
  'collective-media.net',
  'appsflyer.com',
  'dmtry.com',
  'blogger.com',
  'taboola.com',
  'legolas-media.com',
  'images-amazon.com',
  'afy11.net',
  'aspnetcdn.com',
  'hike.in',
  'feedburner.com',
  'bootstrapcdn.com',
  'usertrust.com',
  'adgrx.com',
  'brilig.com',
  'sharethis.com',
  'flashtalking.com',
  'mediaplex.com',
  'eqads.com',
  'adscale.de',
  'imgur.com',
  'edgesuite.net',
  'blogspot.com',
  'msocsp.com',
  'wikimedia.org',
  'ssl-images-amazon.com',
  'amung.us',
  'flickr.com',
  'rundsp.com',
  'trouter.io',
  'edgekey.net',
  'rfihub.net',
  'utorrent.com',
  'thebrighttag.com',
  'eyeviewads.com',
  'switchads.com',
  'tiqcdn.com',
  'mozilla.org',
  'jwpcdn.com',
  'exponential.com',
  'abmr.net',
  'nanigans.com',
  'zenoviaexchange.com',
  'aolcdn.com',
  'licdn.com',
  'mixpanel.com',
  '254a.com',
  'mopub.com',
  'creative-serving.com',
  'statcounter.com',
  'jwpltx.com',
  'parse.com',
  'ensighten.com',
  'adtech.de',
  'brightcove.com',
  'acuityplatform.com',
  'gfx.ms',
  'ixiaa.com',
  'reddit.com',
  'visualrevenue.com',
  'google.com.br',
  'stickyadstv.com',
  'google.it',
  'yashi.com',
  'jumptap.com',
  'interclick.com',
  'tapjoyads.com',
  'globalsign.net',
  'eyereturn.com',
  'pointroll.com',
  'googlevideo.com',
  'virtualearth.net',
  'gumgum.com',
  'triggit.com',
  'tumblr.com',
  'gigya.com',
  'teamviewer.com',
  'insightexpressai.com',
  'msecnd.net',
  'gemius.pl',
  'oracle.com',
  'sonobi.com',
  'fastclick.net',
  'ebay.com',
  'adobetag.com',
  'surveymonkey.com',
  'stumbleupon.com',
  'admaym.com',
  'invitemedia.com',
  'superfish.com',
  'google.com.vn',
  'yahoodns.net',
  'tapjoy.com',
  'blogblog.com',
  'mxpnl.com',
  'omtrdc.net',
  'skimresources.com',
  'akamai.com',
  'adobedtm.com',
  'starfieldtech.com',
  'skypeassets.com',
  'a.com',
  'btstatic.com',
  'researchnow.com',
  'conviva.com',
  'hotmail.com',
  'bittorrent.com',
  'openbittorrent.com',
  'vindicosuite.com',
  'duba.net',
  'publicbt.com',
  'impact-ad.jp',
  'netflix.com',
  'ib-ibi.com',
  'smaato.net',
  'netsolssl.com',
  'fetchback.com',
  'appspot.com',
  'vk.com',
  'mozilla.com',
  'accu-weather.com',
  'yieldmanager.net',
  'yadro.ru',
  'histats.com',
  'netseer.com',
  'creativecommons.org',
  'live.net',
  'vizu.com',
  'youtu.be',
  'kau.li',
  'eyeota.net',
  'weather.com',
  'provenpixel.com',
  'veruta.com',
  'umengcloud.com',
  'paypal.com',
  'office365.com',
  'simplereach.com',
  'ooyala.com',
  'specificclick.net',
  'digg.com',
  'google.ca',
  'dotomi.com',
  'netmng.com',
  'undertone.com',
  'erne.co',
  'staticflickr.com',
  'urbanairship.com',
  'adkmob.com',
  'pro-market.net',
  'dtscout.com',
  'imdb.com',
  'mzstatic.com',
  'alexa.com',
  'fastly.net',
  'baidu.com',
  'brealtime.com',
  'amazon.co.uk',
  'midasplayer.com',
  'bugsense.com',
  'outlook.com',
  'outlook.com.br',
  'chartboost.com',
  'adrta.com',
  'adcash.com',
  'root-servers.net',
  'adtilt.com',
  'awstls.com',
  'fwmrm.net',
  'cdninstagram.com',
  'adsonar.com',
  'zedo.com',
  'demonii.com',
  'vimeo.com',
  'dianxinos.com',
  'adventori.com',
  'accuweather.com',
  'steamstatic.com',
  'coull.com',
  'mxptint.net',
  'pfx.ms',
  'footprint.net',
  'ceipmsn.com',
  'paypalobjects.com',
  'taboolasyndication.com',
  'umeng.com',
  'altitude-arena.com',
  'webtrendslive.com',
  'dl-rms.com',
  'visualwebsiteoptimizer.com',
  'mydas.mobi',
  'cap-mii.net',
  'naver.jp',
  'avg.com',
  'wordpress.com',
  'pinimg.com',
  'livefyre.com',
  'tabwpm.us',
  'maxymiser.net',
  'wordpress.org',
  'ebayimg.com',
  'gravity.com',
  'huffingtonpost.com',
  'exoclick.com',
  'pandora.com',
  'reson8.com',
  'grvcdn.com',
  'aol.com',
  'adcolony.com',
  'adhigh.net',
  'eset.com',
  'trustwave.com',
  'cnn.com',
  'cxense.com',
  'lfstmedia.com',
  'xboxlive.com',
  'vungle.com',
  'a3cloud.net',
  'dailymotion.com',
  'postrelease.com',
  'duapp.com',
  'king.com',
  'mailshell.net',
  'pingdom.net',
  'lenovomm.com',
  'dyntrk.com',
  'kaspersky-labs.com',
  'jwpsrv.com',
  'nsatc.net',
  'soundcloud.com',
  'vimeocdn.com',
  'theviilage.com',
  'hlserve.com',
  'wdgserv.com',
  'inmobi.com',
  'bbc.co.uk',
  'kaspersky.com',
  'spotxcdn.com',
  'norton.com',
  'nytimes.com',
  'crsspxl.com',
  'liveperson.net',
  'amgdgt.com',
  'amazon.in',
  'amazon.de',
  'adotube.com',
  'go.com',
  'samsungosp.com',
  'parsely.com',
  'windowsphone.com',
  'heias.com',
  'amazon.it',
  'washingtonpost.com',
  'ospserver.net',
  'mscimg.com',
  'google.co.uk',
  'mzl.la',
  'pswec.com',
  'media.net',
  'v0cdn.net',
  'supercell.net',
  'visadd.com',
  'andomedia.com',
  'mdotlabs.com',
  'adformdsp.net',
  'wikimediafoundation.org',
  'alenty.com',
  'zergnet.com',
  'sundaysky.com',
  'amazon.ca',
  'mediawiki.org',
  'datafastguru.info',
  'vidible.tv',
  'adzerk.net',
  'brand-server.com',
  'quantcount.com',
  'flipboard.com',
  'dtmpub.com',
  'spongecell.com',
  'tinyurl.com',
  'clkmon.com',
  'bing.net',
  'adlegend.com',
  'adblockplus.org',
  'dvtps.com',
  'p-cdn.com',
  'mailchimp.com',
  'wikidata.org',
  'icio.us',
  'ebaystatic.com',
  'viglink.com',
  'ibook.info',
  'itools.info',
  'thinkdifferent.us',
  'airport.us',
  'appleiphonecell.com',
  'hwcdnlb.net',
  'effectivemeasure.net',
  'amazon.fr',
  'iponweb.net',
  'mbamupdates.com',
  'foxnews.com',
  'fiksu.com',
  'dlqm.net',
  'ozonemedia.com',
  'zenfs.com',
  'deliads.com',
  'yieldlab.net',
  'sail-horizon.com',
  'applovin.com',
  'nspmotion.com',
  'metrigo.com',
  'pulsemgr.com',
  'visiblemeasures.com',
  'revenuemantra.com',
  'smartclip.net',
  'ijinshan.com',
  'tndmnsha.com',
  'go-mpulse.net',
  'relestar.com',
  'amazon.co.jp',
  'jollywallet.com',
  'trafficmanager.net',
  'imgfarm.com',
  'opera-mini.net',
  'cogocast.net',
  'onenote.com',
  'amazon.es',
  'opendns.com',
  'p161.net',
  'a-msedge.net',
  'cpmstar.com',
  'amazon.com.br',
  'logmein.com',
  'nflximg.net',
  'univide.com',
  'tekblue.net',
  'infostatsvc.com',
  'udmserve.net',
  'basebanner.com',
  'zynga.com',
  'amazon.cn',
  'mathads.com',
  'amazon.com.au',
  'mediade.sk',
  'atemda.com',
  'd41.co',
  'amazon.com.mx',
  'airpush.com',
  'ksmobile.net',
  'geogslb.com',
  'goodreads.com',
  'monetate.net',
  'clicktale.net',
  'richrelevance.com',
  'tns-counter.ru',
  'coremetrics.com',
  'online-metrix.net',
  'rs6.net',
  'xingcloud.com',
  'generalmobi.com',
  'uservoice.com',
  'herokuapp.com',
  'adblade.com',
  'svcmot.com',
  'shopbop.com',
  'z5x.net',
  'optmd.com',
  'dropboxusercontent.com',
  'fbsbx.com',
  'turner.com',
  'onclickads.net',
  'bookdepository.com',
  'bluecava.com',
  'adtimaserver.vn',
  'beringmedia.com',
  'choicestream.com',
  'zanox.com',
  'apsalar.com',
  'realmedia.com',
  'dpclk.com',
  'cedexis.com',
  'scanscout.com',
  'display-trk.com',
  'bitmedianetwork.com',
  'ctnsnet.com',
  'tunigo.com',
  'samsung.com',
  'bazaarvoice.com',
  'ebayrtm.com',
  'returnpath.net',
  'walmart.com',
  'wsod.com',
  'constantcontact.com',
  'getclicky.com',
  'localytics.com',
  'ligatus.com',
  'appier.net',
  'dxsvr.com',
  'myhabit.com',
  'ajaxcdn.org',
  'adyapper.com',
  'nist.gov',
  'neulion.com',
  'edgecastcdn.net',
  'convertro.com',
  'vnexpress.net',
  'javafx.com',
  'thepiratebay.org',
  'skype.net',
  'kontagent.net',
  'newsinc.com',
  'glpals.com',
  'ebz.io',
  'audible.com',
  'mobogenie.com',
  'dingaling.ca',
  'nrcdn.com',
  'stumble-upon.com',
  'backupgrid.net',
  'po.st',
  'marinsm.com',
  'nflximg.com',
  'adizio.com',
  'acx.com',
  'fyre.co',
  'admedo.com',
  'xvideos.com',
  'junglee.com',
  'evernote.com',
  'createspace.com',
  'buzzfeed.com',
  'zing.vn',
  'sanasecurity.com',
  'igexin.com',
  'bnmla.com',
  'liadm.com',
  'usatoday.com',
  'scanalert.com',
  'espncdn.com',
  'metamx.com',
  'plexop.net',
  'optimatic.com',
  'medyanetads.com',
  'w3.org',
  'apnanalytics.com',
  'gezinti.com',
  'dpreview.com',
  'xbox.com',
  'servesharp.net',
  'cpxinteractive.com',
  'adsparc.net',
  'cardlytics.com',
  'dailymail.co.uk',
  'redditstatic.com',
  'sociomantic.com',
  'contentabc.com',
  'admost.com',
  'inmobicdn.net',
  '3g.cn',
  'miisolutions.net',
  'nrelate.com',
  'innovid.com',
  'nola.com',
  'testflightapp.com',
  'teads.tv',
  'fool.com',
  'tripadvisor.com',
  'al.com',
  'cloudapp.net',
  'public-trust.com',
  'vine.co',
  'mlive.com',
  'cleveland.com',
  'tp-cdn.com',
  'addtoany.com',
  'sharethrough.com',
  'clickfuse.com',
  'nj.com',
  'abebooks.com',
  'batanga.net',
  'mediavoice.com',
  'wsodcdn.com',
  'bloomberg.com',
  'ucweb.com',
  'fonts.com',
  'videohub.tv',
  'spotify.com',
  'alicdn.com',
  'cdngc.net',
  'groupon.com',
  'afterschool.com',
  'symantec.com',
  'oregonlive.com',
  'apptimize.com',
  'trafficfactory.biz',
  'ibillboard.com',
  'vizury.com',
  'qservz.com',
  'perfectmarket.com',
  'yieldoptimizer.com',
  'ad4game.com',
  'ask.com',
  'networkhm.com',
  'amazonlocal.com',
  'zappos.com',
  'diapers.com',
  'adtricity.com',
  'ml314.com',
  'yldbt.com',
  'plexop.com',
  'bbb.org',
  'tworismo.com',
  'amazonsupply.com',
  'beautybar.com',
  'theguardian.com',
  'myhomemsn.com',
  'nvidia.com',
  'comixology.com',
  'bookworm.com',
  'huffpost.com',
  'vcmedia.vn',
  'casa.com',
  'woot.com',
  'eastdane.com',
  'answers.com',
  'infolinks.com',
  'fabric.com',
  'lphbs.com',
  'rpxnow.com',
  'ovi.com',
  'dlinksearch.com',
  'adlooxtracking.com',
  'soap.com',
  'mail.ru',
  'look.com',
  'microsoftonline.com',
  'wag.com',
  'dyndns.org',
  'pennlive.com',
  'nbcnews.com',
  'yoyo.com',
  'zopim.com',
  'collserve.com',
  'vine.com',
  'gpsonextra.net',
  'tacoda.net',
  'trusteer.com',
  'yahoo.net',
  'toolbarservices.com',
  'bluelithium.com',
  'sun.com',
  '33across.com',
  'ipinfo.io',
  'iasds01.com',
  'longtailvideo.com',
  'typography.com',
  '6pm.com',
  'ptvcdn.net',
  'adf.ly',
  'kissmetrics.com',
  'ccc.de',
  'c3tag.com',
  'safemovedm.com',
  'tango.me',
  'bbc.com',
  'syracuse.com',
  'dashbida.com',
  'gvt1.com',
  'admicro.vn',
  'sascdn.com',
  'r1-cdn.net',
  'everestjs.net',
  'craigslist.org',
  'llnwd.net',
  'thanksearch.com',
  'iegallery.com',
  'typekit.com',
  'visualdna.com',
  'angsrvr.com',
  'tenmarks.com',
  'mediaforge.com',
  'telegraph.co.uk',
  'myspace.com',
  'lastpass.com',
  'steampowered.com',
  'startssl.com',
  'ipinyou.com',
  'fonts.net',
  'goo.mx',
  'google.com.mx',
  'tr553.com',
  '5min.com',
  'tfxiq.com',
  'korrelate.net',
  'alibaba.com',
  'mininova.org',
  'ebaydesc.com',
  'desync.com',
  'compete.com',
  'kochava.com',
  'kaltura.com',
  'bleacherreport.com',
  'buscape.com.br',
  'flite.com',
  'swisssign.net',
  'yieldmo.com',
  'content.ad',
  'github.com',
  'wsj.com',
  'opera.com',
  'grouponcdn.com',
  'aliunicorn.com',
  'solocpm.com',
  'nav-links.com',
  'crtinv.com',
  'hiro.tv',
  'opendsp.com',
  'windows.net',
  'dmcdn.net',
  'wii.com',
  'farlex.com',
  'smartstream.tv',
  'yandex.net',
  'masslive.com',
  'blogher.org',
  'jccjd.com',
  'beanstock.co',
  'weatherbug.com',
  'intellitxt.com',
  'bidtheatre.com',
  'mmondi.com',
  'linkedinlabs.com',
  'acrobat.com',
  'nokia.com',
  'levexis.com',
  'cbsi.com',
  'adsplats.com',
  'perfectaudience.com',
  'admarvel.com',
  'performgroup.com',
  'liveinternet.ru',
  'zyngawithfriends.com',
  'bankrate.com',
  '24h.com.vn',
  'trafficjunky.net',
  'cedexis.net',
  'janrain.com',
  'geforce.com',
  'tacdn.com',
  'eonline.com',
  'smarturl.it',
  'impdesk.com',
  'internapcdn.net',
  'umeng.co',
  'sekindo.com',
  'steamcommunity.com',
  'riotgames.com',
  'wunderground.com',
  'nextadvisor.com',
  'reuters.com',
  'vibrant.co',
  'blackberry.com',
  'hwcdn.net',
  'tremormedia.com',
  'netgear.com',
  'fncstatic.com',
  'google.com.eg',
  'ebdr3.com',
  'revcontent.com',
  'businessinsider.com',
  'prfct.co',
  'iperceptions.com',
  'c8.net.ua',
  'taobao.com',
  'delicious.com',
  '247realmedia.com',
  'imwx.com',
  'active-agent.com',
  'supersonicads.com',
  'realtime.co',
  'kill123.com',
  'phncdn.com',
  'redditmedia.com',
  'thepostgame.com',
  'h33t.com',
  'a9.com',
  'foursquare.com',
  'milliyet.com.tr',
  '4dsply.com',
  'upwpm.us',
  'csze.com',
  'mediaquark.com',
  'tritondigital.com',
  'mozilla.net',
  'fidelity-media.com',
  'dmca.com',
  'greystripe.com',
  'cafemom.com',
  'mapticket.net',
  'xhamster.com',
  'ow.ly',
  'maxmind.com',
  'avira.com',
  'webspectator.com',
  'marketo.net',
  'vlingo.com',
  'iesnare.com',
  'qwapi.com',
  'rarbg.com',
  'twitch.tv',
  'myfonts.net',
  'aws-protocol-testing.com',
  'cb-cdn.com',
  'segment.io',
  'adnetwork.vn',
  'qq.com',
  'kik.com',
  'technoratimedia.com',
  'res-x.com',
  'samsungapps.com',
  'lenovo.com',
  'americanexpress.com',
  'htc.com',
  'android.com',
  'apnstatic.com',
  'bounceexchange.com',
  'tumri.net',
  'theplatform.com',
  'olark.com',
  'cnbc.com',
  'thespatialists.com',
  'shareaholic.com',
  'specificmedia.com',
  'sharedaddomain.com',
  'jquerytools.org',
  'microadinc.com',
  'clashofclans.com',
  'roku.com',
  'qualtrics.com',
  'thescene.com',
  'medialytics.com',
  'mashable.com',
  'cubecdn.net',
  '360game.vn',
  'estara.com',
  'kiip.me',
  'aliexpress.com',
  'dailyofferservice.com',
  'uol.com.br',
  'adk2.co',
  'aliimg.com',
  'tentaculos.net',
  'jsuol.com',
  'attracto.com',
  'corom.vn',
  'dessaly.com',
  'sgiggle.com',
  'mobileapptracking.com',
  'office.com',
  'linkwithin.com',
  'latimes.com',
  'cbsnews.com',
  'eclick.vn',
  'glbimg.com',
  'epicunitscan.info',
  'avira-update.com',
  'hoptopboy.com',
  'tvlsvc.com',
  'tailtarget.com',
  'desk.com',
  'intentiq.com',
  'ero-advertising.com',
  'imguol.com',
  'everyscreenmedia.com',
  'bbci.co.uk',
  'itunes.com',
  'engadget.com',
  'people.com',
  'dsply.com',
  'voga360.com',
  'hmageo.com',
  '337play.com',
  'gannett-cdn.com',
  'rcsadv.it',
  'manage.com',
  'cachefly.net',
  'doublepimp.com',
  'keen.io',
  'ea.com',
  'reklamport.com',
  'shopping.com',
  'youradexchange.com',
  'hp.com',
  'apptentive.com',
  'earthnetworks.com',
  'nfl.com',
  'userdmp.com',
  'yastatic.net',
  'google.de',
  'apxlv.com',
  'moneynews.com',
  'livechatinc.com',
  'forbes.com',
  'pornhub.com',
  'sbal4kp.com',
  'wsoddata.com',
  'logmein-gateway.com',
  'facdn.com',
  'yldmgrimg.net',
  'hurriyet.com.tr',
  'lucidmedia.com',
  'doracdn.com',
  'indeed.com',
  'disneytermsofuse.com',
  'truecaller.com',
  'time.com',
  'mediatek.com',
  'ioam.de',
  'rackcdn.com',
  'baidu.co.th',
  'reklamstore.com',
  'pricegrabber.com',
  'dyndns.com',
  'imageshack.us',
  'popads.net',
  'dataxu.com',
  'sndcdn.com',
  'gizmodo.com',
  'imageshack.com',
  'yelp.com',
  'google.ru',
  'best-tv.com',
  'webtrends.com',
  'google.fr',
  'archive.org',
  'walmartimages.com',
  'att.com',
  'e-planning.net',
  'openxenterprise.com',
  'yan.vn',
  'company-target.com',
  'cmptch.com',
  'incmd04.com',
  'disneyprivacycenter.com',
  'npr.org',
  'tellapart.com',
  'hulu.com',
  'dynamicyield.com',
  'theatlantic.com',
  'atgsvcs.com',
  'whois.co.kr',
  'life360.com',
  'tmz.com',
  'visualstudio.com',
  'adservingml.com',
  'securetrust.com',
  'qubitproducts.com',
  '360.cn',
  'realvu.net',
  'fortune.com',
  'sitescoutadserver.com',
  'sponsorpay.com',
  'torrentum.pl',
  'brcdn.com',
  'origin.com',
  'slidesharecdn.com',
  '360safe.com',
  'pressroomvip.com',
  'unrulymedia.com',
  'nxtck.com',
  'adexcite.com',
  'etsy.com',
  'odnoklassniki.ru',
  'iheart.com',
  'mmstat.com',
  'glam.com',
  'radaronline.com',
  'popnhop.com',
  'edgefcs.net',
  'redintelligence.net',
  'myvisualiq.net',
  'mgid.com',
  '2o7.net',
  'mapquest.com',
  'mediamath.com',
  'me.com',
  'ugdturner.com',
  'amasvc.com',
  'monster.com',
  'seethisinaction.com',
  'ebayinc.com',
  'wallstcheatsheet.com',
  'sogou.com',
  'ambient-platform.com',
  'traffichaus.com',
  'kinja-img.com',
  'googlecommerce.com',
  'utorrent.li',
  'thoiloan.vn',
  'dantri.com.vn',
  'ubuntu.com',
  'googlecode.com',
  'google.com.ar',
  'coppersurfer.tk',
  'garenanow.com',
  'flx1.com',
  '1337x.org',
  'videosz.com',
  'virool.com',
  'kenh14.vn',
  'nypost.com',
  'octro.net',
  'ztstatic.com',
  'stackoverflow.com',
  'wishabi.com',
  'jsdelivr.net',
  'vitrines.in',
  'media-imdb.com',
  'predicta.net',
  'cmcore.com',
  'appoxee.com',
  'mcafeesecure.com',
  'crowdscience.com',
  'pagefair.com',
  'adlucent.com',
  'chase.com',
  'nydailynews.com',
  'padsdelivery.com',
  'wlxrs.com',
  'adscience.nl',
  'shoppingshadow.com',
  'mradx.net',
  'fotapro.com',
  'wired.com',
  'cdn.md',
  'hubspot.com',
  'google.es',
  'buzzfed.com',
  'comcast.net',
  'polldaddy.com',
  'plexapp.com',
  'hidemyass.com',
  'steelhousemedia.com',
  'yumenetworks.com',
  'acc-hd.de',
  'populisengage.com',
  'bncnt.com',
  'responsys.net',
  'printfriendly.com',
  'zendesk.com',
  'gmtdmp.com',
  'madisonlogic.com',
  'dartsearch.net',
  'zdn.vn',
  'zedo.net',
  'nbcudigitaladops.com',
  'stubhub.com',
  'adhood.com',
  'microsofttranslator.com',
  'espn.com',
  'linksmart.com',
  'wshifen.com',
  'appa-maker.com',
  'cabelas.com',
  'redtube.com',
  'channelintelligence.com',
  'dell.com',
  'weibo.com',
  'channeladvisor.com',
  'viewster.com',
  'adjuggler.net',
  'xnxx.com',
  'adxpansion.com',
  'alibench.com',
  'qadservice.com',
  'mybuys.com',
  'raasnet.com',
  'tanx.com',
  'popmarker.com',
  'pubnub.com',
  'peer39.net',
  'globo.com',
  'weborama.fr',
  'independent.co.uk',
  'searchmarketing.com',
  'zemanta.com',
  'vgtf.net',
  'inspsearchapi.com',
  'rambler.ru',
  'en25.com',
  'gomonetworks.com',
  'playhaven.com',
  'aweber.com',
  'retargetly.com',
  'allvoices.com',
  'intel.com',
  'pubsqrd.com',
  'admized.com',
  'minimob.com',
  'adingo.jp',
  'cnet.com',
  'userreport.com',
  'trustedsource.org',
  'vk.me',
  'mediafire.com',
  'buysellads.com',
  'slideshare.net',
  'sexad.net',
  'windowsmedia.com',
  'tremorhub.com',
  'licasd.com',
  'bycontext.com',
  'echoenabled.com',
  'issuu.com',
  '1mobile.com',
  'corporate-ir.net',
  'pubexchange.com',
  'audienceinsights.net',
  'adobur.com',
  'celtra.com',
  'techcrunch.com',
  'boo-box.com',
  'eum-appdynamics.com',
  'try9.com',
  'adriver.ru',
  'taobaocdn.com',
  'dealtime.com',
  'ed4.net',
  'trust-provider.com',
  'feedbackify.com',
  'bbelements.com',
  'dwin1.com',
  'yandex.st',
  'gssp-a.com',
  '4seeresults.com',
  'adition.com',
  'nhncorp.jp',
  'googlemail.com',
  'about.com',
  'gap.com',
  'hotwords.com.br',
  'ant.com',
  'plugrush.com',
  'foreseeresults.com',
  'bidswitch.net',
  'gawker.com',
  'advidi.com',
  'pagefair.net',
  'mixpo.com',
  'intuit.com',
  'imiclk.com',
  'bestbuy.com',
  'engageya.com',
  'nexage.com',
  'intergi.com',
  'playstation.net',
  'foxbusiness.com',
  'adk2.com',
  '9999mb.com',
  'bitdefender.net',
  'cpserve.com',
  'yb0t.com',
  'mi-idc.com',
  'espn.co.uk',
  'minecraft.net',
  'crossrider.com',
  'conduit.com',
  'sensic.net',
  'pavv.co.kr',
  'telemetryverification.net',
  'metanetwork.net',
  'lifehacker.com',
  'bbcimg.co.uk',
  'today.com',
  'jtvnw.net',
  'ptreklam.com.tr',
  'inspsearch.com',
  'poll.fm',
  'komoona.com',
  'v2cdn.net',
  'adtima.vn',
  'viralnova.com',
  'harry.lu',
  'trialpay.com',
  'm6r.eu',
  'samsungrm.net',
  'vindicosuitecache.com',
  'rarbg.me',
  'pusherapp.com',
  'asus.com',
  'indexww.com',
  'assoc-amazon.com',
  'ask.fm',
  'yandex.com.tr',
  'adpredictive.com',
  'swiftkey.net',
  'csdata1.com',
  'kontera.com',
  'reddit.tv',
  'baidustatic.com',
  'ctmail.com',
  'gotinder.com',
  'siteadvisor.com',
  'applifier.com',
  'gtimg.com',
  'crdrdpjs.info',
  'redditgifts.com',
  'boldchat.com',
  'dataxu.net',
  'wishabi.net',
  'dynad.net',
  'legacy.com',
  'emjcd.com',
  'cbsimg.net',
  'google.com.hk',
  'pop6.com',
  't-mobile.com',
  'anthill.vn',
  'zdbb.net',
  'sitewebred.info',
  'youporn.com',
  'radiumone.com',
  'whatsapp.com',
  'technorati.com',
  'aim.net',
  'dotandad.com',
  'ex.ua',
  'adsrvmedia.net',
  'lineage2.com.cn',
  'metaffiliation.com',
  'mywot.com',
  'ns-img.com',
  'shoplocal.com',
  'cloudinary.com',
  'creativecdn.com',
  'vdna-assets.com',
  'doi.org',
  'newsmaxfeednetwork.com',
  'rantlifestyle.com',
  'thedailybeast.com',
  'adjuggler.com',
  'huffpo.net',
  'shopify.com',
  'bitly.com',
  'trtromg.com',
  'samsungotn.net',
  'ups.com',
  'hlntv.com',
  'spccint.com',
  'domobile.com',
  'shinystat.com',
  'worldssl.net',
  'infospace.com',
  'chtah.com',
  'vaporcloudcomputing.com',
  'firstimpwins.com',
  'factual.com',
  'ad360.vn',
  'nmcdn.us',
  'adgear.com',
  'theverge.com',
  'mapquestapi.com',
  'comodoca2.com',
  'scdn.co',
  'sstatic.net',
  'kgridhub.com',
  'coccoc.com',
  'businessweek.com',
  'etonline.com',
  'olx.com',
  'eepurl.com',
  'inspectlet.com',
  'marketwatch.com',
  'rklyjs.info',
  'googledrive.com',
  'ford.com',
  'ants.vn',
  'comufy.com',
  'adshost1.com',
  'ns-cdn.com',
  'q1mediahydraplatform.com',
  'tmall.com',
  'booking.com',
  'fivethirtyeight.com',
  'juicyads.com',
  'groovinads.com',
  'plug.it',
  'myvzw.com',
  'semasio.net',
  'nih.gov',
  'cbsinteractive.com',
  'gandi.net',
  'appclick.co',
  'githubusercontent.com',
  'gogorithm.com',
  'openweathermap.org',
  'directrev.com',
  'pow7.com',
  'io9.com',
  'ok.ru',
  'cdnads.com',
  'updatepm.com',
  'chitika.net',
  'vnecdn.net',
  'sailthru.com',
  'fb.me',
  'zencdn.net',
  'salon.com',
  'espnfc.us',
  'mouseflow.com',
  'mainadv.com',
  'healthcentral.com',
  'novanet.vn',
  'aarp.org',
  'wistia.net',
  'moneymorning.com',
  'yceml.net',
  'netdna-cdn.com',
  'moviefone.com',
  'gittigidiyor.com',
  'adbrn.com',
  'sahibinden.com',
  'java.com',
  'videoplaza.tv',
  'videoamp.com',
  'secureserver.net',
  'kinja-static.com',
  'padstm.com',
  'nocookie.net',
  'timeinc.net',
  'webmd.com',
  'xg4ken.com',
  'haberturk.com',
  'radioreddit.com',
  'trovi.com',
  'hs-analytics.net',
  'estadao.com.br',
  'bankofamerica.com',
  'noproblemppc.com',
  'hollywoodreporter.com',
  'ad-score.com',
  'newinfoclientstack.com',
  'somo.vn',
  'swrve.com',
  'accmgr.com',
  'civicscience.com',
  'ft.com',
  'worldnow.com',
  'charter.com',
  'polyad.net',
  'si.com',
  'webengage.com',
  'mobfox.com',
  'google.nl',
  'millennialmedia.com',
  'dataferb.com',
  'vkontakte.ru',
  'ff0000-cdn.net',
  'billboard.com',
  'beanstock.com',
  'mochibot.com',
  'wiktionary.org',
  'cnn.co.jp',
  'blankbase.com',
  'fedex.com',
  'ywxi.net',
  'sitemeter.com',
  'ap.org',
  'vitrinesglobo.com.br',
  'admission.net',
  'unity3d.com',
  'zedge.net',
  'hackerwatch.org',
  'gameanalytics.com',
  'wistia.com',
  'petuniasaucecockup.com',
  'whaleserver.com',
  'glympse.com',
  'nintendo.net',
  'cbssports.com',
  'mplxtms.com',
  'recaptcha.net',
  'qlogo.cn',
  'tube8.com',
  'speedtest.net',
  'webtrekk.com',
  'ngoisao.net',
  'juiceadv.com',
  'datropy.com',
  'kinja.com',
  'inc.com',
  'office.net',
  'everestads.net',
  'securespy.net',
  'optorb.com',
  'google.dz',
  'mobify.com',
  'sony.net',
  'intellicast.com',
  'sbnation.com',
  'sourceforge.net',
  'stackexchange.com',
  'thehill.com',
  'mindspark.com',
  'telecomitalia.it',
  'iobit.com',
  'slimspots.com',
  'haberler.com',
  'espncms.com',
  'newyorker.com',
  'myinfotopia.com',
  'adsrv247.com',
  'rtalabel.org',
  'espnfc.com',
  'solvemedia.com',
  'espncareers.com',
  'fcc.gov',
  '3lift.com',
  'neodatagroup.com',
  'sitebeacon.co',
  'snapwidget.com',
  'timeinc.com',
  'pardot.com',
  'admarketplace.net',
  'usmagazine.com',
  'admeld.com',
  'pcfaster.com',
  'adinterax.com',
  'adlure.net',
  'mqcdn.com',
  'gm.com',
  'itim.vn',
  'loading-delivery1.com',
  'usabilla.com',
  'janrainbackplane.com',
  'nbcsports.com',
  'chatango.com',
  'affec.tv',
  'tlvmedia.com',
  'integral.com',
  'wealthfront.com',
  'dsrlte.com',
  'kohls.com',
  'belkin.com',
  'rdrtr.com',
  'careerbuilder.com',
  'leagueoflegends.com',
  'eamobile.com',
  'circularhub.com',
  'linksynergy.com',
  'irs01.com',
  'bannerflow.com',
  'lifestylejournal.com',
  'dickssportinggoods.com',
  'cnnexpansion.com',
  'token.ro',
  'bizrate.com',
  'tfbnw.net',
  'etsystatic.com',
  'answcdn.com',
  'cnnimagesource.com',
  'vox-cdn.com',
  'innity.net',
  'nyt.com',
  'powerreviews.com',
  'adfox.vn',
  'cnnchile.com',
  'helpshift.com',
  'parastorage.com',
  'itau.com.br',
  '9gag.com',
  'appsdt.com',
  'netvibes.com',
  'stellaservice.com',
  'afamily.vn',
  'connextra.com',
  'nbcuni.com',
  '4wnet.com',
  'dedicatedmedia.com',
  'no-ip.com',
  'espnmediazone.com',
  'luminate.com',
  'slate.com',
  'openstreetmap.org',
  'lazada.vn',
  'sophosupd.com',
  'free-coupons-codes.com',
  'wfxtriggers.com',
  'grantland.com',
  'struq.com',
  'latinsoulstudio.com',
  'mixplay.tv',
  'lomadee.com',
  'ypcdn.com',
  'alibabagroup.com',
  'target.com',
  'linknavi1.com',
  'anyclip.com',
  'woopra.com',
  'pg.com',
  'kickass.to',
  'scribd.com',
  'aliyun.com',
  'zillow.com',
  'ptp24.com',
  'ybpangea.com',
  'go2speed.org',
  'hgads.com',
  'gameloft.com',
  'wt-data.com',
  'tbccint.com',
  'deadspin.com',
  'googlehosted.com',
  'protrade.com',
  'gammaplatform.com',
  'tradedoubler.com',
  'ebay.it',
  'gfycat.com',
  'goadservices.com',
  'radikal.com.tr',
  'crashplan.com',
  'googlezip.net',
  'embedly.com',
  'tqn.com',
  'm6d.com',
  'thechive.com',
  'rantsports.com',
  'bluestacks.com',
  'kiosked.com',
  'dailyfinance.com',
  'cafepress.com',
  'digitru.st',
  's-nbcnews.com',
  'redrock-interactive.com',
  'chicagotribune.com',
  'turnerstoreonline.com',
  'boston.com',
  'kotaku.com',
  'cnnnewsource.com',
  'real.com',
  'clickability.com',
  'netdna-ssl.com',
  'comodo.com',
  'google.dk',
  'ehow.com',
  'updaterex.com',
  'mozillamessaging.com',
  'and.co.uk',
  'fastcompany.com',
  'genk.vn',
  'github.io',
  'vineapp.com',
  'securedvisit.com',
  'feedly.com',
  'astpdt.com',
  'allstate.com',
  'wal.co',
  'hurpass.com',
  'squarespace.com',
  'politico.com',
  'peel-prod.com',
  'cleanprint.net',
  'groupme.com',
  'techtudo.com.br',
  'sessionm.com',
  'vzwwo.com',
  'mentad.com',
  'jezebel.com',
  'mercent.com',
  'rovio.com',
  'wixstatic.com',
  'bingj.com',
  'targetix.net',
  'amzn.to',
  'espn.com.br',
  'ign.com',
  'adfox.ru',
  'kelkoo.com',
  'reference.com',
  'runadtag.com',
  'myswitchads.com',
  'fqrouter.com',
  'saymedia.com',
  'xhcdn.com',
  'nymag.com',
  'nba.com',
  'polarmobile.com',
  'snapengage.com',
  'swoop.com',
  'vbulletin.com',
  'leafletjs.com',
  'mlstatic.com',
  's-microsoft.com',
  'terra.com.br',
  'drudgereport.com',
  'sabah.com.tr',
  'sporx.com',
  'boomtrain.com',
  'ad-maven.com',
  'bloglovin.com',
  'swypeconnect.com',
  'vui.vn',
  'mynet.com',
  'splash-screen.net',
  'more-results.net',
  'tunein.com',
  'google.com.my',
  'proptp.net',
  'uol.com',
  'oppuz.com',
  'castaclip.net',
  'errorception.com',
  'lexity.com',
  'dreamsadnetwork.com',
  'duckduckgo.com',
  'naver.com',
  'adobesc.com',
  'pandasoftware.com',
  'kiloo.com',
  'sunbeltsoftware.com',
  'logentries.com',
  'rtbsrv.com',
  'quantcast.com',
  'providesupport.com',
  'vox.com',
  'emodio.com',
  'advconversion.com',
  'qpic.cn',
  'wellsfargo.com',
  'browser-update.org',
  'zenguard.biz',
  'boostadvtracking.com',
  'samsungcloudsolution.com',
  'shoprunner.com',
  'tinnong247.net',
  'intermarkets.net',
  'worthly.com',
  'mol.im',
  'likes.com',
  'fmpub.net',
  'maxthon.com',
  'edigitalsurvey.com',
  'servingrealads83.com',
  '163.com',
  'jump-time.net',
  'pornmd.com',
  'goal.com',
  'mynet.com.tr',
  'ancestry.com',
  'dermstore.com',
  'easybreathe.com',
  'box.net',
  'mycdn.me',
  'etahub.com',
  'payclick.it',
  'blip.tv',
  'adrsp.net',
  'apigee.net',
  'extensionanalytics.com',
  'sayyac.net',
  'upsight-api.com',
  'centauro.com.br',
  'ebay.co.uk',
  'espn3.com',
  'wix.com',
  'wix.com.br',
  'page.com',
  'page.com.br',
  'msfsob.com',
  'aboutads.info',
  'eproof.com',
  'editmysite.com',
  'trrsf.com',
  'meltdsp.com',
  'zaloapp.com',
  'secondspace.com',
  'keezmovies.com',
  'movieseum.com',
  'lockerdome.com',
  'jsrdn.com',
  'ad6media.fr',
  'alephd.com',
  'spankwire.com',
  'virgilio.it',
  'everyplay.com',
  'tbcdn.cn',
  'targetimg2.com',
  'horsered.com',
  'ally.com',
  'siftscience.com',
  'hotelurbano.com',
  'dellsupportcenter.com',
  'abcnews.com',
  'adsmarket.com',
  'repubblica.it',
  'netflix.net',
  'medleyads.com',
  'richmetrics.com',
  'phonepower.com',
  'picadmedia.com',
  'imgsmail.ru',
  'sonicwall.com',
  'theblaze.com',
  'targetimg3.com',
  'msocdn.com',
  'luyou360.cn',
  'gittigidiyor.net',
  'mlapps.com',
  'dynectmedia6degrees.com',
  'resultspage.com',
  'goodgamestudios.com',
  'reamp.com.br',
  'foxsports.com',
  'burt.io',
  'feiwei.tv',
  'shareth.ru',
  'espnfrontrow.com',
  'ermisvc.com',
  'w3i.com',
  'publichd.eu',
  'exct.net',
  '24hstatic.com',
  'buscape.com',
  'foxnewsinsider.com',
  'viewmixed.com',
  'redtubefiles.com',
  'webssearches.com',
  'yelpcdn.com',
  'adultfriendfinder.com',
  'lavanetwork.net',
  'fb.com',
  'france24.com',
  'rockyou.com',
  'jwplatform.com',
  'customersvc.com',
  'targetimg1.com',
  'extremetube.com',
  'pandasecurity.com',
  'indiatimes.com',
  'venturecapitalnews.us',
  'brand.net',
  '4shared.com',
  'cnt.my',
  'pictela.net',
  'mulctsamsaracorbel.com',
  'ymail.com',
  'learni.st',
  'youronlinechoices.com',
  'tinypic.com',
  'mega.co.nz',
  'bostonglobe.com',
  'naturalon.com',
  'atil.info',
  'lavamobiles.com',
  'hizliresim.com',
  'friendfeed.com',
  'fame10.com',
  'sheknows.com',
  'cootek.com',
  'usekahuna.com',
  'zelfy.com',
  'friv.com',
  'expedia.com',
  'egistec.com',
  'espnscrum.com',
  'jsadapi.com',
  'worldcat.org',
  'clovenetwork.com',
  'mandrillapp.com',
  'microad.jp',
  'allrecipes.com',
  'tuoitre.vn',
  'qhimg.com',
  'catsupagedwelcome.com',
  'realclearpolitics.com',
  'weheartit.com',
  'pub2srv.com',
  'trackerfix.com',
  'apps.fm',
  'rnengage.com',
  'myfitnesspal.com',
  'begun.ru',
  'videologygroup.com',
  'weather.gov',
  'dmtracker.com',
  'ew.com',
  'foxnewsgo.com',
  'emailsrvr.com',
  'washingtontimes.com',
  'bleacherreport.net',
  'box.com',
  'xtify.com',
  'ppjol.com',
  'sweet-page.com',
  'nt.vc',
  'adshostnet.com',
  'alpha00001.com',
  'startappexchange.com',
  'shareasale.com',
  'sexypartners.net',
  'superuser.com',
  'windowssearch.com',
  'torrentsmd.com',
  'astromenda.com',
  'phpbb.com',
  'openxadexchange.com',
  'hubrus.com',
  'threattrack.com',
  'ravenjs.com',
  'shbdn.com',
  'ghostery.com',
  'rottentomatoes.com',
  'uc.cn',
  'comcast.com',
  'voxmedia.com',
  'sony.com',
  'gaytube.com',
  'chaordicsystems.com',
  'answerscloud.com',
  'tru.am',
  'truste-svc.net',
  'xtube.com',
  'jalopnik.com',
  'torchbrowser.com',
  'lifefactopia.com',
  'huluim.com',
  'clicksor.com',
  'awin1.com',
  'captifymedia.com',
  'realmediadigital.com',
  'ttnet.com.tr',
  'ebay.de',
  'coullmedia.com',
  'stopbullying.gov',
  'foodnetwork.com',
  'dana123.com',
  'guardian.co.uk',
  '1688.com',
  'adrttt.com',
  'skinected.com',
  'myimagetracking.com',
  'mercadoclics.com',
  'mmcdn.cn',
  'wmflabs.org',
  'gorillanation.com',
  'ppjol.net',
  'thescore.com',
  'authorize.net',
  'milliyetvideo.com',
  'peeperz.com',
  'apptap.com',
  'wikia-beacon.com',
  'innity.com',
  'yourjavascript.com',
  'ad120m.com',
  'milliyetemlak.com',
  'blizzard.com',
  'cnnmexico.com',
  'acer.com',
  'peel.com',
  'h3q.com',
  'popcash.net',
  'nest.com',
  'bitdefender.com',
  'newsvine.com',
  'yify-torrents.com',
  'porniq.com',
  'umbel.com',
  'wikia.com',
  'viafoura.com',
  'skim.gs',
  'quickbooks.com',
  'likes-media.com',
  'nflcdn.com',
  'baza.vn',
  'sojern.com',
  'cimcontent.net',
  'minireklam.com',
  'prq.to',
  'lifescript.com',
  'reklamz.com',
  'buysub.com',
  'pbwstatic.com',
  'etbxml.com',
  'outfit7.com',
  'rt.com',
  'ig.com.br',
  'servedbyopenx.com',
  'adtechjp.com',
  'cashtrafic.info',
  'gnu.org',
  'mobilecore.com',
  'thepiratebay.se',
  'magnetic.is',
  'estat.com',
  'oasgames.com',
  'viafoura.net',
  'wp.me',
  'lpsnmedia.net',
  'ssuggest.com',
  'plex.tv',
  'gosquared.com',
  'r7.com',
  'yellowpages.com',
  'exacttarget.com',
  '9cache.com',
  'suproo.com',
  'springboardplatform.com',
  'realsimple.com',
  'gazetevatan.com',
  'wikiquote.org',
  'brtstats.com',
  'eggnogthrushdeemster.com',
  'samsungdm.com',
  'allshareplay.com',
  'serverfault.com',
  'usatoday.net',
  'assetfiles.com',
  'youversionapi.com',
  'espn.com.au',
  'exip.org',
  'youporngay.com',
  'clixmetrix.com',
  'kixer.com',
  'nict.jp',
  'cnn.it',
  'wikihow.com',
  'ebdr2.com',
  'linkhay.com',
  'rollingstone.com',
  'usa.gov',
  'dowjoneson.com',
  'alljoyn.org',
  'parentalcontrolbar.org',
  'mediasoul.net',
  'livestrong.com',
  'instacontent.net',
  'securestudies.com',
  'theglobeandmail.com',
  'microsoftonline-p.com',
  'tapstream.com',
  'wsj.net',
  'kickstarter.com',
  'ntius.com',
  '1iota.com',
  'teamskeetimages.com',
  'yimgr.com',
  'userapi.com',
  'datasphere.com',
  'donation-tools.org',
  'compare-electronics.net',
  'aliyuncs.com',
  'experian.com',
  'optimost.com',
  'audienceamplify.com',
  'realtor.com',
  'soha.vn',
  'alipay.com',
  'shape.com',
  'under-myscreen.be',
  'blogcdn.com',
  'socialreader.com',
  'flipkart.com',
  'ticketmaster.com',
  'photobucket.com',
  'nationalreview.com',
  'pusher.com',
  'hobwelt.com',
  'ptp123.com',
  'validwin.com',
  'guim.co.uk',
  'adersite.com',
  'popsugar.com',
  'icptrack.com',
  'stackauth.com',
  'laban.vn',
  'cbc.ca',
  'wellsfargomedia.com',
  'tccdn.com',
  'mathoverflow.net',
  'honcode.ch',
  'msnbc.com',
  'delivery.net',
  'oclasrv.com',
  'ibm.com',
  'merriam-webster.com',
  'firefox.com',
  'trib.al',
  'remat.ca',
  'hao123.com',
  'qadserve.com',
  'tightendjump.com',
  'accesshollywood.com',
  'idqqimg.com',
  'styleblazer.com',
  'sessioncam.com',
  'sendgrid.net',
  'newnext.me',
  'xiami.com',
  'force.com',
  'clkads.com',
  'reacheyes.net',
  'ngaynay.vn',
  'alimama.com',
  'miniclip.com',
  'adbutter.net',
  'ipecho.net',
  'mediawhite.com',
  'istockphoto.com',
  'intercom.io',
  'microsoftstore.com',
  'bdstatic.com',
  'virgul.com',
  'eloqua.com',
  'sling.com',
  'glispa.com',
  'vice.com',
  'conduit-services.com',
  'embed.ly',
  'nflxvideo.net',
  'ambientdigitalgroup.com',
  'travelzoo.com',
  'sfdict.com',
  'footlocker.com',
  'zgncdn.com',
  'bongacams.com',
  'igodigital.com',
  'footballfanatics.com',
  'feedjit.com',
  'adfrontiers.com',
  'sonos.com',
  'thefreedictionary.com',
  'fitbit.com',
  'health1st.com',
  'switchadhub.com',
  'ozy.com',
  '9gag.tv',
  'prnewswire.com',
  'ntv.io',
  'arkadiumhosted.com',
  'cdc.gov',
  'matrixspa.it',
  'sfgate.com',
  'boswp.com',
  'buzzdock.com',
  'mediaoptout.com',
  'uploaded.net',
  'openh264.org',
  'bossip.com',
  'valuepubmedia.com',
  'crowdignite.com',
  'jivox.com',
  'ntvspor.net',
  'haber7.com',
  'winaffiliates.com',
  'extreme-dm.com',
  'awempire.com',
  'scene7.com',
  'ustiming.org',
  'travelandleisure.com',
  'track8172.com',
  'playwire.com',
  'cbslocal.com',
  'thegioicongai.net',
  'genericlink.com',
  'veinteractive.com',
  'wayfair.com',
  'zap.com.br',
  'webcollage.net',
  'oclaserver.com',
  'askubuntu.com',
  'netshoes.com.br',
  'microad-cn.com',
  'heapanalytics.com',
  'cxt.ms',
  'ebit.com.br',
  'economist.com',
  'csctrustedsecure.com',
  'abalo.vn',
  'weeklystandard.com',
  'gamek.vn',
  'highcpms.com',
  'esm1.net',
  'dolphin-browser.com',
  'myfoxny.com',
  'castplatform.com',
  'snapdoapp.com',
  'zillowstatic.com',
  'smh.com.au',
  'craigconnects.org',
  'tealiumiq.com',
  'dlink.com',
  'gifts.com',
  'westelm.com',
  'reporo.net',
  'products-marketplace.com',
  'howlifeworks.com',
  'livejasmin.com',
  'nflxext.com',
  'usps.com',
  'torrentz.eu',
  'siteblindado.com',
  'haydaygame.com',
  'linkz.net',
  'ad-center.com',
  'iana.org',
  'www.net.cn',
  'roimediadigital.com',
  'ebay.ca',
  'wikisource.org',
  'shopathome.com',
  'giphy.com',
  'vagas.com.br',
  'radiotime.com',
  'lolstatic.com',
  'smithmicro.com',
  'mangomediaads.com',
  'general-marketplace.com',
  'tellaparts.com',
  'afilio.com.br',
  'tindersparks.com',
  'hurriyetaile.com',
  'redlaser.com',
  'baomoi.com',
  'kataweb.it',
  'dreamstime.com',
  'metacritic.com',
  'owneriq.com',
  'scribblelive.com',
  'abuse-lawyer.com',
  'openstat.net',
  'staticsfly.com',
  'vzw.com',
  'dmtio.net',
  'wildgames.com',
  'bizo.com',
  'verizonwireless.com',
  'goember.com',
  'reduxmediagroup.com',
  'examiner.com',
  'txmblr.com',
  'nasdaq.com',
  'serve-sys.com',
  'mtvnservices.com',
  'ebay.in',
  'adocean.pl',
  'ebay-us.com',
  'amap.com',
  'auditude.com',
  'frameddisplay.com',
  'weebly.com',
  'webscorebox.com',
  'update-apps.com',
  'bazoocam.org',
  'ammadv.it',
  'rivals.com',
  'omniata.com',
  'trrsf.com.br',
  'sociaplus.com',
  'mediav.com',
  'adxpose.com',
  'libero.it',
  'bigfineads.com',
  'realitytraffic.com',
  'fqtag.com',
  'cobaltgroup.com',
  'neverblue.com',
  'atlassolutions.com',
  'thongtinnonghoi.com',
  'skyfire.com',
  'wpcomwidgets.com',
  'q1media.com',
  'filmifullizle.com',
  'lglime.com',
  'bigpara.com',
  'spiceworks.com',
  'crunchbase.com',
  'wxug.com',
  'zap2it.com',
  'bdimg.com',
  'onclasrv.com',
  'adultadworld.com',
  'jd.com',
  'freegeoip.net',
  '360buyimg.com',
  'webtrekk.net',
  'eastbay.com',
  'mochiads.com',
  'standard.co.uk',
  'data-slimspots.com',
  'xinhuanet.com',
  'telemetrytaxonomy.net',
  'sumome.com',
  'flixcart.com',
  'whatsapp-sharing.com',
  'linkury.com',
  'epom.com',
  'imp-serving.com',
  'swiftypecdn.com',
  'jntwrk.com',
  'geoplugin.net',
  'mpstat.us',
  'adobelogin.com',
  'yunos.com',
  'bfi0.com',
  'laiwang.com',
  'lduhtrp.net',
  'muachung.vn',
  'slickdeals.net',
  'r7ls.net',
  'kakao.com',
  'cookinglight.com',
  'ahalogy.com',
  'bldrdoc.gov',
  'adacado.com',
  'mobytrks.com',
  'caliser.com',
  'hubspot.net',
  'vastglows.com',
  'msn.com.br',
  'adweek.com',
  'lostwaldo.com',
  'xunlei.com',
  'icmwebserv.com',
  'ebaymotorsblog.com',
  'mapbox.com',
  'em.io',
  'nintendowifi.net',
  'qhmsg.com',
  'thoughtleadr.com',
  'ebay.fr',
  'rmlacdn.net',
  'refinery29.com',
  'mtv.com',
  'grooveshark.com',
  'cambio.com',
  'viaf.org',
  'samsungcloudsolution.net',
  'mlb.com',
  'ifunny.mobi',
  'j2inter.com',
  'tinchieu.com',
  'blinkx.com',
  'bantintuoitre.com',
  'kqzyfj.com',
  'displaymarketplace.com',
  'ebay.com.au',
  'mobisla.com',
  'crateandbarrel.com',
  'livejournal.com',
  'trove.com',
  'x1cdn.com',
  'iminent.com',
  'fastcodesign.com',
  'appboy.com',
  'savefront.com',
  'quickheal.com',
  'spilgames.com',
  'rounds.com',
  'mediander.com',
  'last.fm',
  'goforandroid.com',
  'panthercdn.com',
  'stylelist.com',
  'ojrq.net',
  'bluelionsports.com',
  'awltovhc.com',
  'mologiq.net',
  'bdnsrt.org',
  'amxdt.com',
  'swiftype.com',
  'installshield.com',
  'jscods.cf',
  'reviewed.com',
  'onswipe.com',
  'loggly.com',
  'timeanddate.com',
  'hyprmx.com',
  'elasticbeanstalk.com',
  'fotomac.com.tr',
  'southernliving.com',
  'google.com.ph',
  'vdopia.com',
  'breitbart.com',
  'adextent.com',
  'akafms.net',
  'inner-active.mobi',
  'alisoft.com',
  'sohu.com',
  'sayyac.com',
  'gsimedia.net',
  'whstatic.com',
  'zanox.ws',
  'filepicker.io',
  'youtube-mp3.org',
  'rtbserver.com',
  'bitgravity.com',
  'trafficholder.com',
  'wattpad.com',
  'audioscrobbler.com',
  'networkadvertising.org',
  'admaster.com.cn',
  'xaxis.com',
  'icontact.com',
  'fanatik.com.tr',
  '9apps.com',
  'rd.com',
  'siecdn.com',
  'hootsuite.com',
  'yenibiris.com',
  'sinajs.cn',
  'gettyimages.com',
  'anrdoezrs.net',
  'suggest.com',
  'craveonline.com',
  'boxofficemojo.com',
  'recode.net',
  'postimg.org',
  'mlstat.com',
  'creafi-online-media.com',
  'bstatic.com',
  'localresponse.com',
  'mahmure.com',
  'mybrowserbar.com',
  'fastcocreate.com',
  'weibo.cn',
  'battle.net',
  'gscontxt.net',
  'trvl-media.com',
  'madamenoire.com',
  'onelouder.com',
  '4at5.net',
  'deadline.com',
  'thefind.com',
  'trbimg.com',
  'ironlionfun.com',
  'craigslistjoe.com',
  'bkav.com.vn',
  'livelook.com',
  'badoo.com',
  'abc.net.au',
  'dailycaller.com',
  'skeettools.com',
  'homedepot.com',
  'netshelter.net',
  'plista.com',
  'internetat.tv',
  'xahoi247.net',
  'hearstmags.com',
  'quettra.com',
  'arstechnica.com',
  'womanitely.com',
  'ebay.be',
  'startv.com.tr',
  'pokki.com',
  'ariamax.it',
  'mesh.com',
  'oovoo.com',
  'delta-homes.com',
  'cnnfn.com',
  'huffingtonpost.ca',
  'incmd10.com',
  'mkk.com.tr',
  'instantservice.com',
  'esquire.com',
  'edgefonts.net',
  'zopim.io',
  'huffingtonpost.co.uk',
  'foxydeal.com',
  'google.pl',
  'seatgeek.com',
  'nytstore.com',
  'rapidgator.net',
  'garmin.com',
  'adschoom.com',
  'sitestat.com',
  'ebay.at',
  'gamespot.com',
  'cookingsubstitute.com',
  'mncdn.com',
  'placelocal.com',
  'feedsportal.com',
  'medium.com',
  'gltrkk.net',
  'health.com',
  '247wallst.com',
  'mackolik.com',
  'stylemepretty.com',
  'savemyshows.com',
  'radiobeat.com.br',
  'tqlkg.com',
  'lookout.com',
  'invisionpower.com',
  'onedio.com',
  'milliyet.tv',
  'mirror.co.uk',
  'httptrack.com',
  'travel-assets.com',
  'productsmagazines.com',
  'theweek.com',
  'paragonads.vn',
  'audiencemanager.de',
  'nytm.org',
  'ftjcfx.com',
  'product-subsitute.net',
  'spiegel.de',
  'pushwoosh.com',
  'denverpost.com',
  'autoblog.com',
  'whitehouse.gov',
  'disney.com',
  'ning.com',
  'funshion.net',
  'google.se',
  'cozi.com',
  'pbs.org',
  'oaspapps.com',
  'adrcdn.com',
  'dishaccess.tv',
  'houzz.com',
  'behance.net',
  'mercadolivre.com.br',
  'escinteractive.com',
  'cedexis.org',
  'oui-0x00199d.com',
  'dewmobile.net',
  'food-substitute.net',
  'gsspat.jp',
  'vizejs.info',
  'wearemadeinny.com',
  'systweak.com',
  'ministerial5.com',
  'adbroker.de',
  'intentmedia.net',
  'websosanh.vn',
  'delvenetworks.com',
  'barnesandnoble.com',
  'fontdeck.com',
  'barrons.com',
  'thetrafficstat.net',
  'paypal-communication.com',
  'visilabs.com',
  'pastebin.com',
  'ic-live.com',
  'yakala.co',
  'instyle.com',
  'ebay.com.hk',
  'vietnamnet.vn',
  'match.com',
  'zenmate.com',
  'href.asia',
  'icpsc.com',
  'csmonitor.com',
  'dictionary.com',
  'kii.com',
  'districtm.ca',
  'verizon.net',
  'billmelater.com',
  'nbc.com',
  'foodandwine.com',
  'gssprt.jp',
  'pushbullet.com',
  'dianomi.com',
  'kargo.com',
  'xhamsterpremiumpass.com',
  'gtags.net',
  'fazenda.gov.br',
  'southwest.com',
  'wikibooks.org',
  'netd.com',
  'canonical.com',
  'jeep.com',
  'go2rewards.com',
  'incommon.org',
  'mcclatchydc.com',
  'orkut.com.br',
  'isidewith.com',
  'nwps.ws',
  'pcworld.com',
  'zlcdn.com',
  'dishanywhere.com',
  'nhaccuatui.com',
  'trw12.com',
  'rcsmetrics.it',
  'atv.com.tr',
  'octoshape.net',
  '3.cn',
  'burakoyunda.net',
  'kimia.es',
  'adage.com',
  'meraki.com',
  'clickbank.net',
  'adsdumpo.com',
  'bstk.co',
  'jscripts.org',
  'niziot.com',
  'ebay.com.cn',
  'bhaskar.com',
  'ebay.ie',
  'ivcbrasil.org.br',
  'pixfuture.net',
  'mercadolibre.com',
  'chaturbate.com',
  'thesyndicationserver.co.uk',
  'cbs.com',
  'ipredictive.com',
  'bongdaplus.vn',
  'tsn.ca',
  'xiaomi.net',
  '7eer.net',
  'verizon.com',
  'yieldify.com',
  'niwali.com',
  'ibtimes.com',
  'wmt.co',
  'sinaimg.cn',
  'rnmd.net',
  'hastrk2.com',
  'ad-sys.com',
  'epoch.com',
  'v9.com',
  'themeforest.net',
  'bongda.com.vn',
  'trb.com',
  'sportscenter.com',
  'flowplayer.org',
  'inquisitr.com',
  'smartling.com',
  'sancdn.net',
  'grab-media.com',
  'vinacaptcha.com',
  'offthebus.org',
  'telemetryaudit.com',
  'admaxserver.com',
  'teamespn.com',
  '51.la',
  'controlyourtv.org',
  'myrecipes.com',
  'cqq5id8n.com',
  'boomads.com',
  'kiloo-games.com',
  'ophan.co.uk',
  'adspirit.net',
  'certona.net',
  'ptp33.com',
  'placehold.it',
  'troveread.com',
  'ixxx.com',
  'genieesspv.jp',
  'genieessp.jp',
  'jscache.com',
  'tracking8171.com',
  'mom.me',
  'pgcdn.com',
  'quikr.com',
  'aexp-static.com',
  'fortiguard.net',
  'shutterstock.com',
  'thebighits.com',
  'vccorp.vn',
  'thesaurus.com',
  'leadpages.net',
  'myhomeideas.com',
  'vserv.mobi',
  'planalto.gov.br',
  'blogads.com',
  'incmd03.com',
  'indianexpress.com',
  'hurriyetdailynews.com',
  'boombeachgame.com',
  'propelplus.com',
  'golf.com',
  'fanatics.com',
  'getm.pt',
  'sonyentertainmentnetwork.com',
  'ebaypartnernetwork.com',
  'pricedetect.com',
  'accountonline.com',
  'commissionlounge.com',
  'rutarget.ru',
  'trendcounter.com',
  'umunu.com',
  'lasvegassun.com',
  'voipwelcome.com',
  'aztecbe.com',
  'google.com.tw',
  'shopperconnect.com',
  'ekolay.net',
  'pandonetworks.com',
  'genieessp-a.com',
  'salesforce.com',
  'explabs.net',
  'b-io.co',
  'epsihost.com',
  'variety.com',
  'stripe.com',
  'tripadvisor.com.tr',
  'vrvm.com',
  'ilibr.org',
  'dtravelconnection.com',
  'grnh.se',
  'olx-st.com',
  'rr.com',
  'guardianapps.co.uk',
  'emgn.com',
  'medscape.com',
  'mashery.com',
  'sh.st',
  'wondershare.com',
  'eventbrite.com',
  'invodo.com',
  'netbookmedia.com',
  'falecomog1.com.br',
  'criminalcasegame.com',
  'peopleenespanol.com',
  'potterybarn.com',
  'tndmnshb.com',
  'zoopla.co.uk',
  'etracker.de',
  'xiti.com',
  'makers.com',
  '35go.cn',
  'sa-live.com',
  'jpush.cn',
  'goodwaygroup.com',
  'trustkeeper.net',
  'unicornmedia.com',
  'xidx.org',
  'suntimes.com',
  'nesine.com',
  'hurriyettv.com',
  'thanhnien.com.vn',
  'imshopping.com',
  'mac.com',
  'intag.co',
  'affinity.com',
  'tvinteractive.tv',
  'corriere.it',
  'lowermybills.com',
  'truthrevolt.org',
  'gooncheck.com',
  'glassdoor.com',
  'mgtracker.org',
  'motherjones.com',
  'osdimg.com',
  'govdelivery.com',
  'statig.com.br',
  'funweek.it',
  'sina.com.cn',
  'mathjax.org',
  'blogherads.com',
  'agame.com',
  'curse.com',
  'glowingskinsecret.com',
  'betweendigital.com',
  'cxpublic.com',
  'enbac.com',
  'gmodules.com',
  'easytomessage.com',
  'smithsonianmag.com',
  'trackimpression.com',
  'best-products-review.com',
  'jasmin.com',
  'c-launcher.com',
  'dominos.co.uk',
  'sahadan.com',
  'go2cloud.org',
  'peakgames.net',
  'ad127m.com',
  'bridgetrack.com',
  'miniclipcdn.com',
  'adrcntr.com',
  'arcadeweb.com',
  'springserve.com',
  'tatami-solutions.com',
  'iconosquare.com',
  'zeobit.com',
  'infowars.com',
  'agilone.com',
  'pravda.ru',
  'solo-launcher.com',
  'lporirxe.com',
  'google.com.co',
  'myway.com',
  'getfirefox.com',
  'messagelabs.com',
  'mabaya.com',
  'onesmartpenny.com',
  'investors.com',
  'logitech.com',
  'turbobit.net',
  'darchermedia.com',
  'hepsiburada.net',
  'minecraftevi.com',
  'beenverified.com',
  'getresponse.com',
  'perfectnavigator.com',
  'mortgagesmade.com',
  'loans-made.com',
  'monstersandcritics.com',
  'shifen.com',
  'corriereobjects.it',
  'consumerviews.net',
  'businesswire.com',
  'capitalone.com',
  'oyunasi.com',
  'contentspread.net',
  'enoratraffic.com',
  'pinger.com',
  'mobogarden.com',
  'vyped.com',
  'danviet.vn',
  'vresp.com',
  'dellbackupandrecovery.com',
  'britannica.com',
  'hepsiburada.com',
  'alkislarlayasiyorum.com',
  'wordego.com',
  'likesharetweet.com',
  'gmarket.co.kr',
  'kastatic.com',
  'idea-marketplace.com',
  'digitaltrends.com',
  'sony.tv',
  'buzzwok.com',
  'streamtheworld.com',
  'jdoqocy.com',
  'boingo.com',
  'aaplimg.com',
  'camads.net',
  'vitv.it',
  'adrtx.net',
  'default-search.net',
  'ulive.com',
  'updaterss.com',
  '173uu.com',
  'maxworkouts.com',
  'srpx.net',
  'ignimgs.com',
  'cetrk.com',
  'nhl.com',
  'stats.com',
  'evergage.com',
  'bostonherald.com',
  'ebay.es',
  'townnews.com',
  'runhaven.com',
  'rongbay.com',
  'airfrance.com',
  'stathat.com',
  'haaretz.com',
  'intercomcdn.com',
  'baronsoffers.com',
  'gr-assets.com',
  'ebayclassifieds.com',
  'getadblock.com',
  'gazzetta.it',
  'hshh.org',
  'blogsmithmedia.com',
  'pubgears.com',
  'medicinenet.com',
  'giadinh.net.vn',
  'naytev.com',
  'aol.it',
  'brasil.gov.br',
  'beeg.com',
  'google.co.ve',
  'kitchendaily.com',
  'farolatino.com',
  'allyou.com',
  'efe.com',
  'dogpile.com',
  'goroost.com',
  'seccint.com',
  'xmarks.com',
  'ibsys.com',
  'offer-dynamics.com',
  'reponets.com',
  'yes.my',
  'bbystatic.com',
  'dc-storm.com',
  'zapps.vn',
  'staples.com',
  'kyodonews.jp',
  'pcmag.com',
  'santandernet.com.br',
  'jdownloader.org',
  'google.co.id',
  'whitepages.com',
  'newshunt.com',
  'discover.com',
  'intensedebate.com',
  'onlineregister.com',
  'scubl.com',
  'webovernet.com',
  'telize.com',
  'vanityfair.com',
  'rcs.it',
  'demonoid.com',
  'iol.it',
  'dallascowboys.com',
  'bhg.com',
  'innovatenetworks.com',
  'fout.jp',
  'rackspacecloud.com',
  'irna.ir',
  'pornhubpremium.com',
  'cdn-apple.com',
  'flxpxl.com',
  'searchignite.com',
  'caixa.gov.br',
  'barclaycardus.com',
  'nytco.com',
  'htcsense.com',
  'everydayhealth.com',
  'etracker.com',
  'heraldonline.com',
  'grainger.com',
  'ptpcpm.com',
  'comscore.com',
  't4ft.de',
  'bluehost.com',
  'state.gov',
  'cometourgeorgia.com',
  'affinitymatrix.com',
  'trulia.com',
  'uniblue.com',
  'trbas.com',
  'synology.com',
  'llbean.com',
  'spinr.in',
  'miaozhen.com',
  'hurriyetkampus.com',
  'games.com',
  'jsonip.com',
  '247inc.net',
  'adshexa.com',
  'sfx.ms',
  'dbjhr.com',
  'putags.com',
  'pickmeup-ltd.com',
  'trafficshop.com',
  'line-apps.com',
  'ampxchange.com',
  'serpro.gov.br',
  'preyproject.com',
  'imrk.net',
  'att.net',
  'healthination.com',
  'mysearchdial.com',
  'adledge.com',
  'baixaki.com.br',
  'snxd.com',
  'ionicframework.com',
  'ebay.com.my',
  'scribdassets.com',
  'lp4.io',
  'polyvore.com',
  'rvty.net',
  'crawlability.com',
  'freebase.com',
  'radio.com',
  'videohub2.tv',
  'adziff.com',
  'brazzers.com',
  'sndimg.com',
  'kiplinger.com',
  'vagalume.com.br',
  'advance.net',
  'coastalliving.com',
  'tfd.com',
  'apnewsregistry.com',
  'mediadecision.com',
  'leadboltapps.net',
  'videolan.org',
  'trustlogo.com',
  'exactag.com',
  'twitchmediagroup.com',
  'theweathernetwork.com',
  'webrootcloudav.com',
  'auctiva.com',
  'bangmychick.com',
  'zeusclicks.com',
  'irs.gov',
  'urbandictionary.com',
  'minus.com',
  '4wmarketplace.com',
  'departures.com',
  'stylebistro.com',
  'sweetim.com',
  'junbi-tracker.com',
  'brandsmind.com',
  'heatmap.it',
  'amplitude.com',
  'mdotm.com',
  'marca.com',
  'tkqlhce.com',
  'twonky.com',
  'sendo.vn',
  'usnews.com',
  'cyberpatrol.com',
  'hgtv.com',
  'kazhifu.com',
  'eva.vn',
  'thestreet.com',
  'wsimg.com',
  'bidvertiser.com',
  'cpmshield.com',
  'sunset.com',
  'lonny.com',
  'df-stream.net',
  'liveclicker.net',
  'upsellit.com',
  'vocalocity.com',
  'adxcore.com',
  'nature.com',
  'ustream.tv',
  'advertise.com',
  'eblastengine.com',
  'qiyi.com',
  'thebookinsider.com',
  'supert.ag',
  'dota2.com',
  'thepaperboy.com',
  'breakingburner.com',
  'ilovevideo.tv',
  'tvguide.com',
  'shebudgets.com',
  'jstor.org',
  'toplist.cz',
  'zoom.com.br',
  'wpdigital.net',
  'baltimoresun.com',
  'listrakbi.com',
  'meetic-partners.com',
  'piriform.com',
  'jobsite.co.uk',
  'metro.co.uk',
  'dumedia.ru',
  'shld.net',
  'tribdss.com',
  'essence.com',
  'rutor.org',
  'hotels.com',
  'chinacache.com',
  'change.org',
  'britishairways.com',
  'vexigo.com',
  'ilivid.com',
  'espnshop.com',
  'newsweek.com',
  'myspacecdn.com',
  'cbox.ws',
  'getpocket.com',
  'primelocation.com',
  'asacp.org',
  'theatlanticwire.com',
  'coreclickhoo.com',
  'mythingsmedia.net',
  'indianrail.gov.in',
  'tvbythenumbers.com',
  'dilcdn.com',
  'babcdn.com',
  'kejet.net',
  'gameforge.com',
  'urbanoutfitters.com',
  'dpmsrv.com',
  'where.com',
  'sciencedirect.com',
  'netnanny.com',
  'appfireworks.com',
  'beygir.com',
  'evidon.com',
  'fplive.net',
  'tbo.com',
  'loc.gov',
  'demonoid.me',
  'prchecker.info',
  'linksalpha.com',
  'zazzle.com',
  'torrentbay.to',
  'mobilityware.com',
  'proximic.com',
  'kanald.com.tr',
  'mandatory.com',
  'charter.net',
  'cracked.com',
  'secure-trkr.com',
  'comm100.com',
  'wordreference.com',
  'macropinch.com',
  'pages03.net',
  'newgenstatsnet.com',
  'apikik.com',
  'iolo.com',
  'ashleymadison.com',
  'trello.com',
  'abril.com.br',
  'neon-lab.com',
  'extend.tv',
  'hurriyetemlak.com',
  'mythings.com',
  'vmn.net',
  'ipromote.com',
  'divx.com',
  'philly.com',
  'so.com',
  'yelp-ir.com',
  'imgiz.com',
  'simplemachines.org',
  'evcdn.com',
  'lowes.com',
  'ssacdn.com',
  'vidobu.com',
  'cmail2.com',
  'snidigital.com',
  'hurriyetcocukkulubu.com',
  'entrepreneur.com',
  'infostrada.it',
  'opinionlab.com',
  'pubventuresmedia.com',
  'anm.co.uk',
  'industrybrains.com',
  'tahminkolik.com',
  'vulture.com',
  'wanelo.com',
  'hdonline.vn',
  'garena.vn',
  'interfax.com',
  'j.mp',
  'houstontexans.com',
  'thisismoney.co.uk',
  'citrix.com',
  'puu.sh',
  'crobo.com',
  'bamstatic.com',
  'steelers.com',
  'edgecastdns.net',
  'splashtop.com',
  'cbsistatic.com',
  'api.tv',
  'reachadv.it',
  'extole.com',
  'nasiltv.com',
  'dnsomatic.com',
  'online.gov.vn',
  'plimplim.com.br',
  'broadcastingcable.com',
  'picsart.com',
  'thisoldhouse.com',
  'bigcommerce.com',
  'philadelphiaeagles.com',
  'adoburcrv.com',
  'ranker.com',
  'nsimg.net',
  'smartcampaign.it',
  'greatschools.org',
  'iheartradio.com',
  'patriots.com',
  'qvc.com',
  '9gaging.com',
  'ziplist.com',
  'rocketadserver.com',
  'revolutiongolf.com',
  'scrippscontroller.com',
  'starbucks.com',
  'delta.com',
  'soft365.com',
  'staticpm.com',
  'contadd.com',
  'cpleft.com',
  'pricejs.info',
  'newsmaxhealth.com',
  'moborobo.com',
  'fullhdfilmizle.com',
  'pulse.io',
  'usgs.gov',
  'mediaite.com',
  'tampabay.com',
  'tribune.com',
  'bandito.org',
  'allmusic.com',
  'ipaddresslabs.com',
  'popcap.com',
  'nasil.tv',
  'samsungmobile.com',
  'shns.com',
  'ttinline.com',
  'mailtravel.co.uk',
  'autocompleteplus.com',
  'sawpf.com',
  'sozcu.com.tr',
  'tomshardware.com',
  'yp.com',
  'askmen.com',
  'eventful.com',
  'ambientplatform.vn',
  'bengals.com',
  'bizjournals.com',
  'blogcu.com',
  'dpbolvw.net',
  'undertonevideo.com',
  'newseum.org',
  'friendschecker.com',
  'ultradns.co.uk',
  'cnnarabic.com',
  'adultwebmasternet.com',
  'nai.com',
  'createjs.com',
  'adkontekst.pl',
  'sears.com',
  'cdnetworks.net',
  'cloudsponge.com',
  'intuitstatic.com',
  'hissage.net',
  'databrain.com',
  'lifespan.com',
  'sportstadio.it',
  'xfreeservice.com',
  'buffalobills.com',
  'dsiteproducts.com',
  'exad.me',
  'pqarchiver.com',
  'hellobar.com',
  'chron.com',
  'quifinanza.it',
  'downloadhelper.net',
  'surveey.com',
  'marriott.com',
  'wunderlist.com',
  'elpais.com',
  'xe.com',
  'c-span.org',
  'omnitagjs.com',
  'zonealarm.com',
  'okmagazine.com',
  'opentable.com',
  'dsg.com',
  'spamexperts.com',
  'pbteen.com',
  'nanglobal.com',
  'zip.net',
  'oldtiger.net',
  'extratorrent.cc',
  'wikinews.org',
  'teach.org',
  'basecamp.com',
  'medianetadvertising.com',
  'colts.com',
  'healthgrades.com',
  'williamhill.it',
  'newegg.com',
  'cpmterra.com',
  'ads-creativesyndicator.com',
  'cars.com',
  'quickplay.com',
  'ireport.com',
  'wandoujia.com',
  'wikiversity.org',
  'privateinternetaccess.com',
  'clevelandbrowns.com',
  'upi.com',
  'uptolike.com',
  'nflshop.com',
  'imgur-ysports.com',
  'earthlink.net',
  'dtvbb.tv',
  'taringa.net',
  'ziffdavis.com',
  'eddiebauer.com',
  'surveygizmo.com',
  'ehowcdn.com',
  'food.com',
  'heyzap.com',
  'popdust.com',
  'dtinews.vn',
  'ramp.com',
  'foxitcloud.com',
  'ampdesk.com',
  'srvabc.com',
  'pawnation.com',
  'mediaset.it',
  'hi-mediaserver.com',
  'miamidolphins.com',
  'blogher.com',
  'babbel.com',
  'fptad.net',
  'pagesix.com',
  'sendmessagebox.com',
  'inatjs.info',
  'patch.com',
  'mgccw.com',
  'tmgrup.com.tr',
  'paradox.com',
  'qihoo.com',
  'hurriyetoto.com',
  'deca.vn',
  'deviantart.net',
  'climatempo.com.br',
  'adhexa.com',
  'foreignpolicy.com',
  'miamiherald.com',
  'madadsmedia.com',
  'fyleio.com',
  'llnw.net',
  'ebay.nl',
  'nbcconnecticut.com',
  'opensharing.org',
  'd-nb.info',
  'flyertown.ca',
  'selectmedia.asia',
  'quicktransmit.com',
  'bongdaso.com',
  'rmgserving.com',
  'profootballhof.com',
  'denverbroncos.com',
  'mydomainadvisor.com',
  'meetup.com',
  'tracker-ccc.de',
  'united.com',
  'meredith.com',
  'copyright.com',
  'bahis-sirketleri.com',
  'yardbarker.com',
  'untd.com',
  'jaguars.com',
  'npario-inc.net',
  'agoda.com',
  'miibeian.gov.cn',
  'thegatewaypundit.com',
  'europa.eu',
  'kingsoftstore.com',
  'usbank.com',
  'fxdepo.com',
  'google.be',
  'msdn.com',
  'oroll.com',
  'payn.me',
  'l1o0l11lo11011o.com',
  'nationalgeographic.com',
  'scrippsnetworks.com',
  'sbitinjs.info',
  'ibxads.com',
  'servebom.com',
  'yesware.com',
  'ibtracking.com',
  'bradesco.com.br',
  'newyorkjets.com',
  'vidyoda.com',
  'liebao.cn',
  'vidmate.net',
  'getsatisfaction.com',
  'packers.com',
  'slacker.com',
  'dtzads.com',
  'appwork.org',
  'mailonsunday.co.uk',
  'ixigo.com',
  'wurfl.io',
  'limelight.com',
  'tbcache.com',
  'applifier.info',
  'suckhoedoisong.vn',
  'yelp-press.com',
  'siviaggia.it',
  'ibxk.com.br',
  'toofab.com',
  'e7r.com.br',
  'medio.com',
  'meccahoo.com',
  'ticketexchangebyticketmaster.com',
  'livingsocial.com',
  'kcchiefs.com',
  'aliyuncdn.com',
  'musixmatch.com',
  'chargers.com',
  '3q.com.vn',
  'acunn.com',
  'cmail1.com',
  'gez.io',
  'madmimi.com',
  'nflrush.com',
  'aimatch.com',
  'okcupid.com',
  'thenextweb.com',
  'buonissimo.org',
  'onsugar.com',
  'savefrom.net',
  'adopshost1.com',
  'perfectlytimedpics.com',
  'zini.vn',
  'yelp-support.com',
  'ibpxl.com',
  'italiaonline.it',
  'tonefuse.com',
  'veeseo.com',
  'affiz.net',
  'newsmax.com',
  'liqwid.net',
  'prezi.com',
  'emediate.dk',
  'customer.io',
  'dilei.it',
  'martiniadnetwork.com',
  'drtuber.com',
  'campaignism.com',
  'ccbill.com',
  'chicagobears.com',
  'frontdoor.com',
  'adrdgt.com',
  'kokteyl.com',
  'adversal.com',
  'admailtiser.com',
  'upsjobs.com',
  'ikea.com',
  'teamfanshop.com',
  'v3cdn.net',
  '500px.com',
  'gfsrv.net',
  'lightningnewtab.com',
  'anycastcdn.net',
  'uzmantv.com',
  'evite.com',
  'ati-host.net',
  'globaltestmarket.com',
  'detroitlions.com',
  'upworthy.com',
  'msgamestudios.com',
  'ethn.io',
  'e2ma.net',
  'marketgid.com',
  'maudau.com',
  'thedenverchannel.com',
  'softonic.com',
  'crowdynews.com',
  'mediamond.it',
  'dsusw.net',
  'advertserve.com',
  'gmx.com',
  'as.com',
  'stackapps.com',
  'akilli.tv',
  'simplytechnology.net',
  'fastcoexist.com',
  'celebuzz.com',
  'skor.tv',
  'cheetahmail.com',
  'ibtimes.co.uk',
  'lovedgames.com',
  'vevo.com',
  'bd-pl.com',
  'push.io',
  'villarenters.com',
  'uadx.com',
  'emarbox.com',
  'musica.com.br',
  'kmplayer.com',
  'parperfeito.com.br',
  'actnx.com',
  'clientstaticserv.com',
  'internethaber.com',
  'codecanyon.net',
  'addthiscdn.com',
  'bbc.net.uk',
  'statigr.am',
  'centurylink.net',
  'kavanga.ru',
  'aviary.com',
  '1and1.com',
  'hrblock.net',
  'titansonline.com',
  'sellpoints.com',
  'sharesdk.cn',
  'zdnet.com',
  'targetspot.com',
  'hrblock.com',
  'adk2.net',
  'traffichunt.com',
  'vuigame.vn',
  'ilius.net',
  'vaccint.com',
  'adsnative.com',
  'githubapp.com',
  'tvyo.com',
  'privatehomeclips.com',
  'netmahal.com',
  'shazam.com',
  'arcadesafari.com',
  'panthers.com',
  'peoplestylewatch.com',
  'ebaycareers.com',
  'interoperabilitybridges.com',
  'izlesene.com',
  'adsquangcao.com',
  'bloombergview.com',
  'sportingnews.com',
  'dalealplay.com',
  'chinhphu.vn',
  'pixlr.com',
  'ehownowcdn.com',
  'nflyouthpd.com',
  'wenn.com',
  'mackolikcomplex.com',
  'adgorithms.com',
  'mojang.com',
  'activebeat.com',
  'verticalresponse.com',
  'sftcdn.net',
  'bancobrasil.com.br',
  'bitcoin.org',
  'star-telegram.com',
  'sigfig.com',
  'himediads.com',
  'redd.it',
  'anametrix.com',
  'mimecast.com',
  'baidu.com.eg',
  'endeavor.org.tr',
  'thebrittanyfund.org',
  'crdui.com',
  'adspeed.net',
  'tpb.vn',
  'redskins.com',
  'xrosview.com',
  'mercurynews.com',
  'atlantafalcons.com',
  'xiaomi.com',
  'readspeaker.com',
  'dowjones.com',
  'casa.it',
  'hubapi.com',
  'cox.net',
  'arcgisonline.com',
  'luxup.ru',
  'metanetwork.com',
  'hatid.com',
  'wiley.com',
  'adplugcompany.com',
  'neon-images.com',
  'vikings.com',
  'spilcdn.com',
  'azcardinals.com',
  'nascar.com',
  'nationaljournal.com',
  'webink.com',
  'genesismedia.com',
  'webtype.com',
  'torcache.net',
  'ycombinator.com',
  'wdtinc.com',
  'autodesk.com',
  'abc.com',
  'fortinet.net',
  'sellathon.com',
  'mocean.mobi',
  'apiok.ru',
  'revenuehits.com',
  'launchpad.net',
  'netmining.com',
  'parentsociety.com',
  'ribob01.net',
  'diply.com',
  'operamini.com',
  'shopper-pro.com',
  'userzoom.com',
  '22find.com',
  'condenast.com',
  'tinypass.com',
  'speedshiftmedia.com',
  'localworld.co.uk',
  'vetstreet.com',
  'newjobs.com',
  'icq.com',
  'eorezo.com',
  'neworleanssaints.com',
  'zulily.com',
  'buccaneers.com',
  'pxlad.io',
  'edintorni.net',
  'xenforo.com',
  'liveleak.com',
  'google.com.ua',
  'thethao247.vn',
  'admedia.com',
  'pagelyhosting.com',
  'spingo.com',
  'rxlist.com',
  'weightlosspath.com',
  'noviretrack.com',
  'mp3skull.com',
  'lenzmx.com',
  'theupsstore.com',
  'gamblingtherapy.org',
  'ntent.com',
  'extremetracking.com',
  'cmgdigital.com',
  'onestat.com',
  'custhelp.com',
  'wunderloop.net',
  'e-kolay.net',
  'youdao.com',
  'motorlife.it',
  'proxysandy.com',
  'camplace.com',
  'worldlingo.com',
  'nordeus.com',
  'yandexadexchange.net',
  'cubicleoffers.com',
  'hearstnp.com',
  'a-ads.com',
  'macys.com',
  'adsdk.com',
  'inclk.com',
  'synacor.com',
  'atomex.net',
  'careland.com.cn',
  'linuxmint.com',
  'php.net',
  'rovion.com',
  'informaction.com',
  'justjared.com',
  'biblegateway.com',
  'fansided.com',
  'posterous.com',
  'trustpilot.com',
  'rcsobjects.it',
  'doji.vn',
  'rapsio.com',
  'travelers.com',
  'tebilisim.com',
  'terra.com',
  'youku.com',
  'leanplum.com',
  'lgtvsdp.com',
  'fica.vn',
  'phim3s.net',
  'aa.com',
  'fbnstatic.com',
  'emedicinehealth.com',
  'perezhilton.com',
  'rtbpop.com',
  'seahawks.com',
  'grapeshot.co.uk',
  'ocsp-responder.com',
  'opta.net',
  'rivalgaming.com',
  'avgmobilation.com',
  'bloglines.com',
  'maxthon.cn',
  'topix.net',
  'google.com.pe',
  'otherlevels.com',
  'google.ie',
  'flux.com',
  'dpcdn.com',
  'eztv.it',
  'stltoday.com',
  'wsjlocal.com',
  'aljazeera.com',
  'dt07.net',
  'vatgia.com',
  'vupdate2.com',
  'nuggad.net',
  'bufferapp.com',
  'hdviet.com',
  'sharedcount.com',
  'pages04.net',
  'cinemanow.com',
  'ad121m.com',
  'dudamobile.com',
  'uimserv.net',
  'gaug.es',
  'flattr.com',
  'wetransfer.net',
  'rdio.com',
  'yts.re',
  'toysrus.com',
  'samsungelectronics.com',
  'webcitation.org',
  'jumptime.com',
  'adswizz.com',
  'dianxin.net',
  'ubertags.com',
  'hubpages.com',
  'mobilethreat.net',
  'mcafeeasap.com',
  'linezing.com',
  'brainjet.com',
  'bluenationreview.com',
  'fda.gov',
  'ihrhls.com',
  'nyti.ms',
  'ladsp.com',
  'luckyorange.com',
  'daringfireball.net',
  'ebaymainstreet.com',
  'forbadeplanhad.com',
  'n-able.com',
  'deezer.com',
  'ebay.com.sg',
  'www.gov.uk',
  'eu-ibi.co.uk',
  'tmocce.com',
  'trackjs.com',
  'tigerrunhigh.com',
  'tcgtrkr.com',
  'torrent.to',
  'adcast.io',
  'ip-api.com',
  'trulia-cdn.com',
  'furious7.com',
  'talktv.vn',
  'tcimg.com',
  'lscdn.net',
  'htimg.net',
  'apartments.com',
  'whsites.net',
  'reflexion.net',
  'kaft.com',
  'quikdisplay.com',
  'uplynk.com',
  'forbesimg.com',
  'sonymobile.com',
  'rediff.com',
  'esrb.org',
  'wnyc.org',
  'stlouisrams.com',
  'zqtk.net',
  'gnd.com',
  'youtubeaccelerator.com',
  'kralfm.com.tr',
  'samsungyosemite.com',
  'autotrader.com',
  'espnradio.com',
  'jswrite.com',
  'ebay.ph',
  'pphosted.com',
  'ul.to',
  'foolcdn.com',
  'mnginteractive.com',
  'walgreens.com',
  'adexprt.com',
  'ppstream.com',
  'dtvce.com',
  'shockpedia.com',
  'walmartstores.com',
  'tapcommerce.com',
  'getsentry.com',
  'sitelock.com',
  'theberry.com',
  'torrentfrancais.com',
  'touchcommerce.com',
  'networksolutions.com',
  'wfrcdn.com',
  'hotjar.com',
  'eksisozluk.com',
  'ifttt.com',
  'fishwrapper.com',
  'sli-spark.com',
  'piratebrowser.com',
  'geoportal3d.com.br',
  'ebay.pl',
  'iqiyi.com',
  'sumotracker.com',
  'pof.com',
  'gifsoup.com',
  'rezserver.com',
  'oclc.nl',
  'adready.com',
  'filehippo.com',
  'ebuzzing.com',
  'optproweb.info',
  'destinationtips.com',
  'nhacso.net',
  'sf49ers.com',
  'yahoo.co.jp',
  'minecraftforum.net',
  'mobclix.com',
  'dogannet.tv',
  'eyedemand.com',
  'brandads.net',
  'ria.ru',
  'jawbone.com',
  'toyota.com',
  'petametrics.com',
  'storify.com',
  'srdrvp.com',
  'brassring.com',
  'wavesecure.com',
  'tmcs.net',
  'appads.com',
  'hao123img.com',
  'mercadolivre.com',
  'scansoft.com',
  'posttv.com',
  'edb.gov.sg',
  'gcion.com',
  'bluetie.com',
  'prntscr.com',
  'cursecdn.com',
  'sinemalar.com',
  'tapas.net',
  'rtbhouse.com',
  'haircolorforwomen.com',
  'youm7.com',
  'talkingpointsmemo.com',
  'yieldselect.com',
  'softonic-analytics.net',
  'lenta.ru',
  'sellpoint.net',
  'gofundme.com',
  '7176.com',
  'boredpanda.com',
  'megafilmeshd.net',
  'qhupdate.com',
  'gomlab.com',
  'f-secure.com',
  'garanti.com.tr',
  'hockeyapp.net',
  'passport.net',
  'cootekservice.com',
  'carmax.com',
  'solidstatenetworks.net',
  'bm23.com',
  'ads-grooveshark.com',
  'gs-cdn.net',
  'playboy.com',
  'gplus.to',
  'postads24.com',
  'kongregate.com',
  'jangonetwork.com',
  'jpost.com',
  'movieclip.com',
  'channelvn.net',
  'revmob.com',
  'windstream.net',
  'smarterremarketer.net',
  'bloomberght.com',
  'uolhost.com.br',
  'rkdms.com',
  'hdnux.com',
  'instapaper.com',
  'bet365.com',
  'hepsibahis6.com',
  'istartsurf.com',
  'graphicriver.net',
  'litecoin.org',
  'officedepot.com',
  'fanpop.com',
  'thedailyeight.com',
  'copy.com',
  'alimama.cn',
  'bnf.fr',
  'hahatimes.com',
  'axf8.net',
  'hollywoodlife.com',
  'hhs.gov',
  'presage.io',
  'bonton.com',
  'tencent.com',
  'hayhaytv.vn',
  'allegro.pl',
  'gamefaqs.com',
  'vtc.vn',
  'ui-portal.de',
  'subscene.com',
  'web.de',
  'g2a.com',
  'cloudcell.com',
  'blog-hits.com',
  'shopping-site-directory.com',
  'casagarage.com',
  'rotoworld.com',
  'cibodistrada.it',
  'ungdungviet.com',
  'gnt.com.br',
  'toptenreviews.com',
  'ntvmsnbc.com',
  'dw.de',
  'slutroulette.com',
  'hao123.com.eg',
  'brsrvr.com',
  'fidelity.com',
  'sky.com',
  'wetransfer.com',
  'siriusxm.com',
  'gamesir.com',
  'a2g-secure.com',
  'ebay.ch',
  'rejuvenation.com',
  's-msft.com',
  'montiera.com',
  'petrotimes.vn',
  'incredimail.com',
  'adsame.com',
  'otwsftv0.com',
  'shopping-guide-centre.com',
  'mystartsearch.com',
  'tamindir.com',
  'gadgets-buy.net',
  'arcgis.com',
  'incmd05.com',
  'capitalradio.com.tr',
  'blurdev.com',
  'himediadx.com',
  'gamestop.com',
  'maxcdn.com',
  'icbdr.com',
  'nct.vn',
  'militarycity.com',
  'keepvid.com',
  'quickmeme.com',
  'geoadnxs.com',
  'tripadvisor.co.uk',
  'streamprovider.net',
  'livescore.com',
  'tutsplus.com',
  'channelnewsasia.com',
  'thedodo.com',
  'bevomedia.com',
  'ballotpedia.org',
  'sandai.net',
  'tabtimes.com',
  'acint.net',
  'amazonsilk.com',
  'getbootstrap.com',
  'razerzone.com',
  'thestar.com',
  'cdndn.net',
  'expedia.ca',
  'useclearthink.com',
  'grabnetworks.com',
  'bootstlab.com',
  'cnbce.tv',
  'baohay.vn',
  'androidcentral.com',
  'infinitummovil.net',
  'bee7.com',
  'bhphotovideo.com',
  'ad123m.com',
  'stackadapt.com',
  'drp.su',
  'egrana.com.br',
  'collegehumor.com',
  'doviz.com',
  'smi2.net',
  'livescience.com',
  'gq.com.tr',
  'ads-srv.net',
  'smartclick.net',
  'discovercard.com',
  'thomsonreuters.com',
  'ameblo.jp',
  'oyunskor.com',
  'tnetnoc.com',
  'instructables.com',
  'newser.com',
  'book-showroom.com',
  'inrim.it',
  'mfcreative.com',
  'adorika.com',
  'arcadecandy.com',
  'elitedaily.com',
  'blinklist.com',
  'baiducontent.com',
  'ians.in',
  'sophos.com',
  'yuq.me',
  'advanseads.com',
  'oley.com',
  'ndtv.com',
  '123mua.vn',
  'philips.com',
  'promobay.org',
  'techhive.com',
  'appia.com',
  'dogusdergi.com',
  'download.com',
  'e2.tv.tr',
  '000dn.com',
  'bzgint.com',
  'flipkart.net',
  'everyone.net',
  's2d6.com',
  'teamworkonline.com',
  'onlinewebstat.com',
  'crpnms.com',
  'mayoclinic.org',
  'tradera.com',
  'nasa.gov',
  'healthonnet.org',
  'wwwpromoter.com',
  'inyt.com',
  'emusic.com',
  'pages05.net',
  'media-allrecipes.com',
  'raptr.com',
  'clickprotects.com',
  'cdncontents.com',
  'ygsgroup.com',
  'onescreen.net',
  'bahistuttur.com',
  'pc120.com',
  'htemlak.com',
  'aim.com',
  'fenixm.com',
  'cnbce.com',
  'nzherald.co.nz',
  'zero-team.com',
  'xdeal.vn',
  'winamp.com',
  'emediate.eu',
  'adbooth.com',
  'apollocdn.com',
  'jetpackdigital.com',
  'google.ch',
  'paipaiimg.com',
  'thedatingnetwork.com',
  'copperegg.com',
  'kuwo.cn',
  'rss2search.com',
  'zune.net',
  'hearst.com',
  'attccc.com',
  'rutracker.org',
  'portalsepeti.com',
  'securence.com',
  'memeful.com',
  'bigmir.net',
  'iubenda.com',
  'bustle.com',
  'celebritytoob.com',
  'devour.com',
  'onlinecreditcenter6.com',
  'icq.net',
  'ist-track.com',
  'tmomail.net',
  'champssports.com',
  'w3schools.com',
  'prodigy.net',
  'gadgetspurchase.com',
  'youlamedia.com',
  'connexity.com',
  'buzzcity.net',
  'mm-health.com',
  'uproxx.com',
  'toothbrushguru.com',
  'longurl.it',
  'bongacash.com',
  'turkcell.com.tr',
  'mindjolt.com',
  '6si.com',
  'kraltv.com.tr',
  'sendevent.net',
  'hicloud.com',
  'smowtion.com',
  'subito.it',
  'ted.com',
  'overstock.com',
  'tout.com',
  'badoocdn.com',
  'seedceo.com',
  'fluxstatic.com',
  'mmafighting.com',
  'reutersmedia.net',
  'facebook.com.br',
  'macworld.com',
  's8.com.br',
  'frontbridge.com',
  'kralpop.com.tr',
  'nationalgeographic.com.tr',
  'inputdatacloud.com',
  'shoppingate.info',
  'thestaticvube.com',
  'tomsguide.com',
  'netteller.com',
  'tecmundo.com.br',
  'pickupcloud.com',
  'express.com',
  'lockhosts.com',
  'slashdot.org',
  'nyaatorrents.info',
  'secureboxes.net',
  'technet.com',
  'clamav.net',
  'avclub.com',
  'viralnewschart.com',
  'fox.com',
  'ipinfodb.com',
  'smilebox.com',
  'uverse.com',
  'renren.com',
  'imageg.net',
  'potterybarnkids.com',
  'inskinmedia.com',
  'cexchange.com',
  '11oyun.com',
  'skorer.tv',
  'bulletinsync.info',
  'realclearmarkets.com',
  'sinemaizle.org',
  'nbcnewyork.com',
  'devicescape.net',
  'factiva.com',
  'shoppingonlinedirectory.com',
  'kralpoptv.com.tr',
  'orcali.com',
  'expedia.co.uk',
  'smarterlifestyles.com',
  'onscroll.com',
  'deximedia.com',
  'sprintpcs.com',
  'nrgbinary.com',
  'usafootball.com',
  'boredlion.com',
  'freemake.com',
  'jango.com',
  'babycenter.com',
  'complex.com',
  'expedia.com.au',
  'htspor.com',
  'itsupport247.net',
  'tiscali.it',
  'todotorrents.com',
  'kijiji.ca',
  'escapehere.com',
  'bb.com.br',
  'census.gov',
  'wallst.com',
  'mortgages-guide.net',
  'clickjogos.com.br',
  'ebay.co.th',
  'goweloveit.info',
  'admagnet.net',
  'podtrac.com',
  'citrixonline.com',
  'video-one.com',
  'p0.com',
  'polarisoffice.com',
  'castradio.net',
  'parkwind.com',
  'rating-widget.com',
  'rockchip.com',
  'citibank.com',
  'splitcamera.com',
  'jam.com.vn',
  'fsdn.com',
  'kayak.com',
  'mastercard.com',
  'lanistaads.com',
  'astbr.com',
  'salesforceliveagent.com',
  'adexprts.com',
  'premiereinteractive.com',
  'yourshoppingoutlet.com',
  'fuq.com',
  'digitaloptout.com',
  'popcrush.com',
  'zst.com.br',
  'mmajunkie.com',
  'google.no',
  'optonline.net',
  'wow.com',
  'css-tricks.com',
  'adnet.vn',
  'snappea.com',
  'siteimprove.com',
  'fbmta.com',
  'meteomedia.com',
  '123phim.vn',
  'latest.com',
  'megatrack.co',
  '9hoho.com',
  'who.int',
  'bzfd.it',
  'research.net',
  'comcastnets.net',
  'boots.com',
  'gocyberlink.com',
  'gotraffic.net',
  'receitas.com',
  'bmwusa.com',
  'bankone.com',
  'mindspring.com',
  'shopping-outlet.net',
  'tubegalore.com',
  'discovery.com',
  'imesh.com',
  'fandango.com',
  'ntvradyo.com.tr',
  'cafef.vn',
  'intencysrv.com',
  'cbsig.net',
  'yieldkit.com',
  'azlyrics.com',
  'ptinews.com',
  'gx101.com',
  'noaa.gov',
  'wajam.com',
  'funshion.com',
  'media1first.com',
  'usadserver.com',
  'olx.pl',
  'pdfcomplete.com',
  'hurlist.com',
  'wipmania.com',
  'intgr.net',
  'whicdn.com',
  'tyroodr.com',
  'joinexpedia.com',
  'myad.vn',
  'shuntv.net',
  'gaytubevideos.com',
  'homepage.com.tr',
  'keywordblocks.com',
  'incapdns.net',
  'google.com.au',
  'ntvsporsmart.com',
  'reelfeed.tv',
  'best-deals-products.com',
  'google.pt',
  'fixyourbloodsugar.com',
  'stylene.net',
  'mozillalabs.com',
  'snapdeal.com',
  'staradvertiser.com',
  'massrelevance.com',
  'playsushi.com',
  'tickld.com',
  'babylon.com',
  'eagnews.org',
  'he.net',
  'lswcdn.net',
  'complexmedianetwork.com',
  'drugs.com',
  'thenation.com',
  'wsjradio.com',
  'webmotors.com.br',
  'expedia.de',
  'websta.me',
  'bomnegocio.com',
  'ya.ru',
  'usyncapp.com',
  'shaw.ca',
  'rcn.com',
  'google.ro',
  'nflcommunications.com',
  'wowway.com',
  'qadabra.com',
  'google.bg',
  'blazing.de',
  'elmundo.es',
  'eba.gov.tr',
  'tfile.me',
  'gameoapp.com',
  'lifestyleasia.com',
  'haivl.com',
  'amplifinder.biz',
  'scarabresearch.com',
  'mydlink.com',
  'morningstar.com',
  'udn.com',
  'dragonbyte-tech.com',
  'livestream.com',
  'yandex.ua',
  'clickfast.co',
  'toledoblade.com',
  'letras.mus.br',
  'wasabii.com.tw',
  'epicgameads.com',
  'hsoub.com',
  'gq.com',
  'joomla.org',
  'dpstack.com',
  'repstatic.it',
  'google.co.jp',
  'offers4u.org',
  'vw.com',
  'srvstatsdata.com',
  'drpsrvr.com',
  'publicidees.com',
  'onavo.com',
  'google.cl',
  'iflscience.com',
  '9to5mac.com',
  'reklm.com',
  'pastebay.net',
  'liftdna.com',
  'google.co.nz',
  'break.com',
  'sprint.com',
  'nikkei.com',
  'viewablemedia.net',
  'sonital.com',
  'ltvcms.com',
  'baltimoreravens.com',
  'inscname.net',
  'abullseyeview.com',
  'doodlemobile.com',
  '3600.com',
  'turnto.com',
  'grindr.com',
  '247-inc.net',
  'ezakus.net',
  'google.at',
  'ntvyayinlari.com',
  'moikrug.ru',
  'sina.cn',
  'readserver.net',
  'ulogix.ru',
  'searchengineland.com',
  'sogoucdn.com',
  'robbreport.com.tr',
  'servicos.gov.br',
  'baynote.net',
  'katespade.com',
  'geniusweekly.com',
  'walmart.com.br',
  'wsjwine.com',
  '360.com',
  'snapdo.com',
  'lienminhhuyenthoai.vn',
  'google.cz',
  'quizzyn.com',
  'inq.com',
  'americanas.com.br',
  'cpnscdn.com',
  'ra47r.com',
  'google.fi',
  'swebdpjs.info',
  'gmx.net',
  'giants.com',
  'washingtonexaminer.com',
  'correios.com.br',
  'mmo-champion.com',
  'rantmovies.com',
  'splkmobile.com',
  'folha.com.br',
  'citrixonlinecdn.com',
  'cam4s.com',
  'drweb.com',
  'ecorebates.com',
  'ostkcdn.com',
  'usa.net',
  'certum.pl',
  'badlefthook.com',
  'wahwahnetworks.com',
  'game-advertising-online.com',
  'anonymox.net',
  'venturebeat.com',
  'tuttur.com',
  'cpaptimes.com',
  'fanduel.com',
  '51y5.net',
  'top100.ru',
  'worldstarhiphop.com',
  'google.sk',
  'trafficforce.com',
  'google.com.sg',
  'admngronline.com',
  'installfarm.com',
  'espncricinfo.com',
  'netshoes.net',
  'trueconf.net',
  'fluentmobile.com',
  'doubleclick.com',
  'maxpointinteractive.com',
  'mchsi.com',
  'hardsextube.com',
  'hthayat.com',
  'dailykos.com',
  'd3js.org',
  'google.co.il',
  'five.tv',
  'intelliad.de',
  'realclearsports.com',
  'songza.com',
  'desmoinesregister.com',
  'omeljs.info',
  'indiegogo.com',
  'hon.ch',
  'newrepublic.com',
  'leadid.com',
  'google.co.th',
  'telmex.com',
  'rvpadvertisingnetwork.com',
  'adreadytractions.com',
  'google.co.hu',
  'carrierzone.com',
  'psafe.com',
  'orange.fr',
  'sharepointonline.com',
  'google.co.kr',
  'townhall.com',
  'sub2tech.com',
  'bemobile.ua',
  'securetve.com',
  'host.sk',
  'bgr.com',
  'xing.com',
  'weddingpaperdivas.com',
  'duba.com',
  'networkmagic.com',
  'hautelook.com',
  'santander.com.br',
  'btbuckets.com',
  'btttag.com',
  't26.net',
  'pornstargalore.com',
  'coupons.com',
  'usatodayclassifieds.com',
  'meetme.com',
  'bahis-oranlari.com',
  'meteorsolutions.com',
  'demandstudios.com',
  'expedia.co.jp',
  'roomkey.com',
  'vuitruyentranh.vn',
  'madsone.com',
  'adp.com',
  'onlinewebstats.com',
  'spn.com',
  'wynk.in',
  'yandex.com',
  'xfinity.com',
  'hospitalitynet.org',
  'legacy.net',
  'linkbolic.com',
  'donanimhaber.com',
  'xtendmedia.com',
  'newgenonlinesrv.com',
  'srvntrk.com',
  'klm.com',
  'patreon.com',
  'flowstats.net',
  'ehowcommcdn.com',
  'fishbowl.com',
  'mobileiron.com',
  'citi.com',
  'ekomi.de',
  'cooking.com',
  'samplicio.us',
  'quizlet.com',
  'tovarro.com',
  'ultraadserver.com',
  'techradar.com',
  'webpagescripts.net',
  'cpmba.se',
  'yoz.io',
  'meteo.it',
  'webwebget.com',
  'victoriassecret.com',
  'here.com',
  'ebay.vn',
  'craigslist.hk',
  'openxmarket.asia',
  'telus.net',
  'streamcloud.eu',
  'toplist.eu',
  'ilsole24ore.it',
  'ebaumsworld.com',
  'op-cdn.net',
  'newshuntads.com',
  'ticketm.net',
  'google.gr',
  'urbantabloid.com',
  'slideshare.com',
  'ctvnews.ca',
  'bahisfoni.com',
  'google.com.sa',
  'expedia.fr',
  'ayads.co',
  'bayimg.com',
  'irishtimes.com',
  '247sports.com',
  'bayfiles.net',
  'visistat.com',
  'rferl.org',
  'smoothfusion.com',
  'solidoak.com',
  'yhd.com',
  'inboundmx.com',
  'expedia.it',
  'lemonde.fr',
  'ourtime.com',
  'quora.com',
  'mlt01.com',
  'clarin.com',
  'textnow.me',
  'buyvip.com',
  'worldtimeserver.com',
  'newsok.com',
  'motherless.com',
  'octrocdn.com',
  'simply.com',
  'birdstep.com',
  'tudogostoso.com.br',
  'getportal.net',
  'nike.com',
  'localpages.com',
  'schnutzelhuber.com',
  'amkspor.com',
  'bronto.com',
  'pjmedia.com',
  'ezinearticles.com',
  'certsentry.com',
  'tudou.com',
  'lavasoft.com',
  'bahissiteleri.mobi',
  'metrolyrics.com',
  'ifcdn.com',
  'turkiye.gov.tr',
  'epicplay.com',
  'palcomp3.com',
  'hubtraffic.com',
  'vzwfemto.com',
  'moz.com',
  'pornorama.com',
  'amobee.com',
  'sdlcdn.com',
  'ndmdhs.com',
  'mediabong.net',
  'picasasoftware.com',
  'canli-casinositeleri.com',
  'bills.com',
  'recreativ.ru',
  'ideel.com',
  'rockabox.co',
  'rediffmail.com',
  'academia.edu',
  'appsfire.net',
  'bild.com',
  'bnqt.com',
  'phunware.com',
  'reactiongifs.com',
  'suite6ixty6ix.com',
  'meb.gov.tr',
  'psmtp.com',
  'mdpcdn.com',
  'golfchannel.com',
  'goodhousekeeping.com',
  'apply2jobs.com',
  'squidoo.com',
  'usatodayhss.com',
  'clearchannel.com',
  'fbshare.me',
  'advertising-support.com',
  'hp-ww.com',
  'panoramio.com',
  'vube.com',
  'rontar.com',
  'opposingviews.com',
  'nakedtube.com',
  'cartalk.com',
  'atlassbx.com',
  'videotron.ca',
  'mmptrack.com',
  'commercialintegrator.com',
  'stormiq.com',
  'imagesbn.com',
  'arabam.com',
  'wdc.com',
  'profootballfocus.com',
  'smtproutes.org',
  'manta.com',
  'digitaltarget.ru',
  'kansascity.com',
  'nq.com',
  'cameo.tv',
  'poponclick.com',
  'canlirulet-siteleri.com',
  '2sao.vn',
  'ifeng.com',
  'chefscatalog.com',
  'redcross.org',
  'pandora.tv',
  'typepad.com',
  'yemektarifleri.com',
  'bc.vc',
  'eltrafiko.com',
  'gwu.edu',
  'scmp.com',
  'yammer.com',
  'anthropologie.com',
  'gogii.com',
  'uscellular.com',
  'alcatelonetouch.com',
  'kijiji.it',
  'grovupdt.com',
  'maxpreps.com',
  'bttrack.com',
  'socialpointgames.com',
  'studiopress.com',
  'wxbug.com',
  'wpadsvr.com',
  'faithtap.com',
  'echo.msk.ru',
  'casinositeleri.biz',
  'fdnames.com',
  'wikivoyage.org',
  'yazarkafe.com',
  'giga.xxx',
  'detik.com',
  'fc2.com',
  'netcrawl.info',
  'all-free-download.com',
  'gannett.com',
  'glammedia.com',
  'lincoln.com',
  'purewow.com',
  'el-ladies.com',
  'wsjplus.com',
  'speedial.com',
  'ebayadvertising.com',
  '8tracks.com',
  'td.com',
  'instagramfollowbutton.com',
  'securejump.net',
  'rankingames.com',
  'workintelligent.ly',
  'howstuffworks.com',
  'thefashionfanatic.com',
  'staticworld.net',
  '59saniye.com',
  'vzw.net',
  'cisco.com',
  'filmdiziseyret.com',
  'pelmorex.com',
  'congan.com.vn',
  't24.com.tr',
  'findnsave.com',
  'mamaslatinas.com',
  'hstpnetwork.com',
  'newsprints.co.uk',
  'realclearworld.com',
  'atpanel.com',
  'ctx.ly',
  'textme-app.com',
  'conversantmedia.com',
  'komikoyunlar.net',
  'wimp.com',
  'localyokelmedia.com',
  'dateandtimesync.com',
  'collider.com',
  'clevergirlscollective.com',
  'golferstrust.com',
  'abebooks.co.uk',
  'maskonline.vn',
  'eff.org',
  'visionobjects.com',
  'xapads.com',
  'noktamedya.com',
  'mediafiredev.com',
  'digitalinsight.com',
  'mysoluto.com',
  'vatgia.vn',
  'jabong.com',
  'ma.tt',
  'emediate.se',
  'shutterfly.com',
  'shoppop.net',
  'qz.com',
  'appscloudupdater.com',
  'adnexio.com',
  'ykimg.com',
  'terra.com.mx',
  'popmyads.com',
  'xat.com',
  'mixi.jp',
  'wefi.com',
  'dtcn.com',
  'cinesport.com',
  'xatech.com',
  'biography.com',
  'k9webprotection.com',
  'vmmpxl.com',
  'uuidcshmg.com',
  'bittorrent.am',
  'arenajunkies.com',
  'itar-tass.com',
  'withoutabox.com',
  'agoop.net',
  'adorika.net',
  'protectfootballonfreetv.com',
  'msparktrk.com',
  'ehowenespanol.com',
  'ad2games.com',
  'bloxcms.com',
  'staplescenter.com',
  'arcadefrontier.com',
  'btg360.com.br',
  'feedblitz.com',
  'healthforself.com',
  'yonhapnews.co.kr',
  'tnaflix.com',
  'tumra.com',
  'veedi.com',
  'taps.io',
  'expedia.co.in',
  'youwincdn.com',
  'raiders.com',
  'bet365affiliates.com',
  'gci.net',
  'sokrati.com',
  'nordstrom.com',
  'efinancialnews.com',
  'freenode.net',
  'projectwonderful.com',
  'instinctiveads.com',
  'thinkprogress.org',
  'clickbooth.com',
  'usaa.com',
  'ddmcdn.com',
  'macrumors.com',
  'rmncdn.com',
  'sublimevideo.net',
  'predictad.com',
  'megaoferta.net',
  'kowalskypage.com',
  'totallyher.com',
  'appflood.com',
  'startribune.com',
  'yellowpages.ca',
  'telesec.de',
  'rstyle.me',
  'scambioetico.org',
  'komikler.com',
  'theonion.com',
  'tradelab.fr',
  'gdmdigital.com',
  'loudtalks.com',
  'omiga-plus.com',
  'doisongphapluat.com',
  'mercadopago.com.br',
  'plo.vn',
  'danarimedia.com',
  'ventunotech.com',
  'adhispanic.com',
  'tv.com',
  'infusionsoft.com',
  'besthitsnow.com',
  'pub-fit.net',
  'fusepowered.com',
  'suprbay.org',
  '32d1d3b9c.se',
  'hellomagazine.com',
  'rdcpix.com',
  'trt.net.tr',
  'lolking.net',
  'edmunds.com',
  'moodle.org',
  'mercadoshops.com.br',
  'exitmonetization.com',
  'webme.com',
  'c-col.com',
  'livesportmedia.eu',
  'wooga.com',
  'hotwire.com',
  'bit-search.com',
  'localnet.com',
  '123rf.com',
  'highcharts.com',
  'dashlane.com',
  'chrysler.com',
  'posst.co',
  'meetrics.net',
  'youjizz.com',
  'warnerbros.com',
  'bugsnag.com',
  'stack.com',
  'redirectingat.com',
  'rightinthebox.com',
  'copacet.com',
  'timeapi.org',
  'oyunkolu.com',
  'getvideostream.com',
  'cloudmark.com',
  'lobstertube.com',
  'maxiget.com',
  'servetags.com',
  'wp.pl',
  'starwebnet.com',
  'epa.gov',
  'maturetube.com',
  'cosmopolitan.com',
  'mcproton.com',
  'apuslauncher.com',
  'eccmp.com',
  'xdealvn.com',
  'tvline.com',
  'lostlettermen.com',
  'free-porn-vidz.com',
  'rasmussenreports.com',
  'olivebrandresponse.com',
  'lolboom.net',
  'adfront.org',
  'ajiang.net',
  'inca.gov.br',
  'popaholic.me',
  'broadage.com',
  'biphysics.com',
  'devicevm.com',
  'highbeam.com',
  'giantbomb.com',
  'lifegooroo.com',
  'smartasset.com',
  '9gaginc.com',
  'history.com',
  'crackedcdn.com',
  'lithium.com',
  'stagram.com',
  'venere.com',
  'redvertisment.com',
  'cdn77.net',
  'sedoparking.com',
  'clickcountr.com',
  'mediakit.com.br',
  'widdit.com',
  'sd-assets.com',
  'pluso.ru',
  'azcentral.com',
  'webtretho.com',
  'alice.it',
  'webhostoid.com',
  'orkut.com',
  'helloreverb.com',
  'healthline.com',
  'belugaboost.com',
  'suddenlink.net',
  '2sawbucks.com',
  'system-monitor.com',
  'shelterpetproject.org',
  '3gl.net',
  'sgn.com',
  'google.co.za',
  'linkfeed.org',
  'snacktools.net',
  'geocities.com',
  'pcgamer.com',
  'diadiem.com',
  'agoramedia.com',
  'right-coupon.com',
  'upstats.ru',
  'hispeedtube.com',
  'thinkfurtheralger.com',
  'screencast.com',
  'bna.com',
  'nfl.biz',
  'ashleyrnadison.com',
  'tienphong.vn',
  'bigpond.com',
  'mazdausa.com',
  'link.vn',
  'dequeamaze.com',
  'phys.org',
  'openx.com',
  'adzcore.com',
  'desert-operations.com.tr',
  'nflplayercare.com',
  'teamspeak.com',
  'thehindu.com',
  'viewpoint.com',
  'priceline.com',
  '9game.com',
  'finebooksmagazine.com',
  'ttnetmuzik.com.tr',
  'audible.co.uk',
  'mercadopago.com',
  'site-analytics.info',
  'hao123.com.br',
  'cwfservice.net',
  'iconfinder.com',
  'fjcdn.com',
  'allyes.com',
  'xmlshop.biz',
  'flipora.com',
  'afiliados.com.br',
  'adnetwork.net',
  'mefeedia.com',
  'playblasteroids.com',
  'cmbilisim.com',
  'researchgate.net',
  'mshcdn.com',
  'tubemate.net',
  'mobilepassback.com',
  'moneycontrol.com',
  'networkedblogs.com',
  'adspdbl.com',
  'shopclues.com',
  'buzznet.com',
  'canliskor.com.tr',
  'adservlite.com',
  'scientificamerican.com',
  'sbbanner.com',
  'drupal.org',
  'babble.com',
  'dailydot.com',
  '120sports.com',
  'expedia.es',
  'phimmoi.net',
  'fegn.com',
  'bitfalcon.tv',
  'cogmatch.net',
  'marthastewart.com',
  'peoplepets.com',
  'fbnewsreport.com',
  'futbolmacozetleri.com',
  'bluewin.ch',
  'wsjdigital.com',
  'vidto.me',
  'hindustantimes.com',
  'cloud-trax.com',
  'retailmenot.com',
  'ibsrv.net',
  'coed.com',
  'sscdn.co',
  'jobvite.com',
  'imore.com',
  'vagalume.com',
  'fotokritik.com',
  'usopen.org',
  'giaoduc.net.vn',
  'goviral-content.com',
  'mkt932.com',
  'details.com',
  'realvu.com',
  'wwv4ez0n.com',
  'rapor.mobi',
  'mymotocast.com',
  'logly.co.jp',
  'lolpro.com',
  'jetlore.com',
  'omnitwig.com',
  'nakamitech.de',
  'bizible.com',
  '51y5.com',
  'turbobytes.net',
  'myfreecams.com',
  'ensonhaber.com',
  'thefiscaltimes.com',
  'net-mine.com',
  'torrent-download.to',
  'adsupply.com',
  'superantispyware.com',
  'cinergroup.com.tr',
  'systemcdn.net',
  'melonstube.com',
  'libsyn.com',
  'tzoo-img.com',
  'nixcdn.com',
  'pch.com',
  'globalenerji.com.tr',
  'taptica.com',
  'justlook.tv',
  'tracksitetraffic1.com',
  'maponics.com',
  'truste.org',
  'integral-marketing.com',
  'comodo.net',
  'messagingengine.com',
  'sphinn.com',
  'mackeeper.com',
  'cpmrocket.com',
  'blush.com',
  'web.tv',
  'id.net',
  'guildwars2guru.com',
  'gomtv.com',
  'softonic.com.br',
  'starzone.info',
  'listenlive.co',
  'v4cdn.net',
  'dogusyayingrubu.com.tr',
  'yontoo.com',
  'dealchicken.com',
  'filmon.com',
  'news.com.au',
  'tadst.com',
  'bgov.com',
  'ani-view.com',
  'wnsqzonebk.com',
  'mediashakers.tv',
  'cazamba.com',
  'dpreview.co.uk',
  'xda-developers.com',
  'space.com',
  'hupso.com',
  'djreprints.com',
  'gelocal.it',
  'khanacademy.org',
  'stocktwits.com',
  'minhngoc.net.vn',
  'hud.gov',
  'hairenvy.com',
  'featurelink.com',
  'arabayarisi.com.tr',
  'williamhill.com',
  'sportsnetwork.com',
  'yandex.kz',
  'sciencedaily.com',
  'dolimg.com',
  'komiksurat.com',
  'technologytell.com',
  'dribbble.com',
  'iconarchive.com',
  'cbscorporation.com',
  'tinmoi.vn',
  'adzhub.com',
  'highwebmedia.com',
  'fssta.com',
  'sabah.de',
  'impawards.com',
  'passport.com',
  'adohana.com',
  'nflonlocation.com',
  'globovideos.com',
  'emailretargeting.com',
  'webservis.gen.tr',
  'webroot.com',
  'qwikbookprint.com',
  'magicfinds.com',
  'maximustube.com',
  'googleadsserving.cn',
  'extensionanalytics.net',
  'reddollars.com',
  'browsersecurity.net',
  'adelement.com',
  'bacdau.vn',
  'soundandglory.com',
  'akhbarak.net',
  'clorox.com',
  'csnstores.com',
  'springboardvideo.com',
  'supremetube.com',
  'wowdb.com',
  'campanja.com',
  'bettycrocker.com',
  'usatodaysportsevents.com',
  'anime-news.info',
  'wbmd.com',
  'verizoninsider.com',
  'spongecellmedia.com',
  'bbcworldwide.com',
  'kmart.com',
  'olx.com.br',
  'thisamericanlife.org',
  'banzaiadv.it',
  'redbox.com',
  'online.sh.cn',
  'redstate.com',
  'vogue.com.tr',
  'compey.net',
  'suddenlinkmail.com',
  'hastrk3.com',
  'orbitz.com',
  'villas.com',
  'imdbweb.info',
  'aeerdy.com',
  'expedia.co.nz',
  'groupon.de',
  'oprah.com',
  'grouponworks.com',
  'createspace.co.uk',
  'faceporn.com',
  'legalnotice.org',
  'kaptcha.com',
  'concentric.com',
  'csnimages.com',
  'reevoo.com',
  'lacivertdergi.com',
  'ookla.com',
  'beekee-akkie.com',
  'refdesk.com',
  'downloadmeteoroids.com',
  'superbahisaffiliates.com',
  'palmcoastd.com',
  'dizi-izle.com',
  'luttgenheinrich.bz',
  'sina.com',
  'formstack.com',
  'salemwebnetwork.com',
  'livehelpnow.net',
  'chitika.com',
  'qualys.com',
  'hostgator.com',
  'sputnikhome.com',
  'firmarehberiekle.gen.tr',
  'indiebound.org',
  'smartlifeweekly.com',
  'xkcd.com',
  'cpmfun.com',
  'kompas.com',
  'newsbusters.org',
  'buysafe.com',
  'slickdealscdn.com',
  'ecustomeropinions.com',
  'opaltelecom.net',
  'cboeoptionshub.com',
  'necn.com',
  'volusion.com',
  'kuaibo.com',
  'gionee.com',
  'zypush.com',
  'cityads.ru',
  'docstoccdn.com',
  'habrahabr.ru',
  'markmost.com',
  'soso.com',
  'diablofans.com',
  'google.com.pr',
  'trustsign.com.br',
  'curseforge.com',
  'tweetmeme.com',
  'magnetmail1.net',
  'target.ca',
  'samsungalways.com',
  'littlethings.com',
  'bloombergsports.com',
  'avg.cz',
  'gazeta.pl',
  'webtrackerplus.com',
  'member-hsbc-group.com',
  'craigslist.ca',
  'nlinevideos.com',
  'ansa.it',
  'utsandiego.com',
  '100im.info',
  'torrent-downloads.to',
  'emule.org.cn',
  'inttrax.com',
  'bloombergbriefs.com',
  'faceporn.no',
  'sec.gov',
  'mirmay.com',
  'zamunda.net',
  'batanga.com',
  'odatv.com',
  'watchseries.lt',
  'gotomeeting.com',
  'fusion.net',
  'in.com',
  'ad-m.asia',
  'playbuzz.com',
  'wufoo.com',
  'irctc.co.in',
  'wpthemes.co.nz',
  'playfizz.com',
  'lossip.com',
  'torrentreactor.net',
  'imptrkr.com',
  'bshare.cn',
  'swagbucks.com',
  'socialvi.be',
  'opselect.com',
  'fotolia.com',
  're-markable.net',
  'is.gd',
  'yenimedya.com.tr',
  'cookingchanneltv.com',
  'nflplayers.com',
  'carambo.la',
  'i-em.eu',
  'letv.com',
  'infoaxe.com',
  'nbclearn.com',
  'mint.com',
  'porn.com',
  'antiwar.com',
  'fbiz.com.br',
  'okccdn.com',
  'oferta.vc',
  'acuityads.com',
  'nextperformance.com',
  'torrentfreak.com',
  'brightroll.com',
  'krishnna.com',
  'webhosteo.com',
  'saoonline.vn',
  'computerandvideogames.com',
  'adshostiso.com',
  'postdirect.com',
  'audtd.com',
  'recruitics.com',
  'yelp.co.uk',
  'ruten.com.tw',
  'meridiana.it',
  'traidnt.net',
  'flixcar.com',
  'ilfattoquotidiano.it',
  'techepoch.com',
  'foxitservice.com',
  'lg.com',
  'bestofmedia.com',
  'drivergenius.com',
  'dfdd4c0913aa193a3dd3d20b7645e2a46a3e4.com',
  'silvercdn.com',
  'scopely.io',
  'o2.co.uk',
  'cagesideseats.com',
  'pegi.info',
  'intagme.com',
  'livedoor.com',
  'google.ae',
  'researchadvanced.com',
  'india.com',
  'khon2.com',
  'move.com',
  'who.is',
  'peacockproductions.tv',
  'techz.vn',
  'mydotcomrade.com',
  'famefocus.com',
  'level3.net',
  'agoda.net',
  'lefigaro.fr',
  'astromendabarand.com',
  'digitalrivercontent.net',
  'thedianerehmshow.org',
  'kalooga.com',
  'androidpolice.com',
  'cvent.com',
  'jossandmain.com',
  'mediabistro.com',
  'bdupdater.com',
  'afp.com',
  'bettermedicine.com',
  'olivesoftware.com',
  'rakuten.co.jp',
  'vocativ.com',
  'tnt-ea.com',
  'cstv.com',
  'hscta.net',
  'theregister.co.uk',
  'goo.ne.jp',
  'deviantart.com',
  'elle.com',
  'contactlab.it',
  'appdynamics.com',
  'eurogamer.net',
  'newtention.net',
  'free-analytics.com',
  'y8.com',
  'bac.com',
  'dangerousminds.net',
  'softonic.it',
  'umass.edu',
  'demandmedia.com',
  'joygame.com',
  'tapulous.com',
  'bookmyshow.com',
  '71i.de',
  'avito.ru',
  'mxcdn.net',
  'fpsgeneral.com',
  'analytics-egain.com',
  'fiverr.com',
  'incitemedialabs.com',
  'breakingnews.com',
  'pubt.net',
  'independent.ie',
  'kbb.com',
  'wptavern.com',
  '9k.com.vn',
  'vcdn.vn',
  'sub.ly',
  'rantchic.com',
  'aionarmory.com',
  'parenttoolkit.com',
  'tripstodiscover.com',
  'minecraftwiki.net',
  'urbanspoon.com',
  'ouedkniss.com',
  'haberturk.tv',
  'moreover.com',
  'b117f8da23446a91387efea0e428392a.pl',
  'woothemes.com',
  'komikdunya.com',
  'gw2db.com',
  'onthemedia.org',
  'umsns.com',
  'outsports.com',
  'yext.com',
  'aruba.it',
  'wetter.com',
  'vividseats.com',
  'helperbar.com',
  'valuecpm.net',
  'valvesoftware.com',
  'oleane.net',
  'fullhdfilmizle.org',
  'oned.io',
  'mercadolibre.com.ar',
  'hsforms.net',
  'wonderhit.com',
  'virustotal.com',
  'windowscentral.com',
  'gmads.net',
  'disneystore.com',
  'takvim.com.tr',
  'addmefast.com',
  'chotot.vn',
  'nifty.com',
  'rbc.ru',
  'carbonite.com',
  'directv.com',
  'octoshape.eu',
  'command.com',
  'grouponaffiliate.com',
  'hpeprint.com',
  'bodybuilding.com',
  'pxxtz.com',
  'amd.com',
  'rollcall.com',
  'mgm.gov.tr',
  'imonomy.com',
  'retargeter.com',
  'socialgamenet.com',
  'mdctrail.com',
  'daum.net',
  'maxwebsearch.com',
  'itao.com',
  'sittercity.com',
  'nflevolution.com',
  'fatakat.com',
  'webmasterplan.com',
  'onet.pl',
  'twoo.com',
  'v1cdn.net',
  'comodoca3.com',
  'ultradns.org',
  'registeredsite.com',
  'kontextua.com',
  'submarino.com.br',
  'infobae.com',
  'souq.com',
  'mcent.com',
  'traffic-orgy.com',
  'rzone.de',
  'zeroredirect1.com',
  'contentclick.co.uk',
  'loginradius.com',
  'kamcord.com',
  'zeti.com',
  '3366app.com',
  'spinmedia.com',
  'livenation.com',
  'meme.vn',
  'heise.de',
  'ultradns.net',
  'amazonbrowserapp.com',
  'teleborsa.it',
  'azurewebsites.net',
  'baidu.com.br',
  'download-servers.com',
  'ultradns.biz',
  'yarpp.org',
  'nieonline.com',
  'googlepages.com',
  'chcmkt.com',
  'costco.com',
  'tubecup.com',
  'darthhater.com',
  'pptv.com',
  'landsend.com',
  'softonic.fr',
  'btinternet.com',
  'jcpenney.com',
  'sephora.com',
  'mndigital.com',
  'dodge.com',
  'walkscore.com',
  'mobilenations.com',
  'seznam.cz',
  'ultradns.info',
  'waterfrontmedia.com',
  'interia.pl',
  'etrade.com',
  'radiolab.org',
  'propellerpops.com',
  'yelp.ch',
  'cooladata.com',
  'scansafe.net',
  'tilt.com',
  'atlasobscura.com',
  'city-data.com',
  'spoton.it',
  'demonoid.ph',
  'mediatakeout.com',
  'simpsons-ea.com',
  'fon.com',
  'spot.im',
  'compuwareapmaas.com',
  'kboing.com.br',
  'iqzone.com',
  'eluniversal.com.mx',
  'defaulttab.com',
  'bungie.net',
  'blocket.se',
  'vitruvianleads.com',
  'polygon.com',
  'bloomberg.net',
  'bild.de',
  'unicef.org',
  'tagged.com',
  'kraloyun.com',
  'lemagram.com',
  'tagcommander.com',
  'dealply.com',
  'kitcode.net',
  'samsung.com.br',
  'ucoz.net',
  'dummies.com',
  'zoho.com',
  'syn-api.com',
  'gioneemobile.net',
  'blogtopsites.com',
  'lendingtree.com',
  'televisionfanatic.com',
  'kursus-bahasa.com',
  'brucelead.com',
  'sunrise.am',
  'illiweb.com',
  'rj.gov.br',
  'sbito.it',
  'tripit.com',
  'turunculevye.com',
  'cdn-hotels.com',
  'gbga.gi',
  'datacaciques.com',
  'jcrew.com',
  'unileverprivacypolicy.com',
  'videozview.com',
  'diigo.com',
  'leasewebcdn.com',
  'yotpo.com',
  'fungame.com.br',
  'google.com.ec',
  'tripcurator.com',
  'a433.com',
  'ptd.net',
  'geeksquad.com',
  'publicsuffix.org',
  'ck101.com',
  'ccm2.net',
  'yelp.de',
  'arcadeyum.com',
  'corel.com',
  'meetupstatic.com',
  'nguoiduatin.vn',
  'cinemablend.com',
  'terrariaonline.com',
  'wowhead.com',
  'list-manage.com',
  'rondavu.com',
  'ceryxefw.com',
  'mongoosemetrics.com',
  'evolvingseo.com',
  'thehitsusa.com',
  'gamepedia.com',
  'crunchyroll.com',
  'nbclosangeles.com',
  'vidcoin.com',
  'leboncoin.fr',
  'qwest.net',
  'my.com',
  'mysql.com',
  'naukri.com',
  'wisersaver.com',
  'firstlook.org',
  'salecycle.com',
  'couponcamp.com',
  'foreverceleb.com',
  'bblr.me',
  'newdatastatsserv.com',
  'pages02.net',
  'hyperpromote.com',
  'buyt.in',
  'zcache.com',
  'verticalscope.com',
  'softonic.de',
  'backpage.com',
  'cloudtrax.com',
  'nava.vn',
  'bentenoyunlari.org',
  'post-gazette.com',
  'adbabylon.com',
  'yelp.be',
  'preguntados.com',
  'htctouch.com',
  'investopedia.com',
  'kmdisplay.com',
  'trklnks.com',
  'politifact.com',
  'cuti.vn',
  'copyscape.com',
  'betburdaaffiliates.com',
  'nuvid.com',
  'olx.in',
  'gslbjpmchase.com',
  'talk4free.com',
  'coxmail.com',
  'appscomeon.com',
  'fisglobal.com',
  'angelfire.com',
  'hiido.com',
  'install-daddy.com',
  'free.fr',
  'dimml.io',
  'softonic.cn',
  'streameye.net',
  'sapo.pt',
  'dmoz.org',
  'yelp.fr',
  'primewire.ag',
  'sexlog.com',
  'wbur.org',
  'hm.com',
  'firebase.com',
  'helloridwan.com',
  'advertiseonabout.com',
  'easybib.com',
  'juicyceleb.com',
  'about.me',
  'midnightjs.net',
  'seattletimes.com',
  'r10.io',
  'linkbucks.com',
  'mnetads.net',
  'groceryserver.com',
  'forobeta.com',
  'digitalwindow.com',
  'xuite.net',
  'gtmetrix.com',
  'bigfootinteractive.com',
  'facenama.com',
  'c4tracking01.com',
  'picmonkey.com',
  'taleo.net',
  '4sqi.net',
  'soubarato.com.br',
  'wsjstudent.com',
  'yelp.com.hk',
  'gapinc.com',
  'clarovideo.com',
  'thewrap.com',
  'yelp.nl',
  'mit.edu',
  'magnetmail.net',
  'zimbio.com',
  'bestofmicro.com',
  'nctcorp.vn',
  'hughes.net',
  'spider.ad',
  'pornerbros.com',
  'wow-europe.com',
  'agentesevenoteatro.com.br',
  'infogame.vn',
  'exilepro.com',
  'jmp9.com',
  'nbcphiladelphia.com',
  'nivi.vn',
  'twittercounter.com',
  'medyanetplayer.com',
  'pubmed.gov',
  'demonware.net',
  'cudasvc.com',
  'deejay.it',
  'pr-cy.ru',
  'distilnetworks.com',
  'es.pn',
  'realharborredirect.com',
  'mail.mil',
  'tifbs.net',
  'distractify.com',
  'zulilyinc.com',
  'nps.gov',
  'online-adnetwork.com',
  'tabnak.ir',
  'anv.bz',
  'magazinkolik.com',
  'filmizle.com.tr',
  'keek.com',
  'upcmail.net',
  'arcamax.com',
  'puckermob.com',
  'craigslist.co.za',
  'firedrive.com',
  'lightinthebox.com',
  'makemytrip.com',
  'diretta.it',
  'irs01.net',
  'tiin.vn',
  'moovweb.net',
  'expedia.at',
  'flixfacts.com',
  'nextissue.com',
  'classistatic.com',
  'fifa.com',
  'gyazo.com',
  'google.com.do',
  'homedecorators.com',
  'nbcwashington.com',
  'kmylvwo5.com',
  'shazamid.com',
  'skysports.com',
  'gpm-digital.com',
  'hdfcbank.com',
  'welt.de',
  'carfax.com',
  'fhserve.com',
  'mymovies.it',
  'assineabril.com.br',
  'redfin.com',
  'm-w.com',
  'expedia.com.my',
  'free-tv-video-online.me',
  'netease.com',
  'web-18.com',
  'scoop.it',
  'zdassets.com',
  'cnetfrance.fr',
  'surveywriter.net',
  'yelp.fi',
  'p5w.net',
  'voegol.com.br',
  'eircom.net',
  'puppytoob.com',
  'yelp.ca',
  'bolumsonucanavari.com',
  'movie4k.to',
  'vzwshop.com',
  'newsday.com',
  'superpages.com',
  'bestblackhatforum.com',
  'getsidekick.com',
  'hespress.com',
  'clocklink.com',
  'farsnews.com',
  'ahaber.com.tr',
  'terra.cl',
  'miniclippt.com',
  'onlinesbi.com',
  'expedia.be',
  'nate.com',
  'lululemon.com',
  'epson.com',
  'sc2mapster.com',
  'tuenti.com',
  'wowace.com',
  'airtel.in',
  'mercadolibre.com.mx',
  'yelp.es',
  'websitealive.com',
  'blogspot.co.uk',
  'abc.es',
  'persianblog.ir',
  'glanceguide.com',
  'google.hr',
  'altervista.org',
  'cnetnews.com.cn',
  'marriland.com',
  'elance.com',
  'samsungallstore.com',
  'teacherspayteachers.com',
  'cpatrendreklam.com',
  'pravda.com.ua',
  'searchforce.net',
  'cam4.com',
  'mobile.de',
  'canada.com',
  'dotki.tv',
  'smarterpowerunite.com',
  'adobe.io',
  'metalyzer.com',
  'walkme.com',
  'justdial.com',
  'cnetcontent.com',
  'tistory.com',
  'ifengimg.com',
  'purenetworks.com',
  'vivastreet.it',
  'r-ad.ne.jp',
  'coolots.com',
  'theroot.com',
  'wibiya.com',
  'google.kz',
  'semrush.com',
  'tianya.cn',
  'joystiq.com',
  'quicknessrun.com',
  'knight-sac-media.com',
  'netindex.com',
  'nickmom.com',
  '58.com',
  'kakaku.com',
  'watchmygf.net',
  'vennq.com',
  'baixakijogos.com.br',
  'dubizzle.com',
  'firstpost.com',
  'brilliantearth.com',
  'csnbayarea.com',
  'dellbackupandrecoverycloudstorage.com',
  'cloneweb.net',
  'zqlx.com',
  'douban.com',
  'aparat.com',
  'thesportster.com',
  'odesk.com',
  'idnes.cz',
  'tagstat.com',
  'myntra.com',
  'thesun.co.uk',
  'evitecdn.com',
  'phonearena.com',
  'aizhan.com',
  'a2dfp.net',
  'hit.ua',
  'anddownthestretchtheycome.com',
  'yelp.com.au',
  'adapf.com',
  'tabelog.com',
  'ijreview.com',
  '37signals.com',
  'dealer.com',
  'dailynews.com',
  'abine.com',
  'tim.it',
  'flix360.com',
  'pingtest.net',
  'rotowire.com',
  'storm8.com',
  'uribl.com',
  'motthegioi.vn',
  'lanacion.com.ar',
  'staplesadvantage.com',
  'nouvelobs.com',
  'vesti.ru',
  'wwe.com',
  'horoscopedays.com',
  'rovicorp.com',
  'ltn.com.tw',
  'premiumtv.co.uk',
  'icicibank.com',
  '2345.com',
  'intoday.in',
  'sex.com',
  'cdntraffic.com',
  'mihanblog.com',
  'rightmove.co.uk',
  'komiksozler.net',
  'aniways.com',
  'bravotube.net',
  'impressiondesk.com',
  'abt.cm',
  'neobux.com',
  'georiot.co',
  'majesticseo.com',
  'memurlar.net',
  'installerapplicationusa.com',
  'bankmellat.ir',
  'sophosupd.net',
  'magentocommerce.com',
  'vtexrc.com.br',
  'sueddeutsche.de',
  'cvs.com',
  'expedia.com.br',
  'huffingtonpost.it',
  'ione.net',
  'qianlong.com',
  'tiny.cc',
  'appointron.com',
  'tapit.com',
  'almasryalyoum.com',
  'leo.org',
  'pchome.com.tw',
  'globoesporte.com',
  'app111.com',
  'powermarketing.com',
  'yelp.com.br',
  'playtopus.com',
  '51fanli.com',
  'autohome.com.cn',
  'lequipe.fr',
  'jeuxvideo.com',
  'aplus.com',
  'firsttoknow.com',
  'military.com',
  'yelp.it',
  'feelcars.com',
  'cyberlink.com',
  'gelirortaklari.com',
  'novinky.cz',
  'premierleague.com',
  'ingresso.com',
  'jagran.com',
  'x17online.com',
  'xadcentral.com',
  'cumulus-cloud.com',
  'nownews.com',
  'dpliveupdate.com',
  'ccb.com',
  'dmm.com',
  'startpage.com',
  'gameinformer.com',
  'huyenbi.net',
  'herewetest.com',
  'podiumcafe.com',
  'cnmo.com',
  'gome.com.cn',
  'airsensewireless.com',
  'softonic.jp',
  'g8teway.com',
  'wpmudev.org',
  '2ch.net',
  'gumtree.com',
  'ku6.com',
  'paipai.com',
  'rednet.cn',
  'purebreak.com.br',
  '800wen.com',
  'condenet.com',
  'gsmarena.com',
  'pacsun.com',
  'businessinsider.com.au',
  'hotmail.com.br',
  'pbskids.org',
  'gismeteo.ru',
  'nairaland.com',
  'hqq.tv',
  'yelp.co.nz',
  'google.cn',
  'searchengines.ru',
  'yelp.com.ar',
  'freelancer.com',
  'chaseswing.eu',
  'prothom-alo.com',
  'livingplay.com',
  'mkt922.com',
  'immobilienscout24.de',
  'smartmoney.com',
  'e-printphoto.co.uk',
  'eventoptimize.com',
  'shaadi.com',
  'templatemonster.com',
  'internetbrands.com',
  'yelp.at',
  'google.lk',
  'pixiv.net',
  'inventorycreation.com',
  'google.rs',
  'pingdom.com',
  'prestashop.com',
  'oneindia.in',
  'payoneer.com',
  'r10.net',
  'reverso.net',
  'yelp.com.sg',
  'empowernetwork.com',
  'fullscreenweather.com',
  'paginegialle.it',
  'wowpedia.org',
  'sosmart.vn',
  'ce.cn',
  'kariyer.net',
  'zol.com.cn',
  'mywebsearch.com',
  'google.az',
  'freep.com',
  'wsj.com.tr',
  'lge.com',
  'yelp.co.jp',
  'mystart.com',
  'cnet.de',
  'appledaily.com.tw',
  'blogtalkradio.com',
  'computerworld.com',
  'apartmenttherapy.com',
  'shopstyle.com',
  'chip.de',
  'px10.net',
  'hi-spider.com',
  'softonic.pl',
  'popmog.com',
  'timeout.com',
  'bitauto.com',
  'adne.tv',
  'google.com.ly',
  'people.com.cn',
  'gazzettaobjects.it',
  '10best.com',
  'megacurioso.com.br',
  'brandreachsys.com',
  'pchome.net',
  'symphonytools.com',
  'kankan.com',
  'clixsense.com',
  'guardianapis.com',
  'narod.ru',
  'probux.com',
  'qtrax.com',
  'adsbackup.net',
  'it168.com',
  'americanlivewire.com',
  'forgeofempires.com',
  'onlylady.com',
  'consumerreports.org',
  'growmobile.com',
  'blogfa.com',
  'wcpo.com',
  'sberbank.ru',
  'resultsaccelerator.net',
  'google.com.kw',
  'citicards.com',
  'mx25.net',
  'bing4.com',
  'fanfiction.net',
  'directadvert.ru',
  'flixster.com',
  'ileehoo.com',
  'vezuha.me',
  'hdfilmsitesi.com',
  'stridenation.com',
  'starbaby.cn',
  'newsgator.com',
  'ioladv.it',
  'chinatimes.com',
  'sfglobe.com',
  'yelp.cl',
  'zippyshare.com',
  'csdn.net',
  'roblox.com',
  'elegantthemes.com',
  'adserving.jp',
  'xgo.com.cn',
  'gazeta.ru',
  'e-junkie.com',
  'fdlstatic.com',
  'blogspot.com.tr',
  'homeaway.com',
  'icast.cn',
  'yelp.ie',
  'allmyvideos.net',
  'appisys.com',
  'sociablelabs.com',
  'youth.cn',
  'orf.at',
  'sitepoint.com',
  'webmoney.ru',
  'allocine.fr',
  'uclick.com',
  'yesky.com',
  'blogspot.jp',
  'gigacircle.com',
  'google.com.ng',
  'hupu.com',
  'mercadolibre.com.ve',
  'jrj.com.cn',
  'lds.org',
  'sulekha.com',
  'varzesh3.com',
  'jvzoo.com',
  'diceholdingsinc.com',
  'jimdo.com',
  'h12-media.com',
  'ashford.edu',
  'viss.vn',
  'gresille.org',
  'xvika.com',
  'blogspot.gr',
  'etao.com',
  'google.com.pk',
  'tokobagus.com',
  'lancenet.com.br',
  'arcadeparlor.com',
  'cj.com',
  'psychologytoday.com',
  'incapsula.com',
  'gmw.cn',
  'youyuan.com',
  'blogspot.in',
  'gutefrage.net',
  'yoka.com',
  'haiwainet.cn',
  'hatena.ne.jp',
  'indiamart.com',
  'tiny-toyz.com',
  'sunporno.com',
  'blogspot.de',
  'evbuc.com',
  'blackberry.net',
  'iplt20.com',
  'sape.ru',
  'tructiepbongda.com',
  'theskipshot.com',
  'blogspot.com.ar',
  'wideinfo.org',
  'epicurious.com',
  'blogspot.ru',
  'chatidcdn.com',
  'epimg.net',
  'kdnet.net',
  'voc.com.cn',
  'trovigo.com',
  'guzelleselim.com',
  'hudong.com',
  'nzn.me',
  'atvavrupa.tv',
  'eastday.com',
  'google.com.bd',
  'prismamediadigital.com',
  'over-blog.com',
  'plaintube.com',
  'gr.pn',
  'opensiteexplorer.org',
  'tractionize.com',
  'nationalgeographic.it',
  'b5m.com',
  'gamerankings.com',
  'tmzstore.com',
  'acesse.com',
  'china.com',
  'markafoni.com',
  'url.cn',
  'lnkdatas.com',
  'comenity.net',
  'qone8.com',
  'blogspot.com.br',
  'mpnrs.com',
  'insnw.net',
  'sgnapps.com',
  'viadeo.com',
  'dailysabah.com',
  'pixnet.net',
  'vodtraffic.com',
  'ajc.com',
  'tukif.com',
  'xpopad.com',
  '123srv.com',
  'matchflowmedia.com',
  'chexun.com',
  'sakura.ne.jp',
  'yelp.com.mx',
  'ca.gov',
  'hpdjjs.com',
  'nicovideo.jp',
  'bigdoor.com',
  'vtex.com.br',
  'zond.org',
  'focus.de',
  'life.com.tw',
  'systemmonitor.us',
  '39.net',
  'delivery51.com',
  'pcgames.com.cn',
  'convio.net',
  'thefreecamsecret.com',
  'wildstarforums.com',
  'cdnst.net',
  'blogspot.mx',
  'dainikbhaskar.com',
  'seat.it',
  'o24x7.com',
  'blackhatworld.com',
  'petflow.com',
  'skyrimforge.com',
  'lady8844.com',
  'mama.cn',
  'dol.gov',
  'gamned.com',
  'ameba.jp',
  'bigfoot.net',
  'seesaa.net',
  'voanews.com',
  'ccloud.io',
  'walmartlabs.com',
  'eazel.com',
  'getaviate.com',
  'noip.com',
  'targetphoto.com',
  'showtv.com.tr',
  'mysearchresults.com',
  'behindthesteelcurtain.com',
  'quovadisglobal.com',
  'civicplus.com',
  'xcar.com.cn',
  'ettoday.net',
  'gateable.com',
  'stockstar.com',
  'baomihua.com',
  'blogspot.com.es',
  'srv123.com',
  'tvdata.com.br',
  'staples-3p.com',
  'rw.gs',
  'pconline.com.cn',
  'warriorforum.com',
  'clicrbs.com.br',
  'nonstoppartner.net',
  'kinopoisk.ru',
  'yelp.com.tr',
  'neemu.com',
  'genieo.com',
  'pengyou.com',
  'dmm.co.jp',
  'weloveiconfonts.com',
  'kym-cdn.com',
  'm2newmedia.com',
  'linkszb.com',
  'cntv.cn',
  'reallifecam.com',
  'softlayer.net',
  'mmbang.com',
  'uctrac.com',
  'commentcamarche.net',
  'huff.lv',
  'revistamonet.com.br',
  'pbsrc.com',
  'scholastic.com',
  'soku.com',
  'buy-targeted-traffic.com',
  '17ok.com',
  'tim.com.br',
  'wmnlife.com',
  'homedepot.ca',
  'clickbank.com',
  'fuckish.com',
  'v1.cn',
  '4399.com',
  'asos.com',
  'eyny.com',
  'starmagazine.com',
  'baofeng.net',
  'superstoragemy.org',
  'ucoz.ru',
  'imgaft.com',
  'instair.net',
  'sharelive.net',
  'abebooks.it',
  'sap.com',
  'theadex.com',
  'm-decision.com',
  'depositfiles.com',
  'yourtango.com',
  'bkstr.com',
  'hswstatic.com',
  'avantlink.com',
  'dailysanctuary.com',
  'haber-sistemi.com',
  'webhostingtalk.com',
  'hitfix.com',
  'reachmax.cn',
  'admatic.com.tr',
  'purch.com',
  'yelp.dk',
  'kundenserver.de',
  'bookbub.com',
  'sfdcstatic.com',
  'caijing.com.cn',
  'glamour.com',
  'giadinhonline.vn',
  'register.com',
  'enet.com.cn',
  'kaskus.co.id',
  'adsniper.ru',
  'winzip.com',
  'allmovie.com',
  'myshopify.com',
  'lync.com',
  'webs.com',
  'loopnet.com',
  'chinaz.com',
  'awesomehp.com',
  'adreactor.com',
  'geek.com',
  'mbc.net',
  'chatid.com',
  'vuze.com',
  'b2wdigital.com',
  'gamesradar.com',
  'aejohg.com',
  'womenshealthmag.com',
  'brasilescola.com',
  'pcbaby.com.cn',
  'shopkrowd.com',
  'eddie4.nl',
  'jqw.com',
  'expedia.co.id',
  'yelp.cz',
  'yelp.se',
  'yaolan.com',
  'reason.com',
  'homedepot.com.mx',
  'bricknet.com',
  'lgcpm.com',
  'craigslist.com.ph',
  'weightwatchers.com',
  'jw.org',
  'tribpub.com',
  'yelp.pl',
  'plaxo.com',
  'requestnextadnet.com',
  'traileraddict.com',
  'adtrustmedia.com',
  'lga.org.mt',
  'barbioyunlari.org',
  'zybez.net',
  'moceanads.com',
  'kopimi.com',
  'adiquity.com',
  'bleedinggreennation.com',
  'vietnamnetad.vn',
  'biglobe.ne.jp',
  'expedia.com.hk',
  'staticamzn.com',
  'freeserve.com',
  'csnchicago.com',
  'rentedspaces.com',
  'newscientist.com',
  'redbeacon.com',
  'adlabs.ru',
  'kitchenstoringshop.com',
  'cpmbux.com',
  'anythumb.com',
  'cpmaxads.com',
  'iddaa-siteleri.com',
  't-online.de',
  'uber.com',
  'supercounters.com',
  'leylek.com',
  'baotintuc.vn',
  'dafont.com',
  'mobile01.com',
  'path.com',
  'hypergames.net',
  'toplist.sk',
  'appnexus.com',
  'easports.com',
  'forbeschina.com',
  'reverbnation.com',
  'blogglez.com',
  'shopyourway.com',
  'dlvr.it',
  'live-genieo-feed.com',
  'imo.im',
  'tbliab.net',
  'asana.com',
  'mnn.com',
  'thehollywoodmag.com',
  'daohongdonvenus.com',
  'forbes.com.tr',
  'trinklink.com',
  'mobdub.com',
  'cam4ads.com',
  'craigslist.co.in',
  'bol.com.br',
  'browsersafeguard.com',
  'sesamestats.com',
  'ticketmaster.co.uk',
  'tmztour.com',
  'rek.mobi',
  'weknowmemes.com',
  'fark.com',
  'mq4m.com',
  'cheaptickets.com',
  'hot-cpm.com',
  'adplxmd.com',
  'nimbuzz.com',
  'commerce.gov',
  'ad.org.vn',
  'impactradius.com',
  'jotform.com',
  'forbesmagazine.com',
  'furl.net',
  'dnaindia.com',
  'videoentertainmnt.com',
  'caferuj.com.tr',
  'bnef.com',
  'docer.com',
  'nu.nl',
  'eater.com',
  'liftoff.io',
  'samsungchaton.com',
  'gulfup.com',
  'asus.com.tw',
  'aboneturkuvaz.com',
  'hitslink.com',
  'themetapicture.com',
  'funnyordie.com',
  'htkulup.com',
  'avazudsp.net',
  'dvdcdn.com',
  'vivox.com',
  'ihg.com',
  'alljsscript.com',
  'advolution.de',
  'yelp.no',
  'admixer.net',
  'configar.org',
  'citizenjournal.net',
  'sacbee.com',
  'fingersoft.net',
  'wrating.com',
  'rsys2.net',
  'set.tv',
  'list.ru',
  'comicvine.com',
  'eb.com',
  'expedia.com.ar',
  'kooora.com',
  'utop.it',
  'masrawy.com',
  '1up.com',
  'moneycontrol.co.in',
  'hongkiat.com',
  'beytoote.com',
  'securedatatransit.com',
  'network-auth.com',
  'harvard.edu',
  'filseclab.com',
  'ikikisilikoyunlar.com',
  'mixcloud.com',
  'openadserve.com',
  'aeon.co',
  'janrainsso.com',
  'bebegimvebiz.com.tr',
  'interesticle.com',
  'yelp.pt',
  'chzbgr.com',
  'zello.com',
  'cams.com',
  'exchangedefender.com',
  'horyzon-media.com',
  'boredbug.com',
  'craigslist.de',
  'forbes.com.mx',
  'jscount.com',
  'yisou.com',
  'onlinehome-server.info',
  'musicbrainz.org',
  'semantictec.com',
  'newsdev.net',
  'haivainoi.com',
  'thoughtcatalog.com',
  'dnainfo.com',
  'extremetech.com',
  'layered.net',
  'magnumads.me',
  'starwoodhotels.com',
  'innityserve.net',
  'superiends.org',
  'zapto.org',
  'craigslist.com.tr',
  'cosmodergi.com',
  'forever21.com',
  'moviepilot.com',
  'scmpacdn.com',
  'screencrush.com',
  'chicos.com',
  'depend.com',
  'travelocity.com',
  'walmart.ca',
  'huluad.com',
  'tokenads.com',
  'craigslist.com.sg',
  'gsfn.us',
  'mendeley.com',
  'tapsense.com',
  'domaintools.com',
  'expedia.dk',
  'twc.com',
  'nowvideo.sx',
  'soclminer.com.br',
  't.cn',
  'irrawaddy.org',
  'yourbridebook.com',
  'el-mundo.net',
  'isbank.com.tr',
  'oyunlar1.com',
  'oyunvitrini.com',
  'cosmogirl.com.tr',
  'cdw.com',
  'scrippsnationalnews.com',
  'mobidea.com',
  'logme.in',
  'domainsponsor.com',
  'tipo777.com',
  'theline.com',
  'pandawhale.com',
  'zdworks.com',
  'webgains.com',
  'triongames.com',
  'iht.com',
  'swamedia.com',
  'trumba.com',
  'craigslist.com.tw',
  'republer.com',
  'adskyforever.com',
  'craigslist.at',
  'casaevideo.com.br',
  'clip.vn',
  'bangkokpost.com',
  'craigslist.jp',
  'craigslist.fr',
  'd4p.net',
  'pastaoyunu.com',
  'esquire.com.tr',
  'billdesk.com',
  'livepcsupport.com',
  'dhgiris.com',
  'craigslist.be',
  'simplyhired.com',
  'ad122m.com',
  'linternaute.com',
  'mkt941.com',
  'haivlfan.com',
  'hubimg.com',
  'rncdn1.com',
  'thelocalsearchnetwork.com',
  'knoworthy.com',
  'bseller.com.br',
  'peoplepc.com',
  'makazi.com',
  'ics0.com',
  'mindbodyonline.com',
  'tube911.com',
  'minika.com.tr',
  'vporn.com',
  'cia.gov',
  'clicksvenue.com',
  'craigslist.dk',
  'craigslist.com.cn',
  'axs.com',
  'guiamais.com.br',
  'kanimg.com',
  'silence-ads.com',
  'tmztournyc.com',
  'craigslist.gr',
  'fox40.com',
  'rummblelabs.com',
  'jassets.com',
  'glotorrents.com',
  'craigslist.fi',
  'claromusica.com',
  'expedia.co.kr',
  'battlefield.com',
  'nationalpost.com',
  'bloomberglaw.com',
  'gamer.com.tw',
  'cincyjungle.com',
  'spokeo.com',
  'megabrowse.biz',
  'iphmx.com',
  'reignofgaming.net',
  'rexposta.com.br',
  'admnx.com',
  'mikle.com',
  'cybertrade.co.za',
  'craigslist.pl',
  'inspcloud.com',
  'uzjvh.com',
  'joblo.com',
  'redbull.com',
  'securitymetrics.com',
  'teknokulis.com',
  'spilcloud.com',
  'gizmodo.es',
  'elwatannews.com',
  'isteinsan.com.tr',
  'pmc.com',
  'minhaserie.com.br',
  'expediamail.com',
  'dt00.net',
  'nhle.com',
  'reinvigorate.net',
  'christianbook.com',
  'zerohedge.com',
  '20minutos.es',
  'hsbc.com.br',
  'craigslist.it',
  '9v8kxvfvw.com',
  'flipagram.com',
  'dynect.net',
  'conduit-data.com',
  'expedia.fi',
  'meus5minutos.com.br',
  'craigslist.pt',
  'torrent-finder.info',
  'incredibarvuz1.com',
  'theepochtimes.com',
  'logos.com',
  'otohaber.com.tr',
  'nginx.org',
  'telenet-ops.be',
  'snapfish.com',
  'hub.am',
  'slashdotmedia.com',
  'craigslist.es',
  'mmtro.com',
  'ehow.com.br',
  'newsfactor.us',
  'craigslist.se',
  'votinginfoproject.org',
  '726.com',
  'diynetwork.com',
  'nguyenkim.com',
  'wps.cn',
  'softpedia.com',
  'onforb.es',
  'minq.com',
  'vmware.com',
  'ink1001.com',
  'mysearch-online.com',
  'shopsocially.com',
  'applift.com',
  'dict.cc',
  'hsn.com',
  'enigmasoftware.com',
  'beatsmusic.com',
  'cifraclub.com.br',
  'oglobo.com.br',
  'info-stream.net',
  'arrowheadpride.com',
  'variety411.com',
  'teddybrinkofski.com',
  'piclens.com',
  'un.org',
  'sanoma.fi',
  'jobrapido.com',
  'gruponzn.com',
  'aka.ms',
  'live-lyrics.com',
  'craigslist.co.uk',
  'idealo.de',
  'expedia.ie',
  'resellerratings.com',
  'epi.vn',
  'cp20.com',
  'online.de',
  'travelchannel.com',
  'crackberry.com',
  'reachjunction.com',
  'putlocker.bz',
  'ovh.net',
  'mediabong.com',
  'caspion.com',
  'paddypower.com',
  'verizonbusiness.com',
  'aftonbladet.se',
  'timesofindia.com',
  'qcloud.com',
  'objectedge.com',
  'rcsmediagroup.it',
  'fun.tv',
  'greatarcadehits.com',
  'reliableremodeler.ca',
  'admoda.com',
  'widespace.com',
  'cymera.com',
  'baltimorebeatdown.com',
  'realtracker.com',
  'baomoi.mobi',
  'glu.com',
  'cy-pr.com',
  'fmsads.com',
  'cttsrv.com',
  'samsungadhub.com',
  'dafity.com.br',
  'digikala.com',
  'magreprints.com',
  'hitsprocessor.com',
  'pampanetwork.com',
  'twnmm.com',
  'leguide.com',
  'whitehouseblackmarket.com',
  'expedia.nl',
  'webmdhealthservices.com',
  'truyenhinhanvien.vn',
  'woobox.com',
  'easy2.com',
  'publicradio.org',
  'mtvnimages.com',
  'forbes.pl',
  'craigslist.ch',
  'rockstargames.com',
  'afar.com',
  'ramtrucks.com',
  'tenethealth.com',
  'investingchannel.com',
  'gettvwizard.com',
  'wnco.com',
  'condenaststore.com',
  'digitalspy.co.uk',
  'strava.com',
  'comprises.info',
  'paradergi.com.tr',
  'nordstromimage.com',
  'micromaxinfo.com',
  'rocketfuel.com',
  'bigblueview.com',
  'angelpush.com',
  'liveperson.com',
  'skyhookwireless.com',
  'haizap.com',
  'openadserving.com',
  'worldweatheronline.com',
  'yikyakapi.net',
  'justice.gov',
  'craigslist.com',
  'milanuncios.com',
  'radyoturkuvaz.com',
  'qzone.com',
  'imagebam.com',
  'usasabah.com',
  'evmanya.com',
  'refinedads.com',
  'storycorps.org',
  'battleredblog.com',
  'bangmygfs.com',
  'expedia.com.ph',
  'expedia.co.th',
  'neweggflash.com',
  'nick.com',
  'snmmd.nl',
  'scottrade.com',
  'onpointradio.org',
  'latinvestor.com',
  'bedbathandbeyond.com',
  'domainnamesales.com',
  'zmags.com',
  'mozilla-europe.org',
  'springer.com',
  'vinepair.com',
  'akismet.com',
  'ccgslb.com',
  'publy.net',
  'chemistrychef.com',
  'msrch.com',
  'glide.me',
  'hrw.org',
  'uni-rostock.de',
  'wnd.com',
  'intuitcdn.net',
  'realist.gen.tr',
  'yahoo.com.br',
  'onlymyhealth.com',
  'china.com.cn',
  'transmissionbt.com',
  'huanqiu.com',
  'dyngate.com',
  'acmepackingcompany.com',
  'buffalorumblings.com',
  'coronalabs.com',
  'tinthethao.com.vn',
  'gingersoftware.com',
  'geico.com',
  'miniinthebox.com',
  'bitcomet.com',
  'nuance.com',
  'atvnetworks.tv',
  'yougov.com',
  'ccmbg.com',
  'mckesson.com',
  'hearstdigital.com',
  'phunutoday.vn',
  'icims.com',
  'curiyo.com',
  'magicjack.com',
  'sofra.com.tr',
  'technologyreview.com',
  'tmzhollywoodsports.com',
  'superbahis217.com',
  'cequinttmoecid.com',
  'cheezburger.com',
  'ci123.com',
  'adrtr.net',
  'informer.com',
  'truefitcorp.com',
  'pnc.com',
  'powerlinks.com',
  'craigslist.com.au',
  'csidata.com',
  'iobconcursos.com',
  'homestead.com',
  'rawstory.com',
  'binaryoptionstm.com',
  'expedia.com.sg',
  'expedia.mx',
  'pangia.biz',
  'ad6.fr',
  'drtvtracker.com',
  'golfdigest.com',
  'capitalone360.com',
  'apiodyth.com',
  'commissariatodips.it',
  'e-pages.dk',
  'kia.com',
  'nuancemobility.net',
  'expedia.com.tw',
  'samdan.com.tr',
  'fuse.net',
  'sciencemag.org',
  'getpantheon.com',
  'reali.st',
  'trendyol.com',
  'correioweb.com.br',
  'houselogic.com',
  'streambroadcastmedia.com',
  'hexagon.cc',
  'bigcatcountry.com',
  'thoigian.com.vn',
  'forbesid.com',
  'futurenet.com',
  'ekstat.com',
  'trustgo.com',
  'alarabiya.net',
  'surface.com',
  'shoefitr.com',
  'biznessapps.com',
  'digitalfirstmedia.com',
  'gopro.com',
  'webaslan.com',
  'haberzamani.com',
  'adstatic.com',
  'jointheteam.com',
  'dailyinfovideo.com',
  'bkatjs.info',
  'money.com',
  'vgsgaming-ads.com',
  'bbm.com',
  'photorank.me',
  'ptreklamcrv.com.tr',
  'observer.com',
  'autotrendworld.com',
  'cookfor1.com',
  't411.me',
  'upsieutoc.com',
  'timehop.com',
  'expedia.no',
  'isprimecdn.com',
  'contentexplorer.net',
  'da-ads.com',
  'tipki.it',
  'turkuvazmobil.com',
  'sezgisel.com',
  'admeme.net',
  'bloombergtradebook.com',
  'clickcarreira.com.br',
  'scoringserving.net',
  'trangvangvietnam.com',
  'dose.com',
  'guvenliinternet.org',
  'inkfrog.com',
  'rivalo3.com',
  'placed.com',
  'metacafe.com',
  'datingvip.com',
  'loseit.com',
  'bloomberglink.com',
  'infashionmag.com',
  'dsnetwb.com',
  'bloggingtheboys.com',
  'backcountry.com',
  'adtlgc.com',
  'trovit.com',
  'vechai.info',
  'dallasnews.com',
  'craigslist.com.pe',
  'vote411.org',
  'anonym.to',
  'afip.gob.ar',
  'instacam.com',
  'codeplex.com',
  'dailynorseman.com',
  'schoolwires.com',
  'craigslist.com.mx',
  'shappify.com',
  'boltsfromtheblue.com',
  'oldnavy.com',
  'vidprocess.com',
  'markedup.com',
  'ampagency.com',
  'hoverzoom.net',
  'hbo.com',
  'sandclowd.com',
  'womenpov.com',
  'rtbpopd.com',
  'joomlatune.com',
  'msft.net',
  'torrenty.org',
  'altincicadde.com',
  'hsappstatic.net',
  'vef.vn',
  'zndsk.com',
  'amazon-press.it',
  'nowvideo.at',
  'gdgt.com',
  'binary.net',
  'lyonnaise-des-eaux.fr',
  'creditkarma.com',
  'splashnewsonline.com',
  'zassets.com',
  'pub-fit.com',
  'mnectar.com',
  'yeniasir.com.tr',
  'ishort.co',
  'research-int.se',
  'turkuvazabone.com',
  'zapkolik.com',
  'forbesindia.com',
  'dawgsbynature.com',
  'navisite.net',
  'shinobi.jp',
  'adkengage.com',
  'backup.com',
  'milehighreport.com',
  'incehesap.com',
  'novanetservice.com',
  'nld.com.vn',
  '411.com',
  'i-vietnam.vn',
  'mystreamservice.com',
  'cdnslate.com',
  'piratebaytorrents.info',
  'shoptime.com.br',
  'style.com',
  'rapidshare.com',
  'buyatoyota.com',
  'shopperapproved.com',
  'mentalfloss.com',
  'ninersnation.com',
  'newinputinfoservice.com',
  'kohlscorporation.com',
  'bucsnation.com',
  'bannersnack.com',
  'frogupdate.com',
  'futurecdn.net',
  'phluant.com',
  'itc.cn',
  'pronto.com',
  'newstogram.com',
  'canalstreetchronicles.com',
  'asda.com',
  'modernluxury.com',
  'usat.ly',
  'happytrips.com',
  'socialbeauty.com.br',
  'rising.com.cn',
  'watchguard.com',
  'turkuvazmatbaacilik.com',
  'helioscloud.com',
  'bongdep.com',
  'fap.to',
  'unibet.com',
  'joygamedl.com',
  'ddccdn.com',
  'bresnan.net',
  'expedia.se',
  'nextag.com',
  'netshoes.com.ar',
  'steepto.com',
  'gamcare.org.uk',
  'muare.vn',
  'trust-guard.com',
  'eye.fi',
  'ibt.com',
  'gamib.com',
  'businessinsider.in',
  'givemesport.com',
  'mydomain.com',
  'shar.es',
  'seattlepi.com',
  'o2online.de',
  'wikia.net',
  's-analytics.info',
  'conversionsbox.com',
  'smi2.ru',
  'tdameritrade.com',
  'gigcount.com',
  'ewebse.com',
  'befrugal.com',
  'fieldgulls.com',
  'monografias.com',
  'doodle.com',
  'google.iq',
  'catscratchreader.com',
  'turkuvazyayin.com.tr',
  'biddingx.com',
  'atomz.com',
  'webex.com',
  'greenerweb.info',
  'clker.com',
  'hc.ru',
  'yapikredi.com.tr',
  'streamsend.com',
  'bizlive.vn',
  'jivosite.com',
  'crisppremium.com',
  'telestream.net',
  'lzjl.com',
  'vimg.net',
  'pathfinder.com',
  'vodlocker.com',
  'xahoi.com.vn',
  'widgetserver.com',
  'appsmartpush.com',
  'registeridm.com',
  'tradetracker.net',
  'ekstrabladet.dk',
  'hexagram.com',
  'tagboard.com',
  'newswhip.com',
  'oranara.com',
  'assets-gap.com',
  'bongdainfo.com',
  'vuiviet.vn',
  'yeniaktuel.com.tr',
  'wilink.com',
  'angieslist.com',
  'clktraker.com',
  'echoplatform.com',
  'airbnb.com',
  'quality-channel.de',
  'stampedeblue.com',
  'islenogren.com',
  'flagcounter.com',
  'musiccitymiracles.com',
  'cjsab.com',
  'walmart.com.mx',
  'ganggreennation.com',
  'image-maps.com',
  'servedby-buysellads.com',
  'stbm.it',
  'vietnamnettv.vn',
  'jetblue.com',
  'governoeletronico.gov.br',
  'rebelmouse.com',
  'laodong.com.vn',
  'nflplayerengagement.com',
  'brightcove.net',
  'primusad.com',
  'alphassl.com',
  'roixdelivery.com',
  'myfox8.com',
  'expedia.com.vn',
  'randomhouse.com',
  'villagevoice.com',
  'gospect.com',
  'brainyquote.com',
  'rollbar.com',
  'napster.com',
  'marketgid.com.ua',
  'decompras.com',
  'interstats.org',
  'airmail.net',
  'metoffice.gov.uk',
  'betfair.com',
  'vidcore.tv',
  'rabbitscams.com',
  'pvp.net',
  'bloombergindexes.com',
  'celticsblog.com',
  'sonyericsson.com',
  'decolar.com',
  'ligtv.com.tr',
  'staticloads.com',
  'parents.com',
  'samsclub.com',
  'catho.com.br',
  'citysearch.com',
  'tictacti.com',
  'rewardtv.com',
  'detroitnews.com',
  'dizibox.org',
  'delivery55.com',
  'daumcdn.net',
  'xyimg.net',
  'payzippy.com',
  'ycharts.com',
  'cartoontube.com',
  'netshoes.com.mx',
  'beead.co.uk',
  'edmodo.com',
  'adviator.com',
  '1and1.co.uk',
  'usatodayeducation.com',
  'coupons.net',
  'mediaset.net',
  'burstbeacon.com',
  'snoonet.org',
  'remintrex.com',
  'topeleven.com',
  'realprotectedredirect.com',
  'mixx.com',
  'smiles.com.br',
  'arcsoft.com',
  'forbesmedia.com',
  'wfp.org',
  'look.io',
  'gigaom.com',
  'prideofdetroit.com',
  '9c9media.com',
  'vonage.net',
  'mybinarysystem.com',
  'edmunds-media.com',
  'siteapps.com',
  'gammae.com',
  'bugherd.com',
  'thekitchn.com',
  'forbesglobalceoconference.com',
  'kiwiirc.com',
  'comcastspotlight.com',
  'drudgereportarchives.com',
  'www8-hp.com',
  'cpmaffiliation.com',
  'patspulpit.com',
  'thephinsider.com',
  'mail.dk',
  'peoplem.ag',
  'gutenberg.org',
  'newsrep.net',
  'pitchfork.com',
  'spanishdict.com',
  'yeniasirilan.com',
  'spamexperts.net',
  'vcommission.com',
  'blueserving.com',
  'dvipcdn.com',
  'rutube.ru',
  'hogshaven.com',
  'mightytext.net',
  'cdnplanet.com',
  'bigpoint.com',
  'informars.com',
  'stubhub.co.uk',
  'callofduty.com',
  'dailyprofitmethod.org',
  'p2pdl.com',
  'gallup.com',
  'cpc-ads.com',
  'patheos.com',
  'ultimedia.com',
  'feeyun.com',
  'fcounter.info',
  'telia.com',
  'jc-affiliates.com',
  'optusnet.com.au',
  '4cdn.org',
  'dr.dk',
  'fntk.co',
  'mktw.net',
  'go2jump.org',
  'leonardo.it',
  'synacast.com',
  'gossipcop.com',
  'canadapost.ca',
  'planet.nl',
  'stopbadware.org',
  'sublimetext.com',
  'cabbjs.info',
  'pantherssl.com',
  'zam.com',
  'arxiv.org',
  'icopyright.net',
  'aegworldwide.com',
  'healthcare.gov',
  'matomy.com',
  'wdtvlive.com',
  'forbesrussia.ru',
  'adicio.com',
  'ventivmedia.com',
  'bloombergsef.com',
  'iodonna.it',
  'archive.is',
  'fundacioncarlosslim.org',
  'maclife.com',
  'vistaprint.com',
  'ctt.ec',
  'palocalworld.info',
  'babycentre.co.uk',
  'emediate.com.br',
  'sky.fm',
  'flashget.com',
  'utarget.ru',
  'purechat.com',
  'videosense.com',
  'jsonline.com',
  'appstore.com',
  'bannersnack.net',
  'quizgroup.com',
  'datawire.net',
  'mensfitness.com',
  'directads.de',
  'chardward.us',
  'lxdcdn.net',
  '24h-hotel.com',
  'silverandblackpride.com',
  'markitcdn.com',
  'schlund.de',
  'speedbit.com',
  'ad-stir.com',
  'addlive.io',
  'wt-eu02.net',
  'mediasetpremium.it',
  'nesn.com',
  'myharmony.com',
  'senzari.com',
  '99widgets.com',
  'dvs.vn',
  'dummy-domain-do-not-change.com',
  'therichest.com',
  'watchmygf.com',
  'cloudinsights.com',
  'concursolutions.com',
  'ehow.co.uk',
  'ddni.net',
  'uploadable.ch',
  'kn3.net',
  'findarticles.com',
  'cleantechnica.com',
  'revengeofthebirds.com',
  'contactatonce.com',
  'connectify.me',
  'zamimg.com',
  '5giay.vn',
  'extra-imagens.com.br',
  'wscdns.com',
  'hrdepartment.com',
  'netsdaily.com',
  'thefalcoholic.com',
  'gorillions.com',
  'classicshell.net',
  'zunnit.com',
  'hoobly.com',
  'globalmediaserving.com',
  'namequery.com',
  'wd2go.com',
  'wolframalpha.com',
  'csafer.net',
  'bradesconetempresa.b.br',
  'mbtrx.com',
  'barilliance.net',
  'coveritlive.com',
  'memecdn.com',
  'slingmedia.com',
  'mailshop.co.uk',
  'cobex.net',
  'twitchy.com',
  'patricinhaesperta.com.br',
  'thedailyswarm.com',
  'epi.com.vn',
  'routerlogin.net',
  'highspeedbackbone.net',
  'thebiglead.com',
  'dhhs.gov',
  'cntrafficpro.com',
  'libertyballers.com',
  'showtvnet.com',
  'noom.com',
  'merchantadvantage.com',
  'builddirect.com',
  'strcst.net',
  'itv.com',
  'schwab.com',
  'vads.net.vn',
  'n111adserv.com',
  'cdndelivery.com',
  'pixenka.com',
  'anywho.com',
  'cumhuriyet.com.tr',
  'medicarenoticedeal.me',
  'surpax.net',
  'creativecloud.com',
  'fema.gov',
  'sadecehosting.com',
  'totaltech.it',
  'malwarebytes.org',
  'blekko.com',
  'globalnews.ca',
  'ntradmin.com',
  'ole.com.ar',
  'viddler.com',
  'grupoabril.com.br',
  'technobuffalo.com',
  'air2s.com',
  'payments-amazon.com',
  'babiesrus.com',
  'microsoftvirtualacademy.com',
  'getsidecar.com',
  'dpstatic.com',
  'business-standard.com',
  'conxport.com',
  'techrepublic.com',
  'gravityrd-services.com',
  'cnappbox.com',
  'pagesuite-professional.co.uk',
  'behe.com',
  'sms-mmm.com',
  '7graus.com',
  'samsungvideohub.com',
  'mid-day.com',
  'postingandtoasting.com',
  'harpersbazaar.com',
  'realclear.com',
  'tripadvisor.it',
  'staples-static.com',
  'freenet.de',
  'bodis.com',
  'tempoagora.com.br',
  'appbrain.com',
  '16mm.it',
  'sinemadafilmizle.com',
  'totalfilm.com',
  'extfeed.net',
  'pctools.com',
  'imgci.com',
  'betbooaffiliates.com',
  'rave-api.com',
  'tvgcdn.net',
  'windycitygridiron.com',
  'akbank.com',
  'connatix.com',
  'key.com',
  'iinet.net.au',
  'kampyle.com',
  'dnsalias.com',
  'thefederalist.com',
  'dice.com',
  'arstechnica.net',
  'homestore.com',
  '013net.net',
  'interlude.fm',
  'meneame.net',
  'quotemedia.com',
  'myfreeyp.com',
  'senate.gov',
  'thoughtsondance.info',
  'pornoid.com',
  'pbc.com',
  'shorte.st',
  'pri.org',
  'microsoftonline-p.net',
  '1worldonline.com',
  'yourdailyscoop.com',
  'vneconomy.vn',
  'daddymami.net',
  'online.no',
  'gamespy.com',
  'groupon.it',
  'wsj.de',
  'hyperadslite.com',
  'savefreescoresseekers.me',
  'godatafeed.com',
  'democracynow.org',
  'adspirit.de',
  'ad131m.com',
  'bet.com',
  'mic.com',
  'chow.com',
  'clubpenguin.com',
  'expediaaffiliate.com',
  'itsfogo.com',
  'chacha.com',
  'clearsale.com.br',
  'eurosport.com',
  'brighteroption.com',
  'bls.gov',
  'turfshowtimes.com',
  'wmobjects.com.br',
  'aufeminin.com',
  'fromthetop.org',
  'contactmusic.com',
  'bitbucket.org',
  'the9.com',
  'midco.net',
  'bodybuilderdaily.com',
  'goseeklocation.com',
  'redirecting.ws',
  'express.co.uk',
  'superdownloads.com.br',
  'boxcloud.com',
  'iinmobi.com',
  'modamob.com',
  'strands.com',
  'rei.com',
  'adtiger.de',
  'boingtv.it',
  'walmartcontacts.com',
  'cbsstatic.com',
  'adsynth.com',
  'timberland.com',
  'trackedlink.net',
  'assinefolha.com.br',
  'peer5.com',
  'opbandit.com',
  'vanguard.com',
  'klippal.com',
  'naturalmotion.com',
  'irishcentral.com',
  'offeredby.net',
  'fedexsameday.com',
  'gostats.com',
  'iyuntian.com',
  'sage.co.uk',
  'migre.me',
  'bznx.net',
  'realtor.org',
  'mydati.com',
  'ibtimes.co.in',
  'm2o.it',
  'direct-tap.com',
  'homedepotmeasures.com',
  'mailconnected.co.uk',
  'knowyourmeme.com',
  'realclearscience.com',
  'gtdaily.com',
  'terra.com.ar',
  'vitamio.org',
  'ctia.org',
  'expediainc.com',
  'cerberusapp.com',
  'name-services.com',
  'cnnturk.com',
  'onionstatic.com',
  'ctv.ca',
  'dota2wiki.com',
  'dropboxstatic.com',
  'pclncdn.com',
  'bringyourchallenges.com',
  'capptain.com',
  'yimg.jp',
  'qrius.me',
  'humanevents.com',
  'interingilizce.com',
  'inskinad.com',
  'tomsitpro.com',
  'ziffprod.com',
  'hotelurbano.com.br',
  'berries.com',
  'taobao.org',
  'mobitv.com',
  'bravotv.com',
  'iba.com.br',
  'amzn.com',
  'igg.com',
  'ordergroove.com',
  'cnbcprime.com',
  'voicestar.com',
  'tamind.ir',
  'pianetadonna.it',
  'tns-gallup.dk',
  'snip.ly',
  'mediashopping.it',
  'ctctcdn.com',
  'findagrave.com',
  'ieee.org',
  'r29static.com',
  'pncmc.com',
  'qiyipic.com',
  'prnx.net',
  'recipe.com',
  'networkanalytics.net',
  'flip.it',
  'investingmediasolutions.com',
  'searchfun.in',
  'bradescofinanciamentos.com.br',
  'plex.bz',
  'governmentjobs.com',
  'usablenet.com',
  'eathei.com',
  'starfluff.com',
  'jacobs.com',
  'kul.vn',
  'viki.com',
  'premiereradio.net',
  '4chan.org',
  'jampp.com',
  'twenga.com',
  'nextopiasoftware.com',
  'ugwdevice.net',
  'sonic.com',
  'poweroffer.net',
  'snopes.com',
  'thefreelibrary.com',
  'fullsail.edu',
  'shareyourlink.net',
  'fivestore.it',
  'supermaneddy.com',
  'installerdatauk.info',
  'mailroute.net',
  'brainpop.com',
  'raptorshq.com',
  'hotwords.com',
  'digitalpoint.com',
  'd2hshop.com',
  'seatguru.com',
  'wnba.com',
  'boxcdn.net',
  'games724.com',
  'mzcdn.com',
  'mimicromax.com',
  'viewmotions.com',
  'wordreference.net',
  'canlitv.tv',
  'ifc.com',
  'cdnjs.com',
  'aspplayground.net',
  'emagazines.com',
  'abacast.net',
  'bellaliant.net',
  'squareup.com',
  'ipgeoapi.com',
  'bandsintown.com',
  'xoedge.com',
  'bizj.us',
  'adtheorent.com',
  'ecollege.com',
  'vodafone.com',
  'yr.no',
  'envato.com',
  'kaskus.com',
  '1dial.com',
  'glo.bo',
  'revnm.com',
  'bonappetit.com',
  'api-alliance.com',
  'nbcbayarea.com',
  'adnotch.com',
  'cotssl.net',
  'irc.su',
  'novalayer.org',
  'southwestvacations.com',
  'unbxdapi.com',
  'celljournalist.com',
  'sciencefriday.com',
  'alloyentertainment.com',
  'itaringa.net',
  'drivershq.com',
  'adsunflower.com',
  'anysex.com',
  'meride.tv',
  'tinyco.com',
  'sanalpazar.com',
  'continular.com',
  'ilsemedia.nl',
  'wn.com',
  'vetogate.com',
  'appgenuine.com',
  'freeonlineusers.com',
  'jotfor.ms',
  'indulgy.com',
  'gizmodo.co.uk',
  'syncaccess.net',
  'filmesonlinegratis.net',
  'matheranalytics.com',
  'cloudcdn.net',
  'mediaweek.com',
  '8digits.com',
  'systemmonitor.co.uk',
  'creditoruralcaixa.com.br',
  'sondakika.com',
  'eaton.com',
  'blogs.com',
  'getrockerbox.com',
  'mangarockhd.com',
  'tintuconline.com.vn',
  'cabinet-office.gov.uk',
  'ccsp.com.br',
  'manta-r2.com',
  'myofferspro.com',
  'atlas.com',
  'livestrongcdn.com',
  'camera360.com',
  'buienalarm.nl',
  'gamewall.me',
  'wildtangent.com',
  'ameritrade.com',
  'kitco.com',
  'cnzz.net',
  'xoso.net',
  'digilant.com',
  'crackle.com',
  'segpaycs.com',
  'spartzmedia.com',
  'naseej.com.sa',
  'classmates.com',
  'compassionandchoices.org',
  'atlassian.com',
  'merck.com',
  'ispgateway.de',
  'giallozafferano.it',
  'wapka.me',
  'idref.fr',
  'shat.net',
  'poste.it',
  'rmmcdn.com',
  'thefoxnation.com',
  'getspeedbrowserp.com',
  'retentionscience.com',
  'batstrading.com',
  'bradescoimoveis.com.br',
  'dimestore.com',
  'adklo.com',
  'postlets.com',
  'softsonic.net',
  'softonicads.com',
  'mobileoversee.net',
  'howtogeek.com',
  'bestbuy-jobs.com',
  'xosothantai.com',
  'tin.it',
  'ocdn.eu',
  'partsearch.com',
  'aarki.net',
  'berniaga.com',
  'shareholder.com',
  'sendeyim.com',
  'app.lk',
  'tvtropes.org',
  'edgar-online.com',
  'aams.it',
  '123pay.vn',
  'desmotivaciones.es',
  'cepro.com',
  'mediasetitalia.it',
  'sltrib.com',
  'asos-media.com',
  'mirtesen.ru',
  'banzai.it',
  'infoplease.com',
  'csnphilly.com',
  'artisantools.com',
  'workopolis.com',
  'jazzedcdn.com',
  'subiz.com',
  'blogabull.com',
  'internetvideoarchive.com',
  'tf1.fr',
  'websteroidsapp.com',
  'boomrat.com',
  'syncstatsdata.com',
  'inrixmedia.com',
  'bwbx.io',
  'oboom.com',
  'aliceposta.it',
  'minecraftforums.net',
  'dcbfjs.info',
  'torrentdownloads.net',
  'dogangazetecilik.com.tr',
  'odometer.com',
  'ringtonematcher.com',
  'mmnetwork.mobi',
  'line.me',
  'creativeapis.com',
  'kadinvekadin.net',
  'crwd.io',
  'activerain.com',
  'nationalenquirer.com',
  'pnas.org',
  'menshealth.com',
  'virusfree.cz',
  'theartoflivingbetter.com',
  'klimg.com',
  '9nl.cc',
  'examinerontopic.com',
  'puretracks.com',
  'ticketweb.com',
  'imdbws.com',
  'imlive.com',
  'nwave.de',
  'videotender.com',
  'cio.com',
  'businesscatalyst.com',
  'lumosity.com',
  'mongodb.org',
  'noisey.com',
  'ccomrcdn.com',
  'socdm.com',
  'best2tol.com',
  'marriott-email.com',
  'blammoservers.com',
  'ssa.gov',
  'telmex.net',
  'cargurus.com',
  'statig.com',
  'qqmail.com',
  'ultimateclassicrock.com',
  'businessinsider.my',
  'skynet.be',
  'ccgslb.com.cn',
  'lan.com',
  'full.sc',
  'kioskea.net',
  'thevoterguide.org',
  'locationlabs.com',
  'gambleaware.co.uk',
  'chiltepin.net',
  'facebookmail.com',
  'westga.edu',
  'twitthis.com',
  'differencegames.com',
  'thesmokinggun.com',
  'adoftheyear.com',
  'appnext.com',
  'fashiontmes.com',
  'signupgenius.com',
  'bluemediappc.com',
  'whitepagesinc.com',
  'turninc.com',
  'hcuge.ch',
  'futureplc.com',
  'ukashal.com.tr',
  'adtpix.com',
  'counter-strike.net',
  'geni.us',
  'micromaxonline.com',
  'bright.net',
  'toshiba.com',
  'bitshare.com',
  'bigcharts.com',
  'cnet.co.kr',
  'leadzu.com',
  'forbesmiddleeast.com',
  'a-static.com',
  'ink361.com',
  'xobni.com',
  'changeip.com',
  'ndnmediaservices.com',
  'cwtv.com',
  'ticketsnow.com',
  'kongcdn.com',
  'jumptaps.com',
  'iadvize.com',
  'instagr.am',
  'flirchicdn.com',
  'thdws.com',
  'torontosun.com',
  'plansmedihealthsolutions.me',
  'openvpn.net',
  'strawpoll.me',
  'pubdirecte.com',
  'givalike.org',
  'leechers-paradise.org',
  'openoffice.org',
  'pleer.com',
  'caixaseguros.com.br',
  'whitepagescustomers.com',
  'dowjonesonline.com',
  'tivi988.com',
  'amctv.com',
  'heartinternet.uk',
  'homeshop18.com',
  'da3e3.net',
  'celebrityselfy.com',
  'securepageloader.com',
  'ourtime.org',
  'bidtrk.com',
  'dlink.com.tw',
  '123-reg.co.uk',
  'intelliad.com',
  'bbcmundo.com',
  'marktplaats.nl',
  'fineartamerica.com',
  'lininteractive.com',
  'gocricket.com',
  'brainfall.com',
  'sblk.io',
  'bandcamp.com',
  'marfeel.com',
  'joy.ac',
  'artlebedev.ru',
  'house.gov',
  'rai.it',
  'theguardian.tv',
  'aliqin.cn',
  'saurik.com',
  'pushpin.com',
  'fimserve.com',
  'mezzobit.com',
  'hulkshare.com',
  'viewalytics.com',
  'beeimg.com',
  'ad-serverparc.nl',
  'anpdm.com',
  'hip2save.com',
  'myegy.com',
  'doisotrung.com',
  'medyanet.net',
  'giltcdn.com',
  'xxxbunker.com',
  'e5.sk',
  'metavertising.com',
  'posta.com.tr',
  'infosbelges.eu',
  'atlanticbb.net',
  'medianewsgroup.com',
  'bimbolive.com',
  'realgravity.com',
  'leaseweb.net',
  'dhgate.com',
  'xosominhngoc.com',
  'yahoomail.com',
  'frgimages.com',
  'alipayobjects.com',
  'cdnbd.com',
  'ibibo.com',
  'uvnimg.com',
  'appleinsider.com',
  'banerator.net',
  'prserv.net',
  'veniso.com',
  'assineglobo.com.br',
  'taggify.net',
  'dailyveso.com',
  'ajillionmax.com',
  'wrightsmedia.com',
  'adups.cn',
  'gogoanime.com',
  'bradescopoderpublico.com.br',
  'skybet.com',
  'thetvdb.com',
  'mystartantiphishing.com',
  'neimanmarcus.com',
  'northcountrypublicradio.org',
  'adupmediaxml.com',
  'fastcdn.com',
  'startappservice.com',
  'archives.gov',
  'edgedatg.com',
  'unica.com',
  'kakao.co.kr',
  'xfinitytv.com',
  'piksel.com',
  'pornfeedback.com',
  'fowar.net',
  'afip.gov.ar',
  'acsalaska.net',
  'cackle.me',
  'download-ap.com',
  'istockimg.com',
  'slashfilm.com',
  'tmdb.org',
  'intelius.com',
  'cnevids.com',
  'omgfacts.com',
  'comicbook.com',
  'rai.tv',
  'justin.tv',
  'arin.net',
  'edreams.it',
  'sedo.com',
  'ifilez.org',
  'lookcpm.com',
  'carhartt.com',
  'cogeco.ca',
  'bitreactor.to',
  'musicradar.com',
  'ulogin.ru',
  'demandforce.com',
  'fluidsurveys.com',
  'casasbahia.com.br',
  'expediajobs.com',
  'amerikanki.com',
  'videoweed.es',
  'weeklyfinancialsolutions.com',
  'janrain.ws',
  'ttmikro.com',
  'runnersworld.com',
  'emdep.vn',
  'celebdirtylaundry.com',
  'codeproject.com',
  'bathandbodyworks.com',
  'seriouseats.com',
  'handmark.com',
  'miitbeian.gov.cn',
  'funplusgame.com',
  'freepp.com',
  'tubecup.org',
  'spanishcentral.com',
  'oregon.gov',
  'fearthesword.com',
  'newslook.com',
  'pressdisplay.com',
  'zara.com',
  'corpmailsvcs.com',
  'afcdn.com',
  'canoe.ca',
  'databyacxiom.com',
  'clickpoint.com',
  'thepioneerwoman.com',
  'aviationweather.gov',
  'hiphopmyway.com',
  'popularmechanics.com',
  'vertica.com',
  'kliksaya.com',
  'bbcgoodfood.com',
  'ccc.se',
  'gfi.com',
  'everydayfamily.com',
  'jiathis.com',
  'ndtvimg.com',
  'wowslider.com',
  'worlderror.org',
  'parcelstream.com',
  'cifraclubnews.com.br',
  'iafrica.com',
  'thinglink.me',
  'darkbluev2.com',
  'sradserver.com',
  'hotmart.net.br',
  'altova.com',
  'financialcontent.com',
  'despegar.com',
  'bradesconikkei.com.br',
  'successfactors.com',
  'blackplanet.com',
  'fox.com.tr',
  'icoco.com',
  'fastserv.com',
  'thehartford.com',
  'mangahere.co',
  'ticketmaster.ie',
  'groovorio.com',
  'detroitbadboys.com',
  'saavn.com',
  'linio.com',
  'passionfruitads.com',
  'apache.org',
  'trendinglifestyles.com',
  'novamov.com',
  'appcelerator.net',
  'emlfiles4.com',
  'takataka.vn',
  'dreamspark.com',
  'cricbuzz.com',
  'ixl.com',
  'plosone.org',
  'k-12techdecisions.com',
  'yhoo.it',
  'hgtvremodels.com',
  'google.com.bn',
  'backblaze.com',
  'stubhubstatic.com',
  'geektyrant.com',
  'vinsight.de',
  'ilmessaggero.it',
  'timewarnercable.com',
  'emltrk.com',
  'miui.com',
  'clickhole.com',
  'natura.com.br',
  'thetimes.co.uk',
  'foxtvmedia.com',
  'listhub.net',
  'jinx.com',
  'appmessages.com',
  'uproxxcdn.com',
  'bby.com',
  'zargan.com',
  'ble.ac',
  'cquotient.com',
  'megaupload.com',
  'allhiphop.com',
  'sify.com',
  'mcssl.com',
  'rewardstyle.com',
  'zohostatic.com',
  'cedexis-test.com',
  'homeadvisor.com',
  'megamailservers.com',
  'termtutor.com',
  'forbes.co.il',
  'tasteofhome.com',
  'telegraaf.nl',
  'searspartsdirect.com',
  'secunia.com',
  'right-ads.com',
  'readability.com',
  'shop.pe',
  'argos.co.uk',
  'empireonline.com',
  'vesochieuxo.com',
  'adpassback.com',
  'oneallcdn.com',
  'kienthuc.net.vn',
  'alternet.org',
  'app-adforce.jp',
  'tracelytics.com',
  'meetmecdna.com',
  'renfe.com',
  'targetingmantra.com',
  'wwbads.com',
  'xyxpk.com',
  'coolmath-games.com',
  'adki.com',
  'cyclingnews.com',
  'minhacasamelhor.com.br',
  'ahram.org.eg',
  'almesryoon.com',
  'gamefaqs.net',
  'arabseed.com',
  'cineplex.com',
  'dha.com.tr',
  'quoracdn.net',
  'geewa.com',
  'cdnhost2000xl.com',
  'newbayasp.net',
  'dayup.org',
  'fshare.vn',
  'icd9data.com',
  'paytm.com',
  'veoh.com',
  'adforgames.com',
  'postimage.org',
  'play.it',
  'huff.to',
  'antevenio.com',
  'tuaw.com',
  'digitalcameraworld.com',
  't3.com',
  'muscache.com',
  'gamecloudnetwork.com',
  'alfadevs.com',
  'apptornado.com',
  'csrlbs.com',
  'vfpress.vn',
  'link.net',
  'kmpmedia.net',
  'synchronychat.com',
  'tencentmind.com',
  'utexas.edu',
  'msgapp.com',
  'hostip.info',
  'fidelityinvestments.com',
  'hearthhead.com',
  'screenrant.com',
  'keeng.vn',
  'cricketcb.com',
  'google.tt',
  'thinkgeek.com',
  'arginfo.com',
  'radzolo.com',
  'hinkhoj.com',
  'elcomercio.pe',
  'get.com',
  'ehow.de',
  'ftc.gov',
  'websiteprotegido.com.br',
  'lider.cl',
  'mitula.net',
  'gu.com',
  'upqzfile.com',
  'bigleaguestew.com',
  'lolnexus.com',
  'cam4support.com',
  'broadwayworld.com',
  'collegesportslive.com',
  'gmfleet.com',
  'ivillage.com',
  'probioslim.com',
  'etherealhakai.com',
  'gbtv.com',
  'getkeepsafe.com',
  'vast.com',
  'a10.com',
  'bbcamerica.com',
  'bj.com.br',
  'tipeez.com',
  'zwaar.org',
  'g.co',
  'broadvid.com',
  'stuff.co.nz',
  'adapd.com',
  'tinviet360.com',
  'mademan.com',
  'traktum.com',
  'sparknotes.com',
  'amazinglytimedphotos.com',
  'rk.com',
  'qip.ru',
  'lhssfj.com',
  'indiewire.com',
  'sodahead.com',
  'liversely.net',
  'granify.com',
  'touchtype-fluency.com',
  'pgol.it',
  'linkbucksmedia.com',
  'mangareader.net',
  'kimg.cn',
  'pepsico.com',
  'tecmarketing.com',
  'trafex.net',
  'milevo.com.br',
  'cantv.net',
  'active.com',
  'oneandone.net',
  'terraempresas.com.br',
  'smugmug.com',
  'yeah1.com',
  'tvrage.com',
  'aionfreetoplay.com',
  'northgrum.com',
  'globalpost.com',
  'memecenter.com',
  'sunbelt-software.com',
  'searsoutlet.com',
  'bbcomcdn.com',
  'r24-tech.com',
  'el-balad.com',
  'cnbcmediasales.com',
  'playtika.com',
  'healthoks.cf',
  'software-cdn.net',
  'torrentz.ch',
  'wapkaimage.com',
  'tdcanadatrust.com',
  'cnsnews.com',
  'alumniconnections.com',
  'eanalyzer.de',
  'panoramtech.net',
  'bikeradar.com',
  'adfeedstrk.com',
  'healthbk.ga',
  'egencia.com',
  'h2porn.com',
  'webhostsy.com',
  'newshost.co.za',
  'porntube.com',
  'quadranet.com',
  'discogs.com',
  'seslisozluk.net',
  'skyteam.com',
  'nenipxex.org',
  'tiydhrpes.info',
  'ayosdito.ph',
  'yourlust.com',
  'michigan.gov',
  'gimokxo.org',
  'nzznw.info',
  'quill.com',
  'topgear.com',
  'epattu.net',
  'proofpoint.com',
  'adrd.co',
  'espressonline.it',
  'brazzersnetwork.com',
  'wordcentral.com',
  'clustrmaps.com',
  'reimanpub.com',
  'appshopper.com',
  'tmbtzyha.net',
  'pplive.com',
  'ryanair.com',
  '360buy.com',
  'usc.edu',
  'naver.net',
  'zenaps.com',
  'wmyjwfixhk.net',
  'rising.cn',
  'sporxtv.com',
  'responsetap.com',
  'nutrend.com',
  'agrantsem.com',
  'aoicyowsk.org',
  'faqhk.net',
  'greenbot.com',
  'cam4bucks.com',
  'seagateshare.com',
  'gmasxewuon.com',
  'dnsmadeeasy.com',
  'vvrqnaibg.com',
  'vywadxft.org',
  'whowhatwear.com',
  'ykxdinmt.com',
  'ddnebnwogv.info',
  'hgrjubwklk.info',
  'dpgcquf.info',
  'azqitla.org',
  'wvjxq.info',
  'smqtpt.com',
  'qsstats.com',
  'ctygsgsfgus.org',
  'kaktjc.org',
  'tzfbqzbmq.net',
  'ucjyuasw.org',
  'bxcma.info',
  'ztat.net',
  'qsgymo0vb6.com',
  'theknot.com',
  'costco-static.com',
  'kmsethnz.com',
  'pearsoncmg.com',
  'ovlqhgc.org',
  'ruamrckswcm.com',
  'urekamedia.com',
  'opensecrets.org',
  'gametrailers.com',
  'xnwjp.info',
  'kotane.info',
  'sievdlstgmh.org',
  'adrbtvpzot.org',
  'fkfazhyyzy.org',
  'libraryh3lp.com',
  'nyfzelqtlwz.info',
  'terena.org',
  'christianmingle.com',
  'apmebf.com',
  'zewvyymdbud.net',
  'hdakqysubl.com',
  'bigfishgames.com',
  'bananarepublic.com',
  'yuzzsfhd.net',
  'bn.tl',
  'mcmlytjw.com',
  'skkkzsym.net',
  'thgqo.info',
  'xkrlmshkbhi.net',
  'fduqkswpbx.org',
  'comodoca4.com',
  'stargazete.com',
  'xcwxufd.org',
  'tbppdpkd.com',
  'fuspdqhon.com',
  'ophxwklr.com',
  'fbi.gov',
  'nejm.org',
  'jungroup.com',
  'gbawcd.net',
  'hjaqpjm.net',
  'iryrmxjtpoy.org',
  'sickbeard.com',
  'hcubxxbg.net',
  'rkhvtlc.info',
  'sxqlfkjxyoa.com',
  'syhttygog.com',
  'dsmmadvantage.com',
  'suntrust.com',
  'eosumfx.org',
  'wjrusyiws.net',
  'pimsleurapproach.com',
  'zxqjbnqbl.org',
  'ojarpdtabs.org',
  'yavli.com',
  'wimwbh.net',
  'audioadcenter.com',
  'adplus.co.id',
  'pqnbkt.org',
  'pacgliym.org',
  'smaclick.com',
  'suuynm.org',
  'tbbujqkry.net',
  'tjzfkph.org',
  'beatport.com',
  'infinite-scroll.com',
  'specialsituationsurvey.com',
  'wiroos.com',
  'amapmksw.net',
  'hschn.net',
  'nawkxuj.net',
  'sfknlqcy.net',
  'coremobility.com',
  'quinnipiac.edu',
  'zwhjex.net',
  'vanillaforums.com',
  'dworusea.org',
  'hgexer.org',
  'slimg.com',
  'data-url.com',
  'kndmdemzoyo.org',
  'kxwloaxw.com',
  'veybrms.com',
  'learnersdictionary.com',
  'guonmkwd.org',
  'vusgcs.org',
  'qqmpvwycwu.org',
  'thaeragkyt.com',
  'barstoolsports.com',
  'stjude.org',
  'nzduxi.cc',
  'trpxl.com',
  'rmbmbebtpp.com',
  'ticketmaster.ca',
  'idg.com',
  'rcqgdkxzpwg.org',
  'lucianne.com',
  'emediate.ch',
  'gesylwir.net',
  'gmfdyonl.cc',
  'idgbszogmfl.info',
  'ijwozlv.info',
  'myaffiliates.com',
  'royalbank.com',
  'slashgear.com',
  'portalmore.com',
  'dgzgpy.net',
  'mifnpd.com',
  'atkrwcld.com',
  'fieuctzm.info',
  'hocmdvia.com',
  'hoopz.co.in',
  'daqiqpbi.org',
  'kjrh.com',
  'redbus.in',
  'puxue.com',
  'txcdn.cn',
  'uuzyaid.org',
  'xjgeqznqm.cc',
  'xvhvc.net',
  'lottomatica.it',
  'mkt51.net',
  'dipkxlq.cc',
  'recipechart.com',
  'uicdn.com',
  'wbodgchdwfh.net',
  'ziffdavisinternational.com',
  'viedij.cc',
  'dmi.gov.tr',
  'dobjzh.org',
  'khmsfmbw.net',
  'ltsbmuenq.net',
  'abc7news.com',
  'adprotected.com',
  'iglqp.org',
  'marieclaire.it',
  'clrxzewdc.com',
  'ewtxsmpeh.com',
  'forbesmagazine.es',
  'tqetpiijm.net',
  'fcsinsider.com',
  'bbokjmcdle.cc',
  'jboujpnbbm.net',
  'echiui.com',
  'kyivpost.com',
  'rtbid.me',
  'fcontrol.com.br',
  'abdbs.org',
  'edaily.vn',
  'tiebaimg.com',
  'nvhrlq.cc',
  'bcbits.com',
  'uc123.com',
  'forbes.ro',
  'freeonlinegames.com',
  'beyjhow.cc',
  'iblbc.cc',
  'played.to',
  'smartbrief.com',
  'webutation.net',
  'metaps.com',
  'zbwdqg.net',
  'pzpdrxc.org',
  'neweggimages.com',
  'mwmkkvpozzk.net',
  'reclameaqui.com.br',
  'lircxaievm.com',
  'whdwsmbkyob.cc',
  'aoltech.com',
  'smarterfox.com',
  'someecards.com',
  'momagic.mobi',
  'piwhzmuw.cc',
  'bdllppws.com',
  'bubiocgy.cc',
  'epmjp.cc',
  'quintelligence.com',
  'finra.org',
  'roost.me',
  'ixijwtzr.cc',
  'web-ster.com',
  'netsuite.com',
  'hurra.com',
  'wcsrtmfao.com',
  'lonelyplanet.com',
  'rbcroyalbank.com',
  'qulgueozpfy.cc',
  'etoro.com',
  'vartoken.com',
  'hetwnddmrii.cc',
  'metrocast.net',
  'sportsauthority.com',
  'valeculturacaixa.com.br',
  'ojnmbxtt.cc',
  'pcgamesn.com',
  'yvvodqsqlu.com',
  'boingboing.net',
  'newscred.com',
  'nepzzveo.cc',
  'zonelabs.com',
  'vnpgroup.net',
  'cganz.org',
  'jahvpwqx.cc',
  'businessinsider.co.id',
  'armorgames.com',
  'nqofpsk.cc',
  'topify.com',
  'dafiti.com.br',
  'gatech.edu',
  'instantssl.com',
  'zorjxg.cc',
  'xmladfeed.com',
  'telerikstatic.com',
  'cofktwvu.cc',
  'zohomail.com',
  'adstrckr.net',
  'christianpost.com',
  'albjkdomro.info',
  'xerox.com',
  'holder.com.ua',
  'hdnxn.info',
  'qbwbzmtv.cc',
  'shefinds.com',
  'editorandpublisher.com',
  'ahqkb.cc',
  'chhsekhqw.com',
  'unidadeditorial.es',
  'gamefree.la',
  'centos.org',
  'addfreestats.com',
  'navy.mil',
  'systemaffiliate.com',
  'dgyhxlxpji.cc',
  'elfagr.org',
  'qeugpfgmgj.cc',
  'wjejmd.cc',
  'connectedly.com',
  'demandforced3.com',
  'searshomeservices.com',
  'admsjycuykv.cc',
  'nszose.biz',
  'ttlbd.net',
  'vvmducso.cc',
  'indir.com',
  'sun-sentinel.com',
  'alfynetwork.com',
  'burgerbusiness.com',
  'complexmediainc.com',
  'adpay.com',
  'solutionzip.info',
  'yroytop.cc',
  'kshwtj.com',
  'kldvhndinht.cc',
  'ttzoroahi.biz',
  'vietbao.vn',
  'securenetconnection.com',
  'magazineluiza.com.br',
  'osuosl.org',
  'fzofzn.cc',
  'iiisgr.info',
  'rarlab.com',
  'urge.com',
  'toroadvertisingmedia.com',
  'wgeprggwv.ws',
  'realclearreligion.org',
  'webhst.com',
  'actiontec.com',
  'tbzdnwk.cc',
  'wegotthiscovered.com',
  'ad4push.com',
  'ilpost.it',
  'realclearpolicy.com',
  'racinggamer.com',
  'khvjh.cc',
  'rtuvrdso.com',
  'a2pub.com',
  'tanidigital.com',
  'eoccqzwk.info',
  'ppbgu.cc',
  'rogysyzp.org',
  'adprudence.com',
  'hudl.com',
  'snknfcfp.info',
  'wkpevwftzv.com',
  'ooma.com',
  'uetcfrdm.info',
  'pdfforge.org',
  'realcleartechnology.com',
  'kwlgtm.cc',
  'ojrfqwt.cc',
  'htzwnl.biz',
  'oaokwaah.info',
  'reklaam.co',
  'vvkux.org',
  'imgix.net',
  'dscww.net',
  'anvato.com',
  'daphnecm.com',
  'wqaxikwy.cc',
  'whatismyip.com',
  'pro-football-reference.com',
  'bitsnoop.com',
  'hldiw.org',
  'hsotptmpg.info',
  'kbebwxy.org',
  'pymrinle.net',
  'i.ua',
  'rwkczaox.info',
  '1digitalstock.com',
  'oxcfwwerbxd.com',
  'rjtgbmjei.info',
  'ljybyhejei.ws',
  'dumpaday.com',
  'socialnewsdesk.com',
  'kdexbh.biz',
  'nxlwx.com',
  'turbobytes.com',
  'smzln.info',
  'livemixtapes.com',
  'lqokojzey.info',
  'deepdyve.com',
  'lfvhjb.biz',
  'pziio.biz',
  'vejeizob.info',
  'hbqtq.net',
  'ijunutaf.net',
  'rvnkxm.org',
  'astrology.com',
  'fiserv.com',
  'finam.ru',
  'onlineservice2013.org',
  'foodnetworkstore.com',
  'vietcombank.com.vn',
  'xsbkh.org',
  'dgvfpdudj.ws',
  'hepfmg.info',
  'qbxurzdb.org',
  'realclearhistory.com',
  'zunrobjo.cc',
  'slack.com',
  'umhbxlgc.net',
  'amo.vn',
  'fullizle.org',
  'ngads.com',
  'acs86.com',
  'baalwpbn.biz',
  'campaigner.com',
  'uprieyivrgd.info',
  'blzojuvragg.biz',
  'edptolrs.net',
  'eetsp.ws',
  'gdmvctuqky.biz',
  'shopifyapps.com',
  'qowatfdl.info',
  'ahasvfxzc.net',
  'worthlossfatseasily.me',
  'evanguard.com',
  'ghconduit.com',
  'hmvsndleo.info',
  'iypflxli.net',
  'jmkdxyk.ws',
  'adbooth.net',
  'vdyarquq.info',
  'spin.com',
  'hruhac.org',
  'izrsbbrtdqn.info',
  'lrylelyr.com',
  'viralgains.com',
  'rsrobt.org',
  'wqutrzfd.biz',
  'wtfdyo.org',
  'adpublik.com',
  'gyini.org',
  'huebwztdp.com',
  'kxieadw.org',
  'ilsole24ore.com',
  'awe.sm',
  'bcwvlnkx.org',
  'cgrlveisaam.net',
  'zebestof.com',
  'egxfslsii.org',
  'nevytf.ws',
  'nsjce.org',
  'draftkings.com',
  'xwygma.biz',
  'edtoroziecr.org',
  'esndmtix.org',
  'kgnhx.org',
  'unbouncepages.com',
  'dorkly.com',
  'xwmfbz.org',
  'irduxdivnc.ws',
  'homedepotfoundation.org',
  'xvylary.ws',
  'ajyoux.org',
  'hissage.com',
  'jzcchvae.org',
  'lxikgoxptag.org',
  'petapixel.com',
  'maketutorial.com',
  'craftsman.com',
  'blldnoxvi.ws',
  'dwdkim.org',
  'jueux.ws',
  'qbcotemhrcj.biz',
  'rfgzqaji.biz',
  'csbew.com',
  'cynnacwuo.info',
  'ltxzb.net',
  'webserviceline.org',
  'gnvkexqvt.ws',
  'lkgtos.biz',
  'moe.gov.eg',
  'realcleardefense.com',
  'serviceonlinetech.org',
  'expediafranchise.com',
  'ilgiornale.it',
  'thesuperficial.com',
  'cogentco.com',
  'tuklyreb.org',
  'presselite.com',
  'crioojfv.net',
  'fxtubwo.com',
  'gyfezbzowuw.biz',
  'qixxrais.ws',
  'cherylstyle.com',
  'ajbjz.org',
  'btmglunma.ws',
  'epnnazri.org',
  'fwfsqcux.net',
  'ifidfszesh.net',
  'jipillw.ws',
  'jetbrains.com',
  'zuncwgq.ws',
  'broadagesports.com',
  'romnz.biz',
  'yovkuwwd.net',
  'dpjksji.info',
  'fqxyheiqp.org',
  'qmfcjsyjpvg.ws',
  'tzzqzosshyj.ws',
  'uiezsksf.ws',
  'vhbvo.org',
  'theaustralian.com.au',
  'zaman.com.tr',
  'xdpenvsi.net',
  'yzifhqrk.ws',
  'cihjafxcp.net',
  'dqyrtyya.ws',
  'mlgzkzwwnz.org',
  'rvgqpud.com',
  'vimdsspys.net',
  'wgupyqdndw.ws',
  'gcmforex.com',
  'wzgzpehhnkm.ws',
  'xbhygm.org',
  'xoqovau.com',
  'dkoshhap.net',
  'rdk.al',
  'crdrjs.info',
  'tsjfn.biz',
  'ebates.com',
  'aitarget.ru',
  'grxhhiqszb.biz',
  'hwokelamsqp.net',
  'sfdoqpsw.info',
  'talkingdata.net',
  'bitebbs.com',
  'academia-assets.com',
  'allocine.net',
  '24o.it',
  'theresumator.com',
  'ncfqy.org',
  'escapistmagazine.com',
  'paginebianche.it',
  'cardstar.mobi',
  'fdovetlp.ws',
  'hfhkqlsevi.biz',
  'ieppg.net',
  'qwghivalvbb.ws',
  'lasa.com.br',
  'rrxzsi.ws',
  'jykchlbyvr.ws',
  'qbktqkl.ws',
  'tiqdfh.biz',
  'billionairesaustralia.com',
  'zpfdtwgyfq.biz',
  'nortel.com',
  'djibbxypely.org',
  'nl-img.com',
  'mbqxxiyr.info',
  'moijzbt.com',
  'ydstatic.com',
  'visa.com',
  'ecgdjumtk.biz',
  'autotraderstatic.com',
  'gmjstqdpmv.org',
  'hbdezkxzjf.ws',
  'sgphffta.cc',
  'timeforkids.com',
  'fnjbgcdmlfv.com',
  'rqrrzwj.net',
  'nyc.gov',
  'socialsecurity.gov',
  'bdawooytpv.org',
  'imgbox.com',
  'optaim.com',
  'judgepedia.org',
  'adtrixi.com',
  'unsmcp.ws',
  'webtraxs.com',
  'hilton.com',
  'ddhvwhqg.biz',
  'hellogiggles.com',
  'huhujreo.cc',
  'hzjga.biz',
  'mhzprtm.biz',
  'tkwff.ws',
  'frontpagemag.com',
  'alphamaletribe.com',
  'hheyqpnuchm.biz',
  'rtk.io',
  'rederecord.com.br',
  'jfkazzj.ws',
  'mniku.com',
  'rwqmopqgak.biz',
  'xnaocbyr.com',
  'ads-ex.com',
  'mobilefuse.net',
  'g2trk.com',
  'advg.jp',
  'oxhks.cc',
  'cmmdkgthw.info',
  'ypimblaegg.ws',
  'betradar.com',
  'glancecdn.net',
  'codeandtheory.com',
  'estrelando.com.br',
  'cctalk.vn',
  'igrmpr.biz',
  'dmmotion.com',
  'app.com',
  'zstwjoeptfu.biz',
  'imagetwist.com',
  'iuvkikajb.net',
  'torrentbox.com',
  'ycmewgipmtn.cc',
  'owlewvrivgz.cc',
  'bmbcmh.org',
  'mhyjyrgn.biz',
  'newclientgenservice.com',
  'wvniza.org',
  'arfuxfliw.net',
  'cxnynydz.biz',
  'xhpcyboz.ws',
  'accweopv.info',
  'egsjzjpz.cc',
  'sociedadedenegocios.com.br',
  'skqtdpgseun.com',
  'epalaxghv.cc',
  'qhirhxxowcf.cc',
  'vericlk.com',
  'eroeooof.com',
  'fkyehkcmxx.com',
  'guuwouduwgk.com',
  'netaffiliation.com',
  'h-cdn.co',
  'nvvknqpt.com',
  'gruppoespresso.it',
  'blmqccfb.biz',
  'phvtxypi.cc',
  'clickable.net',
  'jlqnseshyfr.com',
  'idvaultservices.com',
  'moving.com.br',
  'rehcuqjlszg.ws',
  'wikipedia.com',
  'arcfdtls.net',
  'topfreegames.com',
  'qufacnib.cc',
  'eouvh.cc',
  'getanxhkfl.org',
  'sf.net',
  'nextinsure.com',
  'joinecsc.com',
  'createsend1.com',
  'jcloud.com',
  'hboqdalzdb.cc',
  'iucfpstqju.net',
  'racked.com',
  'haichuanmei.com',
  'pofvc.cc',
  'aeriagames.com',
  'popoholic.com',
  'zxeraykcru.biz',
  'ataiswtjq.cc',
  'imaginecup.com',
  'wxelvbutl.cc',
  'erzubfwdpid.cc',
  'madnet.ru',
  'aqlrq.cc',
  'metrics34.com',
  'fitnesskeeperapi.com',
  'osmsvcxwgh.cc',
  'orlandosentinel.com',
  'tf2outpost.com',
  'xuvkaipwcdb.cc',
  'rackspace.com',
  'publitalia.it',
  'aicsuc.cc',
  'publishthis.com',
  'vrzuthlz.cc',
  'bbcurdu.com',
  'plala.or.jp',
  'afqtrggqe.ws',
  'allakhazam.com',
  'ccmbenchmark.com',
  'foxitsoftware.com',
  'cktfpxeyhq.cc',
  'lubebgyh.cc',
  'minfils.eu',
  'instantcheckmate.com',
  'vmofcpi.cc',
  'ufggdwezyd.cc',
  'youtube.it',
  'dzxgristcfg.ws',
  'hjskmeltj.cc',
  'saraiva.com.br',
  'conmio.com',
  'duomi.com',
  'marcamarca.com.tr',
  'dpupdate.com',
  'alesouza.com',
  'canstockphoto.com',
  'static-nextag.com',
  'mycapture.com',
  'stupiddope.com',
  'xosnetwork.com',
  'jetveopbmzo.cc',
  'peixeurbano.com.br',
  'www.nhs.uk',
  'politicususa.com',
  'timeincnewsgroupcustompub.com',
  'yenisafak.com.tr',
  'home.com',
  'appshat.com',
  'exoticads.com',
  'ift.tt',
  'livejournal.net',
  'lyrta.cc',
  'trfirmaekle.com',
  'cloud9-media.net',
  'pogo.com',
  'gkhroqza.cc',
  'homefinder.com',
  'just-downloads.net',
  'gpqwrwmgist.cc',
  'discuz.net',
  'fractalsciences.com',
  'ovkmjiw.cc',
  'flingguru.com',
  'tds.net',
  'tdqdghjtnj.cc',
  'blogtamsu.vn',
  'weather.com.cn',
  'ckstatic.com',
  'josscdn.com',
  'onion.com',
  'gquldikg.ws',
  'vqwcgak.ws',
  'adcdnx.com',
  'cannedbanners.com',
  'symnds.com',
  'ucla.edu',
  'gilt.com',
  'nme.com',
  'beachfrontio.com',
  'laughingsquid.com',
  'wpxi.com',
  'marktest.pt',
  'lzpwgq.biz',
  'castfire.com',
  'rtb-media.ru',
  'techbargains.com',
  'qugylddujwj.biz',
  'zagat.com',
  'bdiaydynor.biz',
  'mslearn.net',
  'fiesdacaixa.com.br',
  'jhdiknjlq.cc',
  'soyouthinkyoucangame.com',
  'rightnowtech.com',
  'vzagof.ws',
  'axcogulnxj.ws',
  'addgsene.cc',
  'pornmarathon.com',
  'identityguard.com',
  'juksr.com',
  'statuspage.io',
  'hiapk.com',
  'ugcroceao.biz',
  'fastpic.ru',
  'anninhthudo.vn',
  'batpmturner.com',
  'newtentionassets.net',
  'bouncebidder.com',
  'ktqhyn.biz',
  'vjyfw.biz',
  'interactivedata.com',
  'yyzhroqelh.ws',
  'massrel.io',
  'nmgx.co.uk',
  'statistik-gallup.net',
  'topsy.com',
  'ksl.com',
  'automattic.com',
  'dhqfg.ws',
  'fkxzw.biz',
  'mypoints.com',
  '91.com',
  'kjmtpknc.ws',
  'infoescola.com',
  'infg.com.br',
  'wral.com',
  'tianmidian.com',
  'mgaserv.com',
  'sgshbsnxw.biz',
  'xvhibyfku.cc',
  'mightynova.com',
  '1anh.com',
  'rentalcars.com',
  'infonet.vn',
  'hsbfgc.biz',
  'columbia.edu',
  'internetsegura.org',
  'nwfeybp.ws',
  'nzuxayxvb.biz',
  'jjijdzz.biz',
  'mboeughth.biz',
  'athleta.com',
  'ml.com',
  'elfvt.biz',
  'porch.com',
  'ncaa.com',
  'cultofmac.com',
  'searsholdings.com',
  'chztfneh.biz',
  'jimwqv.ws',
  'xrtmbe.biz',
  'cutun.vn',
  'bango.net',
  'feodpyusmel.ws',
  'safetynutbe.com',
  'kcna.co.jp',
  'realclearbooks.com',
  'sendspace.com',
  'datatables.net',
  'containerstore.com',
  'frontdb.com',
  'ybiqqrrr.biz',
  'binaryprofessional.com',
  'easysol.net',
  'cornell.edu',
  'truex.com',
  'scrippsnetworksdigital.com',
  'xzuai.biz',
  'nckrnpudwgc.ws',
  'rbcdn.com',
  'iilcuaks.ws',
  'cafemomstatic.com',
  'care2.com',
  'keepcalm-o-matic.co.uk',
  'dictionaryapi.com',
  'edgussbrehp.ws',
  'mangafox.me',
  'nwoxjixrm.biz',
  'shiftyjelly.com.au',
  'topix.com',
  'btypevb.ws',
  'dcstkgbi.cn',
  'developmaster.in',
  'ulmjklxf.biz',
  'webserviceline2013.org',
  'fvgjmz.biz',
  'motortrend.com',
  'eehnrwsg.biz',
  'truehits.in.th',
  'persona.org',
  'cybergolf.com',
  'nastyvideotube.com',
  'adual.net',
  'krrhvyjsbiq.biz',
  'kenmore.com',
  'nordstromrack.com',
  'pfgvgnvk.ws',
  'spoonful.com',
  'techsonlineervice.org',
  'rockpapershotgun.com',
  '365dm.com',
  'ivwbox.de',
  'tiki.vn',
  'wabagmti.ws',
  'destinydb.com',
  'cafeland.vn',
  'realclearenergy.org',
  'com2us.net',
  'onlineservicetest.org',
  'xtgem.com',
  'jkovqerv.biz',
  'oxqgnhu.ws',
  'babyzone.com',
  'unjklmh.biz',
  'advancedigital.com',
  'gaana.com',
  'homedepotemail.com',
  'pmlatam.com',
  'qsrqqa.ws',
  'msgf.net',
  'mudah.my',
  'reviversoft.com',
  'twincities.com',
  'uolcontent.com',
  'adedgemedia.com',
  'e-karaman.com',
  'army.mil',
  'elgwpdbdz.biz',
  'ktyqltpace.biz',
  'vwislcpb.ws',
  'baseball-reference.com',
  '2xbpub.com',
  'cuteo.vn',
  'playappstats.com',
  'wgt.com',
  'cipebk.biz',
  'dhresource.com',
  'thingsremembered.com',
  'fgnbrfxt.ws',
  'netu.tv',
  'nitropdf.com',
  'gsecondscreen.com',
  'segsrvcs.com',
  'graytvinc.com',
  'brasilpost.com.br',
  'imvu.com',
  'xalo.vn',
  'zromhyh.biz',
  'plaync.co.kr',
  'neulion.net',
  'topkit.com',
  'ghzyehci.ws',
  '71.am',
  'pkqyv.ws',
  'lexisnexis.com',
  'awpjtkvmhd.cn',
  'mtnldelhi.in',
  'omgpm.com',
  'oppomobile.vn',
  'tripadvisor.com.br',
  'dealnews.com',
  'iqhgbjuzi.biz',
  'osatcxntrug.ws',
  'nwsource.com',
  'selectablemedia.com',
  'toucharcade.com',
  'achdebit.com',
  'deseretnews.com',
  'pussycash.com',
  'robbreport.com',
  'zcloud.io',
  'nmfsuibwt.cn',
  'pdfalmta.ws',
  'fmtrader.com',
  'techtimes.com',
  '101affiliates.com',
  'wonderwall.com',
  'xmlclick-g.com',
  'dumlfnhd.ws',
  'ihotdjn.cn',
  'medu.com',
  'eqnextfans.com',
  'telcel.com',
  'rzwvyhv.biz',
  'videonhadat.com.vn',
  'wsi.com',
  'oxforddictionaries.com',
  'commonshare.net',
  'bilyoner.com',
  'wxljto.cn',
  'crbfjs.info',
  'gccdn.net',
  'ccsend.com',
  'ajansspor.com',
  'adsboxonline.com',
  'bqfhmcnsolt.ws',
  'newdemoonlinecloud.com',
  'wnxiwg.cn',
  'reviewjournal.com',
  'sportsnet.ca',
  'borsahaber.com',
  'hfzic.cn',
  'nwacmz.cn',
  'britannicaenglish.com',
  'progressive.com',
  'vogue.com',
  'ocn.ad.jp',
  'mqqmaavwqul.cn',
  'toshiba-tro.de',
  'didyn.co.uk',
  'adonly.com',
  'sp.gov.br',
  'regiedepub.com',
  'india.gov.in',
  'umd.edu',
  'ebscohost.com',
  'aslangamestudio.com',
  'fypmh.cn',
  'cobalt.com',
  'lowes.ca',
  'rvzrjs.info',
  'rxmkklx.cn',
  'bahldhghl.cn',
  'hyuvwsj.cn',
  'abear.com.br',
  'realcleareducation.com',
  'cgfmfa.cn',
  'macysinc.com',
  'expedient.net',
  'hzrxlnynak.cn',
  'zihrsowdavb.cn',
  'pwshqtanxpi.cn',
  'bzwhwzur.biz',
  'bradescoabrasuaconta.com.br',
  'gptxzy.cn',
  'petstocking.com',
  'disneybaby.com',
  'tubexclips.com',
  'electnext.com',
  'scexbsrw.cn',
  'xnsports.com',
  'ey.com',
  'yielm.com',
  'gssdnyiq.info',
  'ygrskyqd.cn',
  'animetoon.tv',
  'lastampa.it',
  'yourlustmedia.com',
  'hhhfwrv.cn',
  'efnet.org',
  'charterbusiness.com',
  'businessinsider.sg',
  'districtwest.com',
  'qljqtnmqx.cn',
  'say.ac',
  'rafflecopter.com',
  'bradescocelular.com.br',
  'goodsearch.com',
  'siemens.com',
  'socialquantum.ru',
  'green-label.com',
  'adwhirl.com',
  'instructure.com',
  'twcc.com',
  'adtop.vn',
  'muachung168.com',
  'freewebs.com',
  'personalcreations.com',
  'pgoamedia.com',
  'marketo.com',
  'ndl.go.jp',
  'clickdesk.com',
  'playdom.com',
  'paramountcommunication.com',
  'sm3na.com',
  'depotliive.in',
  'adbucks.com',
  'directtoustore.com',
  'encontreobb.com.br',
  'xpxbmzqcpma.cn',
  'iaveqvyuo.cn',
  'squid-cache.org',
  'bsqptibskvk.cn',
  'gluftlsdqtc.cn',
  'vidbull.com',
  'ecvjixc.cn',
  'vginyzu.cn',
  'fid-inv.com',
  'letvimg.com',
  'dominionenterprises.com',
  'hotplug.ru',
  'androidauthority.com',
  'safarishop.com.br',
  'fastestcdn.net',
  'easytaxi.com.br',
  'pornwhite.com',
  'fitsugar.com',
  'electric.net',
  'yoomeegames.com',
  'cjsyvlh.cn',
  'violetgrey.com',
  'trivago.com',
  'viacom.com',
  'walmartonline.com.ar',
  'adtdp.com',
  'eastmoney.com',
  'bzmqb.cn',
  'fannation.com',
  'baodautu.vn',
  'ukzrhfbn.cn',
  'vietid.net',
  'mygofer.com',
  'gamersmedia.com',
  'mailcontrol.com',
  'getdownloadmy.com',
  'ninpblt.cn',
  'justfab.com',
  'casasbahia-imagens.com.br',
  'loopassets.net',
  'asocials.com',
  'myntassets.com',
  'tweetriver.com',
  'aerserv.com',
  'bsecure.com',
  'in-appadvertising.com',
  'clientstatsservice.com',
  'ticketfly.com',
  'azdjforhire.com',
  'jqueryui.com',
  'radio-canada.ca',
  'elo7.com.br',
  'planet49.com',
  'iac.com',
  'dolphin-browser.cn',
  'singnet.com.sg',
  'sugarops.com',
  'cartoonnetwork.com',
  'pages01.net',
  'ssl.com',
  'vgoeun.cn',
  'cisive.net',
  'namehub.com',
  'torrents.to',
  'charitynavigator.org',
  'yallakora.com',
  'poletracker.org',
  'cddbp.net',
  'rapgenius.com',
  'speedanalysis.net',
  'eltiempo.es',
  'yatra.com',
  'pgpartner.com',
  'mochitot.com',
  'sendtonews.com',
  'u-on.eu',
  'hallmark.com',
  'cloudmagic.com',
  'contextly.com',
  'bonzaii.no',
  'vchat.vn',
  'jimstatic.com',
  'houstonchronicle.com',
  'seiyu.co.jp',
  'lifeselector.com',
  'streamtip.com',
  'jihadwatch.org',
  'whirlpoolcorp.com',
  'kraftfoods.com',
  'bhphoto.com',
  '56.com',
  'woolik.com',
  'indo.net.id',
  'rcn.net',
  'korabia.com',
  'chicagobusiness.com',
  'powerjobs.com',
  'cbeyond.com',
  'buscapecompany.com',
  'sarenza.com',
  'pornleech.me',
  'kudzu.com',
  'dirmusiic.in',
  'datingfactory.net',
  'disneyjunior.com',
  'torhead.com',
  'greatdepothomey.asia',
  'wmo.int',
  'elle.it',
  'tirerack.com',
  'aftonbladet-cdn.se',
  'iwebar.com',
  'rabilitan.com',
  '4tube.com',
  'prevention.com',
  'kelleybluebookimages.com',
  'tdbank.com',
  'xskt.com.vn',
  'democlientnet.com',
  'toolserver.org',
  'vads.vn',
  'fuse.tv',
  'picdn.net',
  'softwareprojects.com',
  'xbmc.org',
  'thenewrepublic.com',
  'starwars.com',
  'allperfectlytimed.com',
  'education.com',
  'altmetric.com',
  'watchseries.ag',
  'ultimate-guitar.com',
  'ads.cc',
  'worldoftanks.com',
  'zzz.vn',
  'dhs.gov',
  'mc.gov.br',
  'abload.de',
  'nudevector.com',
  'jungledisk.com',
  'aetn.com',
  'the-best-adults-vine.com',
  'quixapp.com',
  'ciudad.com.ar',
  'written.com',
  'cox.com',
  'paragaranti.com',
  'cityfeet.com',
  'vanityfair.it',
  'piperlime.com',
  'rncdn3.com',
  'pjtra.com',
  'browsemark.net',
  'torrenti.al',
  'odcdn.com',
  'acessoainformacao.gov.br',
  'apnic.net',
  'k7computing.com',
  'vplay8.com',
  'd3head.com',
  'arvixe.com',
  'thumbshots.com',
  'kronos.com',
  'gpstream.net',
  'traviangames.com',
  'cursos24horas.com.br',
  'msftconnecttest.com',
  'olapic.com',
  'dhcxjscg.cn',
  'adhaven.com',
  'justgetflux.com',
  'clicktracks.com',
  'adserverpub.com',
  'zqjjrpx.com',
  'rgoskspdu.cn',
  'the-m-age.com',
  'nsf.gov',
  'despegar.com.ar',
  'esohead.com',
  'publicpolicypolling.com',
  'stitcher.com',
  'mdotm.co',
  'sage.com',
  'internic.com',
  'btrd.net',
  'cafebiz.vn',
  'herezera.com',
  'srds.com',
  'uefa.com',
  'newsbank.com',
  'barracudanetworks.com',
  'librato.com',
  'backstage.com',
  'veesible.it',
  'abebooks.de',
  'baifendian.com',
  'wsjsafehouse.com',
  'alistmoz.cn',
  'adfootprints.com',
  'oyunmoyun.com',
  'redbookmag.com',
  'userneeds.dk',
  'bowl.com',
  'vs.com',
  'tinvn.info',
  'imdb.de',
  'arsmtp.com',
  'ipapp.com',
  'tracki112.com',
  'freerepublic.com',
  'starpulse.com',
  'marieclaire.com',
  'yjthmjbjie.cn',
  'meowapi.com',
  'surfingbird.ru',
  'dailytech.com',
  'startimes.com',
  'tgadvapps.it',
  'nero.com',
  'cloudy.ec',
  'fund123.cn',
  'enuygun.com',
  'vervemobile.com',
  'vesselapp.com',
  'finans.dk',
  'ppliowlh.cn',
  'ealojs.cn',
  'trustedshops.com',
  'audioware.com.br',
  'newsnow.co.uk',
  'salaoautocaixa.com.br',
  'deseretconnect.com',
  'vozforums.com',
  'wwpcitfsg.cn',
  'example.com',
  'budgettravel.com',
  'google.hu',
  'ukcompfindlove.info',
  'imagefap.com',
  'bellsouth.com',
  'keystealth.org',
  'adduplex.com',
  'vclnrnhfn.cn',
  'adjug.com',
  'vzaar.com',
  'mmoui.com',
  'decider.com',
  'transpera.com',
  'cprpt.com',
  'zincx.com',
  'directallapp.in',
  'rampanel.com',
  'scmplayer.net',
  'axhldab.cn',
  'bbcpersian.com',
  'gymplan.com',
  'todoist.com',
  'glhxefai.cn',
  'lfdsddbga.cn',
  'ystdcru.cn',
  'loop11.com',
  'clevernet.vn',
  'nos.nl',
  'redhat.com',
  'mediamatters.org',
  'zoneedit.com',
  'rjqmczlucxd.cn',
  'anycash.com',
  'flwarmwg.cn',
  'sbc.com',
  'unitusaforalllove.info',
  'photoscape.org',
  'eemqepu.cn',
  'monsternotebook.com.tr',
  'yyqtbvqv.cn',
  'cegjobs.com',
  'xbnfrg.cn',
  'xplosion.de',
  'taylorswift.com',
  'newspaperdirect.com',
  'gznwldaxh.cn',
  'stardoll.com',
  'tcpdiag.net',
  'fitpregnancy.com',
  'xmypgoqokb.cn',
  'bellmedia.ca',
  'getbills.com',
  'incredibar.com',
  'lowesforpros.com',
  'pdpdvtec.cn',
  'scielo.br',
  'ovoadv.com',
  'iahzw.cn',
  'akqstjbu.cn',
  'lqw.me',
  'multiview.com',
  'wjfrewfykf.cn',
  'marksandspencer.com',
  'trackingclick.net',
  'bangbros.com',
  'mymailwall.com',
  'tntvffmm.cn',
  'topbongda.com',
  'vervewireless.com',
  'animenewsnetwork.com',
  'zvab.com',
  'zgzfbgq.cn',
  'assineabril.com',
  'plaync.com',
  'qmvxa.cn',
  'jumia.com.eg',
  'bsd.net',
  'juzkqm.cn',
  'ksgks.cn',
  'snapworkapps.com',
  'dfna.net',
  'tradenet.net',
  'elsevier.com',
  'predictormedia.com',
  'insidercarnews.com',
  'thisiscolossal.com',
  'dreamhost.com',
  'sexsearch.com',
  'wajuvzaq.cn',
  'muthead.com',
  'pulsepoint.com',
  'movielink.com',
  'talktalk.net',
  'gigaset.net',
  'dijimecmua.com',
  'fit-predictor.net',
  'dlgokzzejj.cn',
  'loopme.me',
  'markitondemand.com',
  'libreoffice.org',
  'omp.me',
  'rankingsandreviews.com',
  'politicopro.com',
  'thegioididong.com',
  'mobizone.mobi',
  'ctpost.com',
  'samsungmediahub.net',
  'sdphruvn.cn',
  'i-funbox.com',
  'saveur.com',
  'jdjfdsnd.cn',
  'clanacion.com.ar',
  'egywcfyz.cn',
  'cityspark.com',
  'snapapp.com',
  'intpvbjj.cn',
  'appyet.com',
  'coolmath.com',
  'zoosk.com',
  'escapemg.com',
  'rtbfy.com',
  'dropboxatwork.com',
  'dwtyj.cn',
  'adidas.com',
  'gogames.me',
  'bt.com',
  'vng.vn',
  'buzzle.com',
  'liquida.it',
  'theage.com.au',
  'grandparents.com',
  'startpage24.com',
  'network18online.com',
  'phird.cn',
  'adobecc.com',
  'openstat.ru',
  'freshdesk.com',
  'delivery53.com',
  'aljazeera.net',
  'zvelo.com',
  'voxel.net',
  'idtargeting.com',
  'alwafd.org',
  'twenga.it',
  'aa.com.tr',
  'btmbxiacvl.cn',
  'registrar-servers.com',
  'bradescorural.com.br',
  'govtrack.us',
  'cb2.com',
  'chel.su',
  'torrentsnipe.info',
  'hitsk.in',
  'ehealthcaresolutions.com',
  'wowinterface.com',
  'schwabcdn.com',
  'frenchmid.eu',
  'newsbytes.com',
  'betterbythemin.com',
  'bigfishsites.com',
  'booksamillion.com',
  'spigjs.info',
  'etymonline.com',
  'supertelafilmesonlinegratis.com',
  'yottaa.net',
  'websimages.com',
  'minhavida.com.br',
  'dietaesaude.com.br',
  'kameleoon.com',
  'livepromotools.com',
  'perfectcitytime.com',
  'hunts.com',
  'molliemakes.com',
  'bloggercomment.com',
  'brookings.edu',
  'bm324.com',
  'sitewit.com',
  'intsig.net',
  'mirror-image.net',
  'foodonthetable.com',
  'ticketmaster.com.au',
  'kernel.org',
  'vancouversun.com',
  'adtegrity.net',
  'brightcloud.com',
  'swappa.com',
  'politiken.dk',
  'hollywoodtuna.com',
  'popfixx.com',
  'fareportal.com',
  'followhorseracing.com',
  'immunet.com',
  'sterling-adventures.co.uk',
  'mgyun.com',
  'baofeng.com',
  'mastercms.org',
  'magiq.com',
  'projone.net',
  'anchorfree.net',
  'usafis.org',
  'salaodocarro.com.br',
  'consumerinput.com',
  'linkonlineworld.com',
  'cc.com',
  'futurity.org',
  'easy-ads.com',
  'adelixir.com',
  'arenafootball.com',
  'mawaly.com',
  'cloudantivirus.com',
  'albawabhnews.com',
  'istoedinheiro.com.br',
  'self.com',
  'pushauction.com',
  'qnsr.com',
  'enchantedlearning.com',
  'gigenet.com',
  'crimtan.com',
  'skem1.com',
  'leaseweb.com',
  'noobmeter.com',
  'idexx.com',
  'theaccept.net',
  'extra.com.br',
  'bimedia.net',
  'thepennyhoarder.com',
  'trackeame.com',
  'gophoto.it',
  'vt.edu',
  'doveclub.it',
  'fptshop.com.vn',
  'gamebaby.net',
  'pangora.com',
  'friv-games.com',
  'locaweb.com.br',
  'polarnavy.com',
  'ado-global.com',
  'incmd07.com',
  'sancohuyenthoai.vn',
  'ulketv.com.tr',
  'esa.int',
  'rescuetime.com',
  'trustedform.com',
  'hotlog.ru',
  'privacystar.com',
  'trademob.com',
  'ssrn.com',
  'kidshealth.org',
  'dostor.org',
  'hotair.com',
  'kidsfootlocker.com',
  'ebay-mediacentre.co.uk',
  'mapmyfitness.com',
  'adform.com',
  'society6.com',
  'hotdeal.vn',
  'thevideo.me',
  'duolingo.com',
  'gossipcenter.com',
  'checkpointsys.com',
  'swacargo.com',
  'cpcache.com',
  'goobzo.com',
  'gtburst.com',
  'cameraprive.com.br',
  '2dopeboyz.com',
  'admixclicks.com',
  'aggeliopolis.gr',
  'fa8072.com',
  'lowescreativeideas.com',
  'playerio.com',
  'findthebest.com',
  'tira.cn',
  'plentyoffish.com',
  'ximad.com',
  'csnne.com',
  'golden-goose-method.com',
  'opm.gov',
  'quattroruote.it',
  'faqs.org',
  'snssdk.com',
  'vdict.com',
  'wpimg.pl',
  'doubletwist.com',
  'inbox.com',
  'cdn-redfin.com',
  'bluecoat.com',
  'kayak.co.uk',
  'cwmods.com',
  'timesonline.co.uk',
  'direcpc.com',
  'redtailtechnology.com',
  'wayreview.com',
  'h12-media.net',
  'meterserver.vn',
  'cisp.com',
  'dulichhue.com.vn',
  'sannhac.com',
  'buenosearch.com',
  'technetevents.com',
  'michaels.com',
  'thesimplethings.com',
  'scoutanalytics.net',
  'laptopmag.com',
  'ticketmaster.es',
  'aerisapi.com',
  'groupon.co.uk',
  'hotukdeals.com',
  'ofuxico.com.br',
  'vidyomani.com',
  'yahoo.co.uk',
  'ledsmagazine.com',
  'blogsonyxperia.com.br',
  'docusign.net',
  'good.com',
  'blogcatalog.com',
  'dhl.com',
  'tagesschau.de',
  'hulatoo.net',
  'sonypictures.com',
  'masralarabia.com',
  'spigtrdpjs.info',
  'berkeley.edu',
  'corrieredellosport.it',
  'guitarbattle.com.br',
  'smartorrent.com',
  'dattobackup.com',
  'cfcloudcdn.com',
  'rte.ie',
  'netvigator.com',
  'genius.com',
  'triangleoffense.com',
  'rifthead.com',
  'cobaltnitra.com',
  'ganadineroconencuestas.com',
  'panorama.it',
  'beaconads.com',
  'hostingxtreme.com',
  'sohu.com.cn',
  'hanmail.net',
  'rhapsody.com',
  'esoui.com',
  'picasion.com',
  'parade.com',
  'socialgrowthtechnologies.com',
  'saigonamthuc.vn',
  'beliefnet.com',
  'pressurenet.io',
  'valaffiliates.com',
  'klart.se',
  'supereva.com',
  'adhitzads.com',
  'audioaddict.com',
  'toparcadehits.com',
  'maquinadevendas.com.br',
  'staticontent.com',
  'gpo.gov',
  'sipc.org',
  'ticketmaster.com.mx',
  '85dcf732d593.se',
  'podoweb.net',
  'wzrkt.com',
  'forumfree.net',
  'wikispaces.com',
  'lapresse.ca',
  'bluehornet.com',
  'eq2interface.com',
  'lostandfound.aero',
  'ttdt.vn',
  'comicbookmovie.com',
  'onlinebackupsolution.com',
  'm-viet.com',
  'cnnic.cn',
  'lowes.com.mx',
  'otmsrv.com',
  'nosc.us',
  'mayo.edu',
  'go.im',
  'rainbowtgx.com',
  'fastrapid.in',
  'locamail.com.br',
  'tachthongtin.com',
  'spongecdn.com',
  'activejunky.com',
  'celebrityhd.tv',
  'lifehack.org',
  'callcentric.com',
  'ibs.it',
  'adglue.com',
  'imagehost123.com',
  'jagranjosh.com',
  'checkm8.com',
  'voyeurhit.com',
  'addictinggames.com',
  'digitalfuture.com',
  'beedoctor.vn',
  'washtimes.com',
  'entwine-wines.com',
  'eqinterface.com',
  'uglab.org',
  'csnwashington.com',
  'produzindoeventos.com.br',
  'accoona.com',
  'policypedia.org',
  'triradar.com',
  'nintendo.com',
  'developermedia.com',
  'easportsfifaworld.com',
  'letv.cn',
  'laposte.net',
  'weather.ca',
  'trafficserving.com',
  'quickconnect.to',
  'fedoraproject.org',
  'thegrio.com',
  'ziraat.com.tr',
  'hitwebcounter.com',
  'select-n-go.com',
  'bigtorrent.org',
  'bradescopromotora.com.br',
  'adverline.com',
  'dota2lounge.com',
  'trade101.com',
  'kitconet.com',
  'bidsystem.com',
  'khampha.vn',
  'rvchsr.com',
  'deployads.com',
  'capital.it',
  'cliktrue.com',
  'bitsontherun.com',
  'legalmail.it',
  'ultradns.com',
  'comsenz.com',
  'iolo.net',
  'goodnet.org',
  'laweekly.com',
  'stereogum.com',
  'leadboltads.net',
  'babycenter.ca',
  'tctmobile.com',
  'fundsspeedy.in',
  'bbccanada.com',
  'flic.kr',
  'icbc.com.cn',
  'photoshop.com',
  'recipezaar.com',
  'dayzdb.com',
  'livrariasaraiva.com.br',
  'clickdiagnostic.com',
  'mygame82.com',
  'demandbase.com',
  'mailhop.org',
  'townsquareblogs.com',
  'smarsh.com',
  'tinnong.vn',
  'vagas.com',
  'thottbot.com',
  'cargocollective.com',
  'app47.mobi',
  'freelotto.com',
  'justuno.com',
  'motiwecdn.com',
  'blogtoplist.com',
  'payplay.fm',
  'morgdm.ru',
  'publiabril.com.br',
  'sidecubes.com',
  'gamezone.com',
  'monitus.net',
  'linkd.in',
  'fastenal.com',
  'cookappsgames.com',
  'yenikadin.com',
  'bcove.me',
  'fancy.com',
  'ad4mat.net',
  'ilmeteo.com',
  'estrongs.com',
  'hamburgdeclaration.org',
  'autoexpress.co.uk',
  'disneycareers.com',
  'sumotracker.org',
  'newclientstaticsrv.com',
  'mail2world.com',
  'ed.gov',
  'kayak.co.in',
  'youwatch.org',
  'aggregateknowledge.com',
  '140proof.com',
  'teamsnap.com',
  'nend.net',
  'avazutracking.net',
  'w.org',
  'sethads.info',
  'computershopper.com',
  'sweetcaptcha.com',
  'alcatel-lucent.com',
  'kayak.com.br',
  'digitalthrottle.com',
  'southwestthemagazine.com',
  'futuredial.com',
  'linksrs.com',
  'kayak.de',
  'textnow.com',
  'uzmanreklam.com',
  'sendgrid.com',
  'unicode.org',
  'vg247.com',
  'installmac.com',
  'stocktonport.com',
  'voxer.com',
  'asksemtools.com',
  'cdn-image.com',
  'pressherald.com',
  'bpsecure.com',
  'fastapi.net',
  'ricardoeletro.com.br',
  'ustatik.com',
  'cat.com',
  'bongda24h.vn',
  'alohaenterprise.com',
  'forumfree.it',
  'capitaliq.com',
  'lotrointerface.com',
  'wothic.com',
  'advancedhosters.com',
  'tsunami.gov',
  'webgozar.ir',
  'gboxapp.com',
  'bradescouniversitarios.com.br',
  'beyeu.com',
  'gothamist.com',
  'crispadvertising.com',
  'motiveadserver.com',
  'hi-pi.com',
  'bradescoseguranca.com.br',
  'canlitv.com',
  'macysjobs.com',
  'vidigital.ru',
  'globomarcas.com.br',
  'curalate.com',
  'aroofquote.info',
  'financialpost.com',
  'hightail.com',
  'ldscdn.org',
  'millry.co',
  'tmocache.com',
  'iomartmail.com',
  'webtv.net',
  'kayak.com.au',
  'shipmentmanager.com',
  'wetpaint.com',
  'imf.org',
  'zite.com',
  'browsehappy.com',
  'cambridge.org',
  'kidsafeseal.com',
  'basbakanlik.gov.tr',
  'n11.com.tr',
  'mibet.com',
  'thefrugalgirls.com',
  'classicvacations.com',
  'lifeatexpedia.com',
  'images4us.com',
  'kayak.ch',
  'mayoclinic.com',
  'netcommunities.com',
  'torchbrowserjs.info',
  'shawcable.net',
  'wired.it',
  'carbonhousehost2.com',
  'kayak.it',
  'sigalert.com',
  'msedge.net',
  'enigmaadserver.com',
  'harryanddavid.com',
  'shopzilla.com',
  'p0y.cn',
  'lilluna.com',
  'cdn-seekingalpha.com',
  'kayak.com.ar',
  'riftui.com',
  'catalinahub.com',
  'eonli.ne',
  'freakshare.com',
  'carrentals.com',
  'easycounter.com',
  'harrenmediatools.com',
  'salesmore.pl',
  'openfeint.com',
  'sitespeeds.com',
  'vitalk.vn',
  'whydoiseetheads.info',
  'dvdvideosoft.com',
  'duke.edu',
  'bubblestat.com',
  'active-srv02.de',
  'movshare.net',
  'goapk.com',
  'gazeteoku.com',
  'sleazyneasy.com',
  'ecbsn.com',
  'pontofrio.com.br',
  'fling.com',
  'huffson.com',
  'umich.edu',
  'dmcimg.com',
  'ntvsp.org',
  'immobiliare.it',
  'muscleandfitness.com',
  'aetndigital.com',
  'shopop.me',
  'kayak.com.hk',
  'usajobs.gov',
  'szgpbgnmexpx6.com',
  'aksam.com.tr',
  'yadi.sk',
  'utah.edu',
  'asktiava.com',
  'olx.co.id',
  'mozdev.org',
  'mail.com',
  'swtorui.com',
  'pub1.us',
  'vinhomes.vn',
  'gazzabet.it',
  'shopandroid.com',
  'sourceforge.jp',
  'spoti.fi',
  'kayak.es',
  'costcophotocenter.com',
  'electronichouse.com',
  'newgrounds.com',
  'smarttech.com',
  'landsofamerica.com',
  'adinfo-guardian.co.uk',
  '247msg.com',
  'assoc-amazon.co.uk',
  'photoshelter.com',
  'hersheys.com',
  'gryphonet.com',
  'geewa.net',
  'catve.tv',
  'lemde.fr',
  'vstarcam.com',
  'thezoereport.com',
  'channel4.com',
  'stroeerdigitalmedia.de',
  'pornleech.ru',
  'radikal.ru',
  'benjerry.com',
  'homedesigntreasure.com',
  'katestube.com',
  'hiconversion.com',
  'dotaoutpost.com',
  'kayak.com.mx',
  'maximumpc.com',
  'modcloth.com',
  '800hosting.com',
  'eyeblaster.com',
  'live365.com',
  'datamind.ru',
  'fvap.gov',
  'yieidmanager.com',
  'kayak.dk',
  'listhub.com',
  'tns-cs.net',
  'kayak.fr',
  'udemy.com',
  'bancodoplaneta.com.br',
  'lpcdn.ca',
  'gourmetads.com',
  'aastocks.com',
  'architecturaldigest.com',
  'mnetads.com',
  'barracudacentral.com',
  'comingsoon.net',
  'kayak.com.tr',
  'vizio.com',
  'leonardoadv.it',
  'freeskreen.com',
  'inn.ru',
  'trckng.net',
  'pixstatic.com',
  'staplesrewardscenter.com',
  'ezanga.com',
  'fastcolabs.com',
  'teklinks.com',
  'iprimus.com.au',
  'c4tw.net',
  'cms.gov',
  'host-engine.com',
  'umtrack.com',
  'zacks.com',
  'di.sn',
  'ietf.org',
  'camdolls.com',
  'oyungemisi.com',
  'disneylandparis.com',
  'appgratuites-network.com',
  'townsquaremedia.com',
  'mediative.com',
  'commentarymagazine.com',
  'crazycashformula.net',
  'grupaonet.pl',
  'playnomics.net',
  'icann.org',
  'bikeqwikfix.com',
  'mobtada.com',
  'vrbo.com',
  'silkroad.com',
  '123c.vn',
  'vietad.vn',
  'edline.net',
  'yesadsrv.com',
  'getfirebug.com',
  'markandgraham.com',
  'newegg.ca',
  'swafreedomshop.com',
  'com.com',
  'formesuabanda.com.br',
  'magisto.com',
  'mapbar.com',
  'brimg.net',
  'canlibahissiteleri24.com',
  'synxis.com',
  'adyoulike.com',
  'costco.ca',
  'pressly.com',
  'doorsteps.com',
  'clkbid.com',
  'cyveillance.com',
  'musicnet.com',
  'mrnumber.com',
  'arenabg.com'
]

export default publicDomains
