'use strict'

import publicDomains from 'utils/publicDomains'

const sequentialNumbersArray = ['00000000', '11111111', '22222222', '33333333', '44444444', '55555555', '66666666', '77777777', '88888888', '999999999']

export function nameAndSurnameValidator (value) {
  const arr = value.trim().split(/\s+/).filter(item => item)
  return arr.length >= 2
}

export function publicDomainValidator (value) {
  const [, domain] = value.trim().split('@')
  const isPublicDomain = publicDomains.includes(domain)
  return !isPublicDomain
}

export function cellPhoneValidator (value) {
  const val = value.replace(/\D/g, '')
  const match = val.match(/^(\d{2})(\d{1})(\d{8})$/)

  if (match) {
    const DDDArrays = ['11', '12', '13', '14', '15', '16', '17', '18', '19', '21', '22', '24', '27', '28', '31', '32', '33', '34', '35', '37', '38', '41', '42', '43', '44', '45', '46', '47', '48', '49', '51', '53', '54', '55', '61', '62', '63', '64', '65', '66', '67', '68', '69', '71', '73', '74', '75', '77', '79', '81', '82', '83', '84', '85', '86', '87', '88', '89', '91', '92', '93', '94', '95', '96', '97', '98', '99']
    const [, ddd = '', ninthDigit = '', sequentialNumbers = ''] = match || []
    const hasDDDValid = DDDArrays.includes(ddd)
    const hasNinthDigit = ninthDigit === '9'
    const hasValidSequentialNumbers = !sequentialNumbersArray.includes(sequentialNumbers)
    return hasDDDValid && hasNinthDigit && hasValidSequentialNumbers
  }

  return false
}

export function CNPJValidator (value) {
  if (!value) return false

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes =
    typeof value === 'string' || Number.isInteger(value) || Array.isArray(value)

  // Elimina valor em formato inválido
  if (!validTypes) return false

  // Guarda um array com todos os dígitos do valor
  const numbers = value.toString().match(/\d/g).map(Number)

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)]
  if (items.length === 1) return false

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x)
    let factor = x - 7
    let sum = 0

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i]
      sum += n * factor--
      if (factor < 2) factor = 9
    }

    const result = 11 - (sum % 11)

    return result > 9 ? 0 : result
  }

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12)

  // Valida 1o. dígito verificador
  const digit0 = calc(12)
  if (digit0 !== digits[0]) return false

  // Valida 2o. dígito verificador
  const digit1 = calc(13)
  return digit1 === digits[1]
}

export function CEPValidator (value) {
  const val = value.replace(/\D/g, '')
  const hasValidSequentialNumbers = !sequentialNumbersArray.includes(val)
  return hasValidSequentialNumbers
}

export function cardExpiryDateValidator (value) {
  const val = value.replace(/\s/g, '')

  if (val && val.length === 5) {
    const [m, y] = value.replace(/\s/g, '').split('/')
    const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
    const hasValidMonth = months.includes(m)
    const year = Number(`20${y}`)
    const month = Number(m)
    const lastDayInMonth = new Date(year, month, 0).getDate()
    const targetDate = new Date(`${year}-${m}-${lastDayInMonth}T23:59:59-0300`)
    const now = new Date()
    return hasValidMonth && targetDate.getTime() > now.getTime()
  }

  return false
}

export function emailValidator (value) {
  const regex = /^([a-z]){1,}([a-z0-9._-]){1,}([@]){1}([a-z0-9._-]){1,}([.]){1}([a-z0-9._-]){1,}([.]?){1}([a-z0-9._-]?){1,}$/
  const match = regex.test(value)
  return match
}
