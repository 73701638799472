<template>
  <footer
    class="form__footer"
    :class="[
      { 'form__footer--fixed': fixedFormFooter },
      { 'form__footer--fixed-left': left },
      { 'form__footer--fixed-right': right },
    ]"
  >
    <div class="form__footer__container">
      <slot></slot>
    </div>
  </footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'FormFooter',
  props: {
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      fixedFormFooter: ({ formFooter }) => formFooter.fixed
    })
  },
  methods: {
    ...mapActions({
      toggleFixedFormFooter: 'formFooter/toggle'
    }),

    handleFixedFormFooter () {
      setTimeout(() => {
        const $screenHeight = window.innerHeight
        const $page = document.querySelector('[data-js="page"]')
        const $pageHeight = $page ? $page.scrollHeight : 0
        this.toggleFixedFormFooter($pageHeight > $screenHeight)
      }, 10)
    }
  },
  mounted () {
    this.handleFixedFormFooter()
    window.addEventListener('resize', this.handleFixedFormFooter, false)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleFixedFormFooter, false)
  }
}
</script>

<style lang="scss" scoped>
.form__footer {
  text-align: center;
  padding: 12px 0;
}

.form__footer--fixed {
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff 50%);
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 18px 0 36px;
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: all .26;

  @media (min-width: 992px) {
    width: 50%;
  }
}

.form__footer__container {
  width: 100%;

  @media (min-width: 1200px) {
    max-width: 560px;
  }
}

.form__footer--fixed {

  &.form__footer--fixed-left {
    left: 0;

    @media (min-width: 992px) {
      justify-content: flex-end;
    }

    .form__footer__container {

      @media (min-width: 1200px) {
        padding-right: 66px;
      }
    }
  }

  &.form__footer--fixed-right {
    right: 0;

    @media (min-width: 992px) {
      justify-content: flex-start;
    }

    .form__footer__container {

      @media (min-width: 1200px) {
        padding-left: 66px;
      }
    }
  }
}
</style>
